export class Detallefacturas{
	id?:number;
	codigo?:string;
	catidad?:number;
	precio?:any;
	iva?:any;
	factura_id?:number;
	citaexemane_id?:number;
	citasmedica_id?:number;
	estado?:number;
	info?:JSON;
	created?:Date;
	modified?:Date;
}

export class Facturas{
	id?:number;
	numero?:number;
	subtotal?:any;
	iva?:any;
	total?:any;
	persona_id?:number;
	recaudador?:number;
	paciente?:number;
	tipo?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
    detallefacturas?:Detallefacturas[];
	facturasformapagos?:any[];
	data?:JSON;
	locale_id?:JSON;
	valoretencioniva?:number;
	valoretencionrenta?:number;
	retencionfacturas?:any[];
	descuento?:number;
}