import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';

@Component({
  selector: 'app-add-pago-venta',
  templateUrl: './add-pago-venta.component.html',
  styleUrls: ['./add-pago-venta.component.scss']
})
export class AddPagoVentaElementComponent implements OnInit {
  @Input() formaPago:any;
  @Input() institucion:any=null;
  @Input() monoTotal:number=0;
  @Input() abonoMonto:number=0;
  valor:number=0;
  codigo:string='';
  dataTipo:number=1;
  abonoGeneral:number=0;

  constructor(private activeModal:NgbActiveModal,
    private decimales:localStorageServices) { }

  ngOnInit() {
    console.log(this.abonoMonto);
    this.valor=this.monoTotal-this.abonoMonto;
    this.valor=this.decimales.round2Decimales(this.valor);
    this.abonoGeneral=this.decimales.round2Decimales(this.monoTotal-this.abonoMonto);
  }

  tipo(data:any){
    this.dataTipo=(data.target.value);
    if(this.dataTipo==1){
      this.valor=this.monoTotal-this.abonoMonto;
    }else{
      this.valor=0;
    }
  }

  agregar(){
    let num=0;
    let dataSend;
    if(this.institucion){
      num=this.institucion.institucionfinanciera.id;
      dataSend={pago:this.formaPago,inst:this.institucion.institucionfinanciera, data:{monto:Number(this.valor),codigo:this.codigo,formapago_id:this.formaPago.id,institucionfinanciera_id:num,estado:1}} as any;
    }else{
      dataSend={pago:this.formaPago,inst:{numero:'Pago',nombre:'En caja'}, data:{monto:Number(this.valor),codigo:this.codigo,formapago_id:this.formaPago.id,estado:1}} as any;
    }

    this.activeModal.close(dataSend);
    
  }
}
