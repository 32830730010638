import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CitasmedicainformacionesServices } from '../../citasmedicainformacione/citasmedicainformaciones.services';

@Component({
  selector: '[app-tipo5-personalizado-motivo]',
  templateUrl: './tipo5-personalizado-motivo.component.html',
  styleUrls: ['./tipo5-personalizado-motivo.component.scss']
})
export class Tipo5PersonalizadoMotivoComponent implements OnInit {
  @Input() infoMotivo:any;
  @Output() clickEvent = new EventEmitter();
  verdatelle: boolean = false;
  valor: string = "";
  detalle: string = "";
  detalleAnterior:string = "";
  infoInicialGuardado;

  constructor(private accionFormulario: CitasmedicainformacionesServices,
    private ngxService: NgxUiLoaderService,
  ) { }


  ngOnInit() {
    if (this.infoMotivo.guardado && this.infoMotivo.guardado == 1) {
      this.detalleAnterior=this.infoMotivo.detalle;
      this.detalle = this.infoMotivo.detalle;
      
      if(this.infoMotivo.evaluar){
        this.infoInicialGuardado = this.valor;
      }else{
        this.infoInicialGuardado = this.detalle;
      }
    }
  }

  setMotivoUnoSeleccion(data:any){
    data.detalle = this.detalle; 
    if(data.seleccion==1){
      this.infoMotivo.detalle="";
      data.detalle="";
    }
    this.verdatelle = true;
    this.clickEvent.emit(data);
  }

  eliminar() {
    let infoUpdate = { id: this.infoMotivo.citasmedicainformacione_id } as any;
    this.ngxService.startLoader('item' + this.infoMotivo.formulario + this.infoMotivo.seccion + this.infoMotivo.codigogrupo + this.infoMotivo.id);
    this.accionFormulario.deleteCitasmedicainformaciones(infoUpdate.id).subscribe((update: any) => {
      if (update.estado) {
        this.infoMotivo.detalle = "";
        this.infoMotivo.valor="";
        this.infoMotivo.seleccion = 0;
        delete this.infoMotivo.guardado;
        this.infoMotivo.visualizar = false;
        this.infoInicialGuardado=undefined;
        this.detalle = "";
        this.valor="";
        this.ngxService.stopLoader('item' + this.infoMotivo.formulario + this.infoMotivo.seccion + this.infoMotivo.codigogrupo + this.infoMotivo.id);
      }
    });
  }

  actualizar() {

    let infoUpdate = { id: this.infoMotivo.citasmedicainformacione_id, detalle: this.detalle ,valor:this.valor} as any;
    
    this.ngxService.startLoader('item' + this.infoMotivo.formulario + this.infoMotivo.seccion + this.infoMotivo.codigogrupo + this.infoMotivo.id);
    this.accionFormulario.updateCitasmedicainformaciones(infoUpdate).subscribe((update: any) => {
      if (update.estado) {
        if (this.infoMotivo.guardado && this.infoMotivo.guardado == 1) {
          this.detalleAnterior=this.detalle;
          if(this.infoMotivo.evaluar){
            this.infoInicialGuardado = this.valor;
          }else{
            this.infoInicialGuardado = this.detalle;
          }
        }
        this.ngxService.stopLoader('item' + this.infoMotivo.formulario + this.infoMotivo.seccion + this.infoMotivo.codigogrupo + this.infoMotivo.id);
      }
    });
  }

  selecopcion(item: any) {

    if (this.infoMotivo.detalle && (this.infoMotivo.detalle == item.id || this.infoMotivo.detalle == item.valor)) {
      this.infoMotivo.detalle = "";
      this.infoMotivo.seleccion = 1;
    } else {
      if (item.valor != null) {
        this.infoMotivo.detalle = item.valor;
        this.detalle = item.valor;
      } else {
        this.infoMotivo.detalle = item.id;
        this.detalle = item.id;
      }
      this.infoMotivo.seleccion = 0;
    }

    this.clickEvent.emit(this.infoMotivo);
  }

}
