import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../../global/global.model';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: '[app-resumen-ver-hospitalizacion]',
  templateUrl: './resumen-ver-hospitalizacion.component.html',
  styleUrls: ['./resumen-ver-hospitalizacion.component.scss']
})
export class ResumenVerHospitalizacionlComponent implements OnInit {
  @Input() idHospital: number;
  dataHospitalSaveOld: any;
  imgEmergencia: string = Globals.imagenEmergencia;
  cantidadCitasHosp: number = 0;
  constructor(private resumen: ElementResumenHospitalizacionComponentService) { }

  ngOnInit() {
    this.cantidadCitasHosp = 0;
    this.resumen.viewHospitalizacionResumen(this.idHospital).subscribe((info: any) => {
      this.dataHospitalSaveOld = info.hospitalizacione;
      this.dataHospitalSaveOld.detallehostipalizaciones.forEach(element => {
        if (element.citasmedica.tipocitamedica.especialidade && element.citasmedica.tipocitamedica.especialidade.nombre == 'HOSPITALIZACIÓN') {
          this.cantidadCitasHosp++;
        }
      });
    });
  }

}
