import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomFormsModule } from 'ng2-validation';
import { ComponentFormaPagoComponent } from './component-forma-pago/component-forma-pago.component';
import { ModalConfirmarPagoElementeModule } from './modal/component-modal-element.module';
import { InfoFormapagoElementComponent } from './info-formapago/info-formapago.component';
import { SelectFormapagoComponentComponent } from './component-select-formapago/component-select-formapago.component';
import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';
import { GraficaResumenComponent } from './grafica-resumen/grafica-resumen.component';
import { AppInfoCitaComponent } from './app-info-cita/app-info-cita.component';
import { ApfInfoCitaComponent } from './apf-info-cita/apf-info-cita.component';
import { OcupacionInfoCitaComponent } from './ocupacion-info-cita/ocupacion-info-cita.component';
import { InformacionPacienteCitaComponent } from './informacion-paciente-cita/informacion-paciente-cita.component';
import { ModalPersonaModule } from '../../appasclepio/configuracion/personas/modal/modalpersona.module';
import { PersonasModule } from '../../appasclepio/configuracion/personas/personas.module';
import { FormAccionesResumenCitaComponent } from './form-acciones-resumen-cita/form-acciones-resumen-cita.component';
import { VerSvgObjectComponent } from './ver-svg-object/ver-svg-object.component';
import { DataSharingImagenService } from './servicios/verimagen.services';
import { FormAccionesAtencionesMedicasComponent } from './form-acciones-atenciones-medicas/form-acciones-atenciones-medicas.component';
import { MedidasListaResumenComponent } from './medidas-lista-resumen/medidas-lista-resumen.component';
import { TiempoListaResumenComponent } from './tiempo-lista-resumen/tiempo-lista-resumen.component';
import { FormEvolucionComponent } from './form-evolucion/form-evolucion.component';
import { HeaderInfoMedicoComponent } from './header-info-medico/header-info-medico.component';
import { GraficaSignosVitalesComponent } from './grafica-signos-vitales/grafica-signos-vitales.component';
import { MedicamentosRecetadosHospitalizacionComponent } from './medicamentos-recetados-hospitalizacion/medicamentos-recetados-hospitalizacion.component';
import { DosisEnfermeriaHospitalizarComponent } from './dosis-enfermeria-hospitalizacion/dosis-enfermeria-hospitalizacion.component';
import { EditarBotonFacturaComponent } from './editar-boton-factura/editar-boton-factura.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { EditarFacturaGeneralComponent } from './editar-factura-general/editar-factura-general.component';
import { ListaPersonasGeneralComponent } from './lista-personas-general/lista-personas-general.component';
import { ListaPendienteExamenCitaComponent } from './lista-pendiente-examen-cita/lista-pendiente-examen-cita.component';
import { CitasListaMedicasInfoComponent } from './lista-citas-medicas-info/lista-citas-medicas-info.component';
import { ItemsListaCitasMedicasFacturaComponent } from './lista-items-citas-medicas-factura/lista-items-citas-medicas-factura.component';
import { ItemListaDetalleFacturaComponent } from './lista-item-detalle-factura/lista-item-detalle-factura.component';
import { ExamnesListaMedicosInfoComponent } from './lista-examnes-medicos-info/lista-examnes-medicos-info.component';
import { ItemsListaExamenesMedicosFacturaComponent } from './lista-items-examenes-medicos-factura/lista-items-examenes-medicos-factura.component';
import { RetencionListaVentasComponent } from './lista-retencion-ventas/lista-retencion-ventas.component';
import { EpicrisisHistoriaClinicaHispitalizacionComponent } from './epicrisis-historia-clinica-hispitalizacion/epicrisis-historia-clinica-hispitalizacion.component';
import { MotivoscitasModule } from '../../appasclepio/configuracion/motivoscitas/motivoscitas.module';
import { ListaCitasHospitalizacionPagosComponent } from './lista-citas-hospitalizacion-pagos/lista-citas-hospitalizacion-pagos.component';
import { ConsultaExternaInformacionResumenComponent } from './consulta-externa-informacion-resumen/consulta-externa-informacion-resumen.component';
import { RecumenGeneralPorHospitalizacionComponent } from './resumen-general-por-hospitalizacion/recumen-general-por-hospitalizacion.component';
import { ElementResumenHospitalizacionComponentService } from './servicios/resumen.hospitalizacion.services';
import { ResumenVerHospitalizacionlComponent } from './resumen-ver-hospitalizacion/resumen-ver-hospitalizacion.component';
import { ResumenSignosVitalesHospitalizacionComponent } from './resumen-signos-vitales-hospitalizacion/resumen-signos-vitales-hospitalizacion.component';
import { ResumenMedicamentosKardexHospitalizacionesComponent } from './resumen-medicamentos-kardex-hospitalizaciones/resumen-medicamentos-kardex-hospitalizaciones.component';
import { DataSharingOpGeneralesService } from './servicios/opgenerales.services';
import { ResumenFormularioF008Component } from './resumen-formulario-f008/resumen-formulario-f008.component';
import { ResumenFormularioF005Component } from './resumen-formulario-f005/resumen-formulario-f005.component';
import { ResumenFormularioF005EnfermeriaComponent } from './resumen-formulario-f005-enfermeria/resumen-formulario-f005-enfermeria.component';
import { ResumenDosisEnfermeriaHospitalizarComponent } from './resumen-dosis-enfermeria-hospitalizacion/resumen-dosis-enfermeria-hospitalizacion.component';
import { ResumenFormularioF006Component } from './resumen-formulario-f006/resumen-formulario-f006.component';
import { F006Seccion7Component } from './resumen-secciones-formularios/f006-seccion7/f006-seccion7.component';
import { F006Seccion9Component } from './resumen-secciones-formularios/f006-seccion9/f006-seccion9.component';
import { F008Seccion1Component } from './resumen-secciones-formularios/f008-seccion1/f008-seccion1.component';
import { F008Seccion2Component } from './resumen-secciones-formularios/f008-seccion2/f008-seccion2.component';
import { F008Seccion3Component } from './resumen-secciones-formularios/f008-seccion3/f008-seccion3.component';
import { F008Seccion4Component } from './resumen-secciones-formularios/f008-seccion4/f008-seccion4.component';
import { F008Seccion5Component } from './resumen-secciones-formularios/f008-seccion5/f008-seccion5.component';
import { F008Seccion6Component } from './resumen-secciones-formularios/f008-seccion6/f008-seccion6.component';
import { F008Seccion7Component } from './resumen-secciones-formularios/f008-seccion7/f008-seccion7.component';
import { F008Seccion8Component } from './resumen-secciones-formularios/f008-seccion8/f008-seccion8.component';
import { F008Seccion9Component } from './resumen-secciones-formularios/f008-seccion9/f008-seccion9.component';
import { F008Seccion10Component } from './resumen-secciones-formularios/f008-seccion10/f008-seccion10.component';
import { ResumenRecetaAltaPacienteComponent } from './resumen-receta-alta-paciente/resumen-receta-alta-paciente.component';
import { MedicamentosRecetadosEmergenciaComponent } from './medicamentos-recetados-emergencia/medicamentos-recetados-emergencia.component';
import { ResumenGeneralCitasEmergenciasComponent } from './resumen-general-citas-emergencias/resumen-general-citas-emergencias.component';
import { ResumenSignosVitalesEmergenciaComponent } from './resumen-signos-vitales-emergencia/resumen-signos-vitales-emergencia.component';
import { ResumenMedicamentosKardexCitaEmergenciaComponent } from './resumen-medicamentos-kardex-cita-emergencia/resumen-medicamentos-kardex-cita-emergencia.component';
import { ResumenFormularioF005EnfermeriaCitaMedicaComponent } from './resumen-formulario-f005-enfermeria-cita-medica/resumen-formulario-f005-enfermeria-cita-medica.component';
import { ResumenRecetaAltaPacientePorCitaComponent } from './resumen-receta-alta-paciente-cita-medica/resumen-receta-alta-paciente-cita-medica.component';
import { MedidasListaResumenControlComponent } from './medidas-lista-resumen-control/medidas-lista-resumen-control.component';
import { MedicamentoControlValoresResumenComponent } from './medicamento-control-valores-resumen/medicamento-control-valores-resumen.component';
import { DosisEnfermeriaHospitalizarResumenComponent } from './dosis-enfermeria-hospitalizacion-resumen/dosis-enfermeria-hospitalizacion-resumen.component';
import { ListaMedicinaComplementariaComponent } from './lista-medicina-complementaria/lista-medicina-complementaria.component';
import { PonerHoraFechaAtencionComponent } from './poner-hora-fecha-atencion/poner-hora-fecha-atencion.component';
import { VerControGlicemicoComponent } from './ver-contro-glicemico/ver-contro-glicemico.component';
import { MspReportesService } from './servicios/msprepotrtes.servicios';
import { CertificadoMedicosComponent } from './certificado-medicos/certificado-medicos.component';
import { AppAnularHospitalizacionComponent } from './app-anular-hospitalizacion/app-anular-hospitalizacion.component';
import { DiagnosticoDientesComponent } from './diagnostico-dientes/diagnostico-dientes.component';

@NgModule({
  declarations: [ComponentFormaPagoComponent,
    InfoFormapagoElementComponent,
    SelectFormapagoComponentComponent,
    GraficaResumenComponent,
    AppInfoCitaComponent,
    ApfInfoCitaComponent,
    OcupacionInfoCitaComponent,
    InformacionPacienteCitaComponent,
    VerSvgObjectComponent,
    FormAccionesResumenCitaComponent,
    MedidasListaResumenComponent,
    TiempoListaResumenComponent,
    FormAccionesAtencionesMedicasComponent,
    FormEvolucionComponent,
    HeaderInfoMedicoComponent,
    GraficaSignosVitalesComponent,
    DosisEnfermeriaHospitalizarComponent,
    MedicamentosRecetadosHospitalizacionComponent,
    EditarBotonFacturaComponent,
    EditarFacturaGeneralComponent,
    ListaPersonasGeneralComponent,
    CitasListaMedicasInfoComponent,
    ItemsListaCitasMedicasFacturaComponent,
    ItemListaDetalleFacturaComponent,
    ExamnesListaMedicosInfoComponent,
    ItemsListaExamenesMedicosFacturaComponent,
    RetencionListaVentasComponent,
    ListaPendienteExamenCitaComponent,
    EpicrisisHistoriaClinicaHispitalizacionComponent,
    ListaCitasHospitalizacionPagosComponent,
    ConsultaExternaInformacionResumenComponent,
    RecumenGeneralPorHospitalizacionComponent,
    ResumenVerHospitalizacionlComponent,
    ResumenSignosVitalesHospitalizacionComponent,
    ResumenDosisEnfermeriaHospitalizarComponent,
    ResumenMedicamentosKardexHospitalizacionesComponent,
    MedicamentosRecetadosEmergenciaComponent,
    ResumenFormularioF008Component,
    ResumenFormularioF005Component,
    ResumenFormularioF006Component,
    ResumenFormularioF005EnfermeriaComponent,
    F006Seccion7Component,
    F006Seccion9Component,
    F008Seccion1Component,
    F008Seccion2Component,
    F008Seccion3Component,
    F008Seccion4Component,
    F008Seccion5Component,
    F008Seccion6Component,
    F008Seccion7Component,
    F008Seccion8Component,
    F008Seccion9Component,
    F008Seccion10Component,
    ResumenRecetaAltaPacienteComponent,
    ResumenGeneralCitasEmergenciasComponent,
    ResumenSignosVitalesEmergenciaComponent,
    ResumenFormularioF005EnfermeriaCitaMedicaComponent,
    ResumenRecetaAltaPacientePorCitaComponent,
    MedidasListaResumenControlComponent,
    MedicamentoControlValoresResumenComponent,
    DosisEnfermeriaHospitalizarResumenComponent,
    ResumenMedicamentosKardexCitaEmergenciaComponent,
    ListaMedicinaComplementariaComponent,
    PonerHoraFechaAtencionComponent,
    VerControGlicemicoComponent,
    CertificadoMedicosComponent,
    DiagnosticoDientesComponent,
    AppAnularHospitalizacionComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    CustomFormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalConfirmarPagoElementeModule,
    ChartsModule,
    ChartistModule,
    ModalPersonaModule,
    PersonasModule,
    NgxUiLoaderModule,
    MotivoscitasModule
  ],
  providers: [
    DataSharingImagenService,
    DataSharingOpGeneralesService,
    ElementResumenHospitalizacionComponentService,
    MspReportesService
  ],
  exports: [
    ComponentFormaPagoComponent,
    InfoFormapagoElementComponent,
    GraficaResumenComponent,
    AppInfoCitaComponent,
    ApfInfoCitaComponent,
    OcupacionInfoCitaComponent,
    InformacionPacienteCitaComponent,
    FormAccionesResumenCitaComponent,
    FormAccionesAtencionesMedicasComponent,
    MedidasListaResumenComponent,
    TiempoListaResumenComponent,
    FormEvolucionComponent,
    HeaderInfoMedicoComponent,
    GraficaSignosVitalesComponent,
    MedicamentosRecetadosHospitalizacionComponent,
    EditarBotonFacturaComponent,
    EditarFacturaGeneralComponent,
    ListaPersonasGeneralComponent,
    ListaPendienteExamenCitaComponent,
    CitasListaMedicasInfoComponent,
    EpicrisisHistoriaClinicaHispitalizacionComponent,
    ListaCitasHospitalizacionPagosComponent,
    ConsultaExternaInformacionResumenComponent,
    RecumenGeneralPorHospitalizacionComponent,
    ResumenVerHospitalizacionlComponent,
    ResumenMedicamentosKardexHospitalizacionesComponent,
    ResumenDosisEnfermeriaHospitalizarComponent,
    ResumenFormularioF008Component,
    MedicamentosRecetadosEmergenciaComponent,
    ResumenGeneralCitasEmergenciasComponent,
    ResumenRecetaAltaPacientePorCitaComponent,
    ResumenFormularioF005EnfermeriaCitaMedicaComponent,
    MedidasListaResumenControlComponent,
    MedicamentoControlValoresResumenComponent,
    DosisEnfermeriaHospitalizarResumenComponent,
    ListaMedicinaComplementariaComponent,
    PonerHoraFechaAtencionComponent,
    VerControGlicemicoComponent,
    CertificadoMedicosComponent,
    AppAnularHospitalizacionComponent,
    DiagnosticoDientesComponent
  ]
})
export class ElementComponentModule {
  DataSharingOpGeneralesService
 }
