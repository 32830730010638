import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-f006-seccion7]',
  templateUrl: './f006-seccion7.component.html',
  styleUrls: ['./f006-seccion7.component.scss']
})
export class F006Seccion7Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  constructor() { }

  ngOnInit() {
  }

}
