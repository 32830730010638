import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';
import { DataSharingImagenService } from '../servicios/verimagen.services';

@Component({
  selector: 'app-form-acciones-resumen-cita',
  templateUrl: './form-acciones-resumen-cita.component.html',
  styleUrls: ['./form-acciones-resumen-cita.component.scss']
})
export class FormAccionesResumenCitaComponent implements OnInit {
  @Input() idCitaMedica: number;
  @Input() idAtencionMedica: number;
  @Input() formulario: string;
  @Input() numero: number = 0;
  @Input() title: string = "";
  @Input() pathfield;
  todo: boolean = true;
  dataInfoCitaMedicaResumen: any;
  verInfoCita: boolean = true;
  tmpLista = [];
  tmps = [];

  
  infosendParte = [];
  verInfoRoot: boolean = false;

  constructor(private servicioApps: ElementComponentService,
    private DataSharing: DataSharingImagenService,
  ) { }

  ngOnInit() {
    
    

    if (this.idCitaMedica == undefined) {
      this.idCitaMedica = null;
      console.log(this.idAtencionMedica);
    } else if (this.idAtencionMedica == undefined) {
      this.idAtencionMedica = null;
    }

   

    this.servicioApps.getDataCitasMotivosFormResumen(this.formulario, this.numero, this.idCitaMedica, this.idAtencionMedica).subscribe((data: any) => {
      this.dataInfoCitaMedicaResumen = data.citasmedicainformacione;
      console.log(this.dataInfoCitaMedicaResumen);
      if (this.numero != 6) {
        this.dataInfoCitaMedicaResumen.motivos.forEach(element => {
          element.seleccion = 0;
          element.visualizar = false;

          this.dataInfoCitaMedicaResumen.lista.forEach(elementasignado => {
            if (element.id == elementasignado.motivoscita_id) {
              element.guardado = 1;
              element.seleccion = 1;
              element.visualizar = true;
              element.detalle = elementasignado.detalle;
              element.valor = elementasignado.valor;
              element.citasmedicainformacione_id = elementasignado.id;
            }
          });
        });
        this.tmps = this.dataInfoCitaMedicaResumen.motivos;
        this.tmpLista = this.tmps.filter(function (el) {
          return el.seleccion == 1;
        });

        if (!this.todo) {
          var temp = this.tmps.filter(function (el) {
            return el.seleccion == 1;
          });
          this.dataInfoCitaMedicaResumen.motivos = temp;
        }
      }
      else if (this.numero == 6) {
        
      }

    });
  }

  verInfoCitaDetalle() {
    if (this.verInfoCita == true) {
      this.verInfoCita = false;
    } else {
      this.verInfoCita = true;
    }
  }

  verInformacion() {
    this.todo = !this.todo;
    if (!this.todo) {
      var temp = this.tmps.filter(function (el) {
        return el.seleccion == 1;
      });
      this.dataInfoCitaMedicaResumen.motivos = [...temp];

      if (this.pathfield != undefined) {
        this.infosendParte = [];
        this.dataInfoCitaMedicaResumen.lista.forEach(elementgrafico => {
          let infoParte = {} as any;
          for (let k = 0; k < this.dataInfoCitaMedicaResumen.motivos.length; k++) {
            const elementgraf = this.dataInfoCitaMedicaResumen.motivos[k];
            if (elementgrafico.motivoscita_id == elementgraf.id) {
              infoParte = { numero: elementgrafico.detalle, data: elementgraf, base: 1 } as any;
              break;
            }
          }
          this.infosendParte.push(infoParte);
        });

        this.verInfoRoot = false;
        let itemCompleto = { lista: this.infosendParte, info: undefined } as any;
        this.DataSharing.SharingData.next(itemCompleto);
      }

    } else {
      this.dataInfoCitaMedicaResumen.motivos = [...this.tmps];
      let itemCompleto = { lista: [], info: undefined } as any;
      this.DataSharing.SharingData.next(itemCompleto);
    }

  }

  

  valorItemRoot: number = 0;
  listaMostraInfo = [];

  seleccionParte(data: any) {
    this.valorItemRoot = data.numero;
    this.verInfoRoot = data.estado;

    let indice = -1;
    let encontrado: boolean = false;
    let base: number = 0;
    for (let index = 0; index < this.infosendParte.length; index++) {
      const element = this.infosendParte[index];
      if (element.numero == data.numero && data.info && element.data.id == data.info.id) {
        indice = index;
        base = element.base;
        encontrado = true;
        break;
      }
    }

    let infoParte = { numero: this.valorItemRoot, data: data.info, base: 0 } as any;
    if (data.info != undefined && !encontrado && base == 0) {
      this.infosendParte.push(infoParte);
    } else if (data.info != undefined && encontrado && base == 0) {
      this.infosendParte.splice(indice, 1);
    }

    this.listaMostraInfo = [];
    this.infosendParte.forEach(element => {

      if ((element.base == 1) && element.numero == data.numero) {
        this.listaMostraInfo.push(element);
      }
    }, this);
  }

  verInfRoot() {
    this.verInfoRoot = !this.verInfoRoot;
  }

}
