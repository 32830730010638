import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Parroquias } from '../../parroquia/parroquias.model';
import { ParroquiasServices } from '../../parroquia/parroquias.services';
import { Cantones } from '../../../cantones/cantone/cantones.model';
import { CantonesServices } from '../../../cantones/cantone/cantones.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addparroquia',
	templateUrl: './addparroquia.component.html',
	styleUrls: ['./addparroquia.component.scss']
})

export class AddParroquiaComponent implements OnInit {
	@Input() cantone_id:number;
	public dataCantones:any;
	public formAddParroquia: FormGroup;
	public dataZonas:any;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioParroquia:ParroquiasServices,
		private servicioCantone:CantonesServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioCantone.getDataCantones().subscribe((data:any)=>{
			this.dataCantones=data.cantones;
		});
		this.servicioParroquia.getDataZonas().subscribe((data:any)=>{
			this.dataZonas=data.zonas;
		});
		this.formAddParroquia = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
			cantone_id:[this.cantone_id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
			zona_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	save(){
		let parroquiaDataModel:Parroquias=this.formAddParroquia.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioParroquia.saveParroquias(parroquiaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(data);
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(0);
	}
}