import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { customValidatorMondogoComponente } from './servicios/customValidator.services';
import { ElementComponentModule } from './elementos/elemento.module';
import { AppElementExamenModule } from './elementexamenes/appelementexamenes.module';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        NgbModule,
        DragulaModule,
        QuillModule,
        ElementComponentModule,
        AppElementExamenModule
    ],
    providers: [
        customValidatorMondogoComponente
    ],
    declarations: []
})

export class AppComponenteElementModule { }
