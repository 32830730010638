import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { EtniasVistaComponent } from './vistas/etniavista.component';

export const EtniasRoutes: Routes = [{
	path: '',
	redirectTo: 'etnias-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'etnias-index',
		component: EtniasVistaComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Lista de etnias',
			roles: ['Gerente'],
			urls: [
			  { title: 'Home', url: '/home' },
			  { title: 'Página de inicio' }
			]
		},
	}]
}];
