import { Component, Input, OnInit } from '@angular/core';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: '[app-resumen-formulario-f005-enfermeria-cita-medica]',
  templateUrl: './resumen-formulario-f005-enfermeria-cita-medica.component.html',
  styleUrls: ['./resumen-formulario-f005-enfermeria-cita-medica.component.scss']
})
export class ResumenFormularioF005EnfermeriaCitaMedicaComponent implements OnInit {
  @Input() idCitaMedica: number;
  rows=[];
  
  constructor( private reporteInfermeria: ElementResumenHospitalizacionComponentService,) { }

  ngOnInit() {
    this.reporteInfermeria.viewReporteenfermeriasPorCitaMedica(this.idCitaMedica).subscribe((dataRepo:any)=>{
      if(dataRepo){
        this.rows=dataRepo.reporteenfermerias;
      }
    });
  }

  retornar(row:any):any{
    let lista = [row.persona.nombre, row.persona.apellido];
    let resultado3 = "";
    for (let i = 0; i < lista.length; i++){
      lista[i].split(' ').reduce((r,w)=> resultado3+=w.slice(0,1),'');
    }
    return  {ac:resultado3};
  } 

}
