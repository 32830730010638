import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class MspReportesService {

    constructor(private http: HttpClient) { }

    getMSPForm008(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_008.json',data);
    }

    getMSPForm005(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_005.json',data);
    }

    getMSPForm004(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_004.json',data);
    }

    getMSPForm004Emergencia(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_004_emergencia.json',data);
    }

    getMSPForm0022(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_0022.json',data);
    }

    getMSPForm0022Emergencia(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_0022_emergencia.json',data);
    }

    getMSPForm005Enfermeria(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_005_enfermaria.json',data);
    }

    getMSPForm006(data:any): Observable<any[]> {
        return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'msp_informe_form_006.json',data);
    }

    viewInfoPaciente(id:number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'historiasclinicas/'+id+'.json');
    }

}