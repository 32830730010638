import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Parroquias, Zonas } from '../../parroquia/parroquias.model';
import { ParroquiasServices } from '../../parroquia/parroquias.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Cantones } from '../../../cantones/cantone/cantones.model';
import { CantonesServices } from '../../../cantones/cantone/cantones.services';

@Component({
	selector: 'app-editparroquia',
	templateUrl: './editparroquia.component.html',
	styleUrls: ['./editparroquia.component.scss']
})

export class EditParroquiaComponent implements OnInit {

	@Input() Parroquia:Parroquias;
	public dataCantones={'nombre':'vacio'} as Cantones;
	public cantonesData:any;
	public dataZonas={'nombre':'vacio'} as Zonas;
	public zonasData:any;
	
	public formParroquiaZona:FormGroup;
	public editZona:boolean=false;

	public formParroquiaNombre:FormGroup;
	public editNombre:boolean=false;

	public formParroquiaCantone:FormGroup;
	public editCantone:boolean=false;

	public formParroquiaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioParroquia:ParroquiasServices,
		private servicioCantone:CantonesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioCantone.getDataCantones().subscribe((data:any)=>{
			this.cantonesData=data.cantones;
			this.dataCantones=this.cantonesData.find(obj => obj.id == this.Parroquia.cantone_id);
		});

		this.servicioParroquia.getDataZonas().subscribe((data:any)=>{
			this.zonasData=data.zonas;
			if(this.Parroquia.zona_id)
				this.dataZonas=this.zonasData.find(obj => obj.id == this.Parroquia.zona_id);
		});

		this.formParroquiaNombre=this.fb.group({
			nombre:[this.Parroquia.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
		});

		this.formParroquiaCantone=this.fb.group({
			cantone_id:[this.Parroquia.cantone_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
		this.formParroquiaZona=this.fb.group({
			zona_id:[this.Parroquia.zona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editParroquiaNombreEnable(){
		this.editNombre=true;
		this.formParroquiaNombre=this.fb.group({
			nombre:[this.Parroquia.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
		});
	}

	editParroquiaNombreDisable(){
		this.editNombre=false;
	}

	saveParroquiaNombre(){
		let ParroquiaDataModel:Parroquias=this.formParroquiaNombre.value;
		ParroquiaDataModel.id=this.Parroquia.id;
		this.Parroquia.nombre=ParroquiaDataModel.nombre;
		this.servicioParroquia.updateParroquias(ParroquiaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editParroquiaCantoneEnable(){
		this.editCantone=true;
		this.formParroquiaCantone=this.fb.group({
			cantone_id:[this.Parroquia.cantone_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editParroquiaCantoneDisable(){
		this.editCantone=false;
	}

	saveParroquiaCantone(){
		let ParroquiaDataModel:Parroquias=this.formParroquiaCantone.value;
		ParroquiaDataModel.id=this.Parroquia.id;
		this.Parroquia.cantone_id=ParroquiaDataModel.cantone_id;
		this.dataCantones=this.cantonesData.find(obj => obj.id == this.Parroquia.cantone_id);
		this.servicioParroquia.updateParroquias(ParroquiaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCantone=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

	editParroquiaZonaEnable(){
		this.editZona=true;
		this.formParroquiaZona=this.fb.group({
			zona_id:[this.Parroquia.zona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editParroquiaZonaDisable(){
		this.editZona=false;
	}

	saveParroquiaZona(){
		let ParroquiaDataModel:Parroquias=this.formParroquiaZona.value;
		ParroquiaDataModel.id=this.Parroquia.id;
		this.Parroquia.zona_id=ParroquiaDataModel.zona_id;
		this.servicioParroquia.updateParroquias(ParroquiaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editZona=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

}

