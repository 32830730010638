import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { EtniasServices} from '../etnia/etnias.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Etnias } from '../etnia/etnias.model';
import { AddEtniaComponent } from '../modal/add/addetnia.component';
import { EditEtniaComponent } from '../modal/edit/editetnia.component';

@Component({
	selector: 'ms-etniavista',
	templateUrl:'./etniavista.component.html',
	styleUrls: ['./etniavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class EtniasVistaComponent implements OnInit {
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refescar'){
			this.listaEtnias();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosEtnias:EtniasServices,
		private notificationServices:NotificationServices,
	){}

	ngOnInit(){
		this.listaEtnias();
	}

	listaEtnias(){
		this.serviciosEtnias.getDataEtnias().subscribe((data:any)=>{
			this.rows=data.etnias;
			this.temp = [...this.rows];
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddEtniaComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaEtnias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataEtnia:any){
		let data={'val':dataEtnia.nombre,'msg':'Eliminar Etnias'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosEtnias.deleteEtnias(dataEtnia.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaEtnias();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditEtniaComponent, this.config);
		dialogRef.componentInstance.Etnia = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaEtnias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataEtnia:Etnias){
		let dataEstadoEtnia= {} as Etnias;
		dataEstadoEtnia.id=dataEtnia.id;
		dataEstadoEtnia.estado=Number(!dataEtnia.estado);
		this.serviciosEtnias.updateEtnias(dataEstadoEtnia).subscribe((data:any)=>{});
	}
}

