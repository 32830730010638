import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-apf-info-cita',
  templateUrl: './apf-info-cita.component.html',
  styleUrls: ['./apf-info-cita.component.scss']
})
export class ApfInfoCitaComponent implements OnInit {

  @Input() idHistoriaMedica: number;
  rows=[];
  verInfoCita:boolean=true;

  constructor(private servicioApps: ElementComponentService) { }

  ngOnInit() {
    this.servicioApps.getDataViewApfs(this.idHistoriaMedica).subscribe((data: any) => {
      this.rows = (data.apfs);
    });
  }

  verInfoCitaDetalle(){
    if(this.verInfoCita==true){
      this.verInfoCita=false;
    }else{
      this.verInfoCita=true;
    }
  }
}
