import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-f006-seccion9]',
  templateUrl: './f006-seccion9.component.html',
  styleUrls: ['./f006-seccion9.component.scss']
})
export class F006Seccion9Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  constructor() { }

  ngOnInit() {
  }

}
