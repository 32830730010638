import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Citasmedicainformaciones } from '../citasmedicainformacione/citasmedicainformaciones.model';
import { CitasmedicainformacionesServices } from '../citasmedicainformacione/citasmedicainformaciones.services';
import { MotivoscitasServices } from '../motivoscita/motivoscitas.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { HttpClient } from '@angular/common/http';
import { parse } from 'svgson';
import { DataSharingService } from '../citasmedicainformacione/imagensgv.services';
declare var $: any;

@Component({
  selector: 'app-acciones-formularios-citas',
  templateUrl: './acciones-formularios-citas.component.html',
  styleUrls: ['./acciones-formularios-citas.component.scss']
})
export class AccionesFormulariosCitasComponent implements OnInit {
  //selec 1 solo uno / 2 multiple seccion 
  //accion esta en 1 esta insertar en grupo 2 para imagen lado izq
  // op es para ver si se trabaja con 1 Citas medicas 2 con Atenciones medicas
  //Formulario con el que se trabaja mas la seccion
  // evaluar la condicion que evaluara en opsiones para poner texto detalle 


  @Input() citaMedica: any;
  @Input() citaAtencionMedica: any;
  @Input() formulario: string;
  @Input() seccion: number;
  @Input() selec: number;
  @Input() titulo: string;
  @Input() accion: number;
  @Input() op: number;
  @Input() evaluar: string;
  lista = [];
  listaagrupada = [];
  tipo_guardado: boolean = true;
  userdata: any;
  groupLista: any;
  valorItemRoot: string = "";
  verInfoRoot: boolean = false;
  dataInfoGeneral: any;
  dataBuscarPaciente = { buscar_paciente: "" } as any;

  temp = [];
  infosend = [];
  infosendParte = [];
  listaMostraInfo = [];
  public svgIcon: any;

  //para el formularios 008 seccion 7
  sumaGlasgow: number = 0;
  dataInfoGlasgow = { ocular: 0, verbal: 0, motora: 0 } as any;

  constructor(private accionFormulario: CitasmedicainformacionesServices,
    private ngxService: NgxUiLoaderService,
    public localdata: localStorageServices,
    private httpClient: HttpClient,
    private DataSharing: DataSharingService,
    private motivoFormulario: MotivoscitasServices) { }

  ngOnInit() {



    if (this.citaMedica == undefined && this.op == 2) {
      this.citaMedica = { id: null } as any;
    } else if (this.citaAtencionMedica == undefined && this.op == 1) {
      this.citaAtencionMedica = { id: null } as any;
    }

    this.userdata = this.localdata.getValueFromLocalStorage();
    this.buscarMotivo();
    if (this.accion == 2) {
      if (this.citaMedica.historiasclinica.persona.sexo == 'F') {
        this.leerGraficaMujer();
      } else {
        this.leerGrafica();
      }

    }
  }


  leerGrafica() {
    this.svgIcon = undefined;
    this.infosendParte = [];
    this.httpClient
      .get('assets/images/hombre.svg', { responseType: 'text' })
      .subscribe((value: any) => {
        parse(value).then((infoJson: any) => {
          let info = infoJson;

          let tmpItem = [];
          for (let index = 0; index < info.children.length; index++) {
            const element = info.children[index];
            let g2: any = element;
            g2.value = index;
            g2.seccion = 0;
            let g1 = { name: 'g', type: "element", value: "", attributes: { "class": "svg-menu__path__seleccion__background" }, children: [g2] } as any;
            let g = {
              name: 'g', type: "element", value: "", attributes: {
                "class": "svg-menu__path__seleccion",
                "cursor": "pointer",
                "pointer-events": "all"
              }, children: [g1]
            } as any;
            tmpItem.push(g);
          }
          info.children = tmpItem;
          this.svgIcon = info;
        })
      });
  }

  leerGraficaMujer() {
    this.svgIcon = undefined;
    this.infosendParte = [];
    this.httpClient
      .get('assets/images/mujer.svg', { responseType: 'text' })
      .subscribe((value: any) => {
        parse(value).then((infoJson: any) => {
          let info = infoJson;
          let tmpItem = [];
          for (let index = 0; index < info.children.length; index++) {
            const element = info.children[index];
            let g2: any = element;
            g2.value = index;
            g2.seccion = 0;
            let g1 = { name: 'g', type: "element", value: "", attributes: { "class": "svg-menu__path__seleccion__background" }, children: [g2] } as any;
            let g = {
              name: 'g', type: "element", value: "", attributes: {
                "class": "svg-menu__path__seleccion",
                "cursor": "pointer",
                "pointer-events": "all"
              }, children: [g1]
            } as any;
            tmpItem.push(g);
          }
          info.children = tmpItem;
          this.svgIcon = info;
        })
      });
  }

  buscarMotivo() {
    this.lista = [];
    this.listaagrupada = [];
    this.infosend = [];
    this.dataBuscarPaciente.buscar_paciente = "";
    this.accionFormulario.getDataCitasMotivos(this.formulario, this.seccion, this.citaMedica.id, this.citaAtencionMedica.id).subscribe((motivos: any) => {
      if (motivos.citasmedicainformacione) {
        let listaasignado = motivos.citasmedicainformacione;
        if (listaasignado.length == 0)
          this.tipo_guardado = false;
        if (this.accion == 1) {
          this.groupLista = this.groupBy(listaasignado, row => row.codigo);

          if (this.groupLista.size == 0) {
            this.motivoFormulario.getDataCitasFormularioMotivo(this.formulario, this.seccion).subscribe((info: any) => {
              this.lista = info.motivoscitas;
              this.lista.forEach(element => {
                element.seleccion = 0;
                element.visualizar = false;
                if (this.evaluar != undefined) {
                  element.evaluar = this.evaluar;
                }
                if (this.accion != undefined) {
                  element.accion = this.accion;
                }

                listaasignado.forEach(elementasignado => {
                  if (element.id == elementasignado.motivoscita_id) {
                    element.guardado = 1;
                    element.seleccion = 1;
                    element.visualizar = true;
                    element.detalle = elementasignado.detalle;
                    element.valor = elementasignado.valor;
                    element.citasmedicainformacione_id = elementasignado.id;
                  }
                });
              });

              this.temp = [...this.lista];

              if (this.tipo_guardado) {
                const temp = this.temp.filter(function (d) {
                  return d.visualizar == true;
                });
                this.lista = temp;
              }
            });

          } else if (this.groupLista.size >= 1) {
            for (let [key, value] of this.groupLista) {


              this.motivoFormulario.getDataCitasFormularioMotivo(this.formulario, this.seccion).subscribe((info: any) => {
                this.lista = info.motivoscitas;

                this.lista.forEach(element => {
                  element.seleccion = 0;
                  element.visualizar = false;
                  if (this.evaluar != undefined) {
                    element.evaluar = this.evaluar;
                  }
                  if (this.accion != undefined) {
                    element.accion = this.accion;
                  }
                  value.forEach(elementasignado => {
                    element.codigogrupo = elementasignado.codigo;
                    if (element.id == elementasignado.motivoscita_id) {
                      element.guardado = 1;
                      element.seleccion = 1;
                      element.visualizar = true;
                      element.detalle = elementasignado.detalle;
                      element.valor = elementasignado.valor;
                      element.citasmedicainformacione_id = elementasignado.id;
                    }
                  });

                });
                this.temp = [...this.lista];

                this.listaagrupada.push(this.lista);
              });



            }



            this.motivoFormulario.getDataCitasFormularioMotivo(this.formulario, this.seccion).subscribe((info: any) => {
              let listatmp = info.motivoscitas;
              listatmp.forEach(element => {
                //element.guardado = 0;
                if (this.evaluar != undefined) {
                  element.evaluar = this.evaluar;
                }
                if (this.accion != undefined) {
                  element.accion = this.accion;
                }
                element.seleccion = 0;
                element.visualizar = false;
              });
              this.listaagrupada.push(listatmp);
            });

          }
        }
        else {

          this.motivoFormulario.getDataCitasFormularioMotivo(this.formulario, this.seccion).subscribe((info: any) => {
            this.lista = info.motivoscitas;

            this.lista.forEach(element => {
              element.seleccion = 0;
              element.visualizar = false;
              if (this.evaluar != undefined) {
                element.evaluar = this.evaluar;
              }
              if (this.accion != undefined) {
                element.accion = this.accion;
              }
              listaasignado.forEach(elementasignado => {
                if (element.id == elementasignado.motivoscita_id) {
                  element.guardado = 1;
                  element.seleccion = 1;
                  element.visualizar = true;
                  element.detalle = elementasignado.detalle;
                  element.valor = elementasignado.valor;
                  element.citasmedicainformacione_id = elementasignado.id;
                }
              });

            });

            if (this.formulario == 'FORM008' && this.seccion == 7) {
              this.lista.forEach(element => {
               
                if (element.guardado == 1 && element.nombre == 'OCULAR') {
                  this.dataInfoGlasgow.ocular = element.detalle;
                } else if (element.guardado == 1 && element.nombre == 'VERBAL') {
                  this.dataInfoGlasgow.verbal = element.detalle;
                } else if (element.guardado == 1 && element.nombre == 'MOTORA') {
                  this.dataInfoGlasgow.motora = element.detalle;
                }
                      
              });
              this.sumaGlasgow = Number(this.dataInfoGlasgow.ocular) + Number(this.dataInfoGlasgow.verbal) + Number(this.dataInfoGlasgow.motora);
              
            }

            if (this.accion == 2) {


              for (let ll = 0; ll < listaasignado.length; ll++) {
                let elementgrafico = listaasignado[ll];
                let infoParte = {} as any;
                let elementgraf;
                for (let k = 0; k < this.lista.length; k++) {
                  elementgraf = this.lista[k] as any;

                  if (elementgrafico.motivoscita_id == elementgraf.id) {
                    infoParte = {
                      numero: elementgrafico.detalle, data: {
                        id: elementgraf.id,
                        guardado: 1,
                        seleccion: 1,
                        nombre: elementgraf.nombre,
                        opcionesmotivos: elementgraf.opcionesmotivos,
                        codigo: elementgraf.codigo,
                        visualizar: true,
                        detalle: elementgrafico.detalle,
                        valor: elementgrafico.valor,
                        accion: elementgraf.accion,
                        formulario: elementgraf.formulario,
                        orden: elementgraf.orden,
                        citasmedicainformacione_id: elementgrafico.id
                      }, base: 1
                    } as any;
                    this.infosendParte.push(infoParte);
                  }
                }
              }

              this.verInfoRoot = false;
              let itemCompleto = { lista: this.infosendParte, info: undefined } as any;
              this.DataSharing.SharingData.next(itemCompleto);


            }
            this.temp = [...this.lista];

            if (this.tipo_guardado) {
              const temp = this.temp.filter(function (d) {
                return d.visualizar == true;
              });
              this.lista = temp;
            }
          });

        }

      }

    });

  }

  seleccionar(data: any) {

    if (this.accion == 2) {
      this.dataInfoGeneral = data;
      this.verInfoRoot = false;
      let listaGuardada = [];
      let listaNoGuardada = [];
      for (let l = 0; l < this.infosendParte.length; l++) {
        const element = this.infosendParte[l];
        if (element.base == 0) {
          listaNoGuardada.push(element);
        } else {
          listaGuardada.push(element);
        }
      }
      this.infosendParte = listaGuardada;
      let itemCompleto = { lista: this.infosendParte, info: data } as any;
      this.DataSharing.SharingData.next(itemCompleto);


    }

    let indice = 0;

    for (let index = 0; index < this.lista.length; index++) {
      const element = this.lista[index];
      if (element.id == data.id) {
        indice = index;
        break;
      }
    }
    let estadoanterior = this.lista[indice].seleccion;

    if (this.selec == 1) {
      this.limpiar();

      if (this.lista[indice].seleccion == 0) {
        this.lista[indice].seleccion = 1;
      } else if (this.lista[indice].seleccion == 1) {
        this.lista[indice].seleccion = 0;
      }


      let dataSendMotivo = {} as any;
      if (this.citaMedica.id == null && this.op == 2 && this.lista[indice].guardado == undefined) {
        dataSendMotivo = { motivoscita_id: this.lista[indice].id, atencionesmedica_id: this.citaAtencionMedica.id, estado: 1, detalle: data.detalle, persona_id: this.userdata.datos.persona.id, valor: data.valor } as Citasmedicainformaciones;
      } else if (this.citaAtencionMedica.id == null && this.op == 1 && this.lista[indice].guardado == undefined) {
        dataSendMotivo = { motivoscita_id: this.lista[indice].id, citasmedica_id: this.citaMedica.id, estado: 1, detalle: data.detalle, persona_id: this.userdata.datos.persona.id, valor: data.valor } as Citasmedicainformaciones;
      }
      if (this.lista[indice].guardado == undefined)
        this.infosend.push(dataSendMotivo);


    } else if (this.selec == 2) {
      if (this.lista[indice].seleccion == 0) {
        this.lista[indice].seleccion = 1;
      } else if (this.lista[indice].seleccion == 1) {
        this.lista[indice].seleccion = 0;
      }

      let encontrado: boolean = false;
      let indiceItem: number = 0;


      for (let i = 0; i < this.infosend.length; i++) {
        const element = this.infosend[i];
        if (element.citasmedica_id == this.citaMedica.id && element.motivoscita_id == data.id && this.op == 1) {
          encontrado = true;
          indiceItem = i;
          break;
        } else if (element.atencionesmedica_id == this.citaAtencionMedica.id && element.motivoscita_id == data.id && this.op == 2) {
          encontrado = true;
          indiceItem = i;
          break;
        }
      }

      if (!encontrado) {
        let dataSendMotivo = {} as any;
        if (this.citaMedica.id == null && this.op == 2 && this.lista[indice].guardado == undefined) {
          dataSendMotivo = { motivoscita_id: this.lista[indice].id, atencionesmedica_id: this.citaAtencionMedica.id, estado: 1, detalle: data.detalle, persona_id: this.userdata.datos.persona.id, valor: data.valor } as Citasmedicainformaciones;
        } else if (this.citaAtencionMedica.id == null && this.op == 1 && this.lista[indice].guardado == undefined) {
          dataSendMotivo = { motivoscita_id: this.lista[indice].id, citasmedica_id: this.citaMedica.id, estado: 1, detalle: data.detalle, persona_id: this.userdata.datos.persona.id, valor: data.valor } as Citasmedicainformaciones;
        }
        if (this.lista[indice].guardado == undefined)
          this.infosend.push(dataSendMotivo);
      } else {

        if (estadoanterior == 1 && this.lista[indice].seleccion == 0) {
          this.infosend.splice(indiceItem, 1);
        } else if (estadoanterior == 1 && this.lista[indice].seleccion == 1) {
          this.infosend[indiceItem].detalle = data.detalle;
        } else if (estadoanterior == 0 && this.lista[indice].seleccion == 1) {
          this.infosend[indiceItem].detalle = data.detalle;
        }
      }
    }
    if (this.formulario == 'FORM008' && this.seccion == 7) {
      if (data.seleccion == 1 && data.nombre == 'OCULAR') {
        this.dataInfoGlasgow.ocular = data.detalle;
      } else if (data.seleccion == 0 && data.nombre == 'OCULAR') {
        this.dataInfoGlasgow.ocular = 0;
      } else if (data.seleccion == 1 && data.nombre == 'VERBAL') {
        this.dataInfoGlasgow.verbal = data.detalle;
      } else if (data.seleccion == 0 && data.nombre == 'VERBAL') {
        this.dataInfoGlasgow.ocular = 0;
      } else if (data.seleccion == 1 && data.nombre == 'MOTORA') {
        this.dataInfoGlasgow.motora = data.detalle;
      } else if (data.seleccion == 0 && data.nombre == 'MOTORA') {
        this.dataInfoGlasgow.motora = 0;
      }
      this.sumaGlasgow = Number(this.dataInfoGlasgow.ocular) + Number(this.dataInfoGlasgow.verbal) + Number(this.dataInfoGlasgow.motora);

      this.lista.forEach(element => {
        if (element.nombre == 'TOTAL') {
          element.detalle = this.sumaGlasgow;
        }
      });

    }
  }

  limpiar() {
    this.infosend = [];
    this.lista.forEach(element => {
      element.seleccion = 0;
    });
  }

  buscar() {
    this.motivoFormulario.getAppsPacienteEdicionCie10Citas(this.dataBuscarPaciente.buscar_paciente, this.citaMedica.historiasclinica_id).subscribe((dataPaciente: any) => {
      this.lista = dataPaciente.enfermedadcronicas;
    });
  }

  guardarmotivo() {
    this.tipo_guardado = true;
    if (this.accion == 1) {
      let codigo = this.generateRandomString(12);
      this.infosend.forEach(element => {
        element.codigo = codigo;
      });
    }

    let infoTotal = {} as any;
    if (this.citaMedica.id == null && this.op == 2) {
      infoTotal = { info: { seccion: this.seccion, form: this.formulario, atencionesmedica_id: this.citaAtencionMedica.id }, inserts: this.infosend } as any;
    } else if (this.citaAtencionMedica.id == null && this.op == 1) {
      infoTotal = { info: { seccion: this.seccion, form: this.formulario, citasmedica_id: this.citaMedica.id }, inserts: this.infosend } as any;
    }

    this.ngxService.startLoader('item' + this.formulario + this.seccion);
    this.accionFormulario.saveCitasmedicainformaciones(infoTotal).subscribe((data: any) => {
      if (data.estado) {
        this.infosend = [];
        this.infosendParte = [];
        this.buscarMotivo();
        this.ngxService.stopLoader('item' + this.formulario + this.seccion);
      }
    });
  }

  resetGrupo(data: any) {
    this.infosend = [];
    this.buscarMotivo();
  }

  guardado(data: Boolean) {
    this.tipo_guardado = (Boolean)(!data);
    this.lista.forEach(element => {
      if (this.tipo_guardado == false && element.guardado == 1) {
        element.visualizar = true;
      } else if (this.tipo_guardado == true && element.guardado == 1) {
        element.visualizar = true;
      } else {
        element.visualizar = false;
      }
    });
    if (this.tipo_guardado) {
      const temp = this.temp.filter(function (d) {
        return d.visualizar == true;
      });
      this.lista = temp;
    } else {
      this.lista = [...this.temp];
    }
  }

  generateRandomString(num: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = Math.random().toString(36).substring(0, num);
    return result1;
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  seleccionParte(data: any) {

    this.valorItemRoot = data.numero;
    this.verInfoRoot = data.estado;

    let indice = -1;
    let encontrado: boolean = false;
    let base: number = 0;
    for (let index = 0; index < this.infosendParte.length; index++) {
      const element = this.infosendParte[index];
      if (element.numero == data.numero && data.info && element.data.id == data.info.id) {
        indice = index;
        base = element.base;
        encontrado = true;
        break;
      }
    }

    let infoParte = { numero: this.valorItemRoot, data: data.info, base: 0 } as any;
    if (data.info != undefined && !encontrado && base == 0) {
      this.infosendParte.push(infoParte);
    } else if (data.info != undefined && encontrado && base == 0) {
      this.infosendParte.splice(indice, 1);
    }

    this.infosend = [];
    this.listaMostraInfo = [];
    this.infosendParte.forEach(element => {
      let dataSendMotivo = {} as any;
      if (this.citaMedica.id == null && this.op == 2 && element.base == 0) {
        dataSendMotivo = { motivoscita_id: element.data.id, atencionesmedica_id: this.citaAtencionMedica.id, estado: 1, detalle: element.numero, persona_id: this.userdata.datos.persona.id, valor: null } as Citasmedicainformaciones;
      } else if (this.citaAtencionMedica.id == null && this.op == 1 && element.base == 0) {
        dataSendMotivo = { motivoscita_id: element.data.id, citasmedica_id: this.citaMedica.id, estado: 1, detalle: element.numero, persona_id: this.userdata.datos.persona.id, valor: null } as Citasmedicainformaciones;
      }

      if (element.base == 0)
        this.infosend.push(dataSendMotivo);


      if ((element.base == 1 || element.base == 0) && element.numero == data.numero) {
        this.listaMostraInfo.push(element);
      }
    }, this);



  }

  verInfRoot() {
    this.verInfoRoot = !this.verInfoRoot;
  }

  eliminarlecion(data: any) {
    let infoUpdate = { id: data.data.citasmedicainformacione_id } as any;
    this.accionFormulario.deleteCitasmedicainformaciones(infoUpdate.id).subscribe((update: any) => {
      if (update.estado) {
        this.infosendParte = [];
        this.buscarMotivo();
      }
    });
  }
}
