import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Personas} from './personas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Etnias } from '../../etnias/etnia/etnias.model';
import { Formacionacademicas } from '../../formacionacademicas/formacionacademica/formacionacademicas.model';


@Injectable()
export class PersonasServices {

	constructor(private http: HttpClient) { }

	getedadserver(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'op_edad_obtener.json',data);
	}

	getexisteserver(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'op_edad_si_existe.json',data);
	}

	getDataPersonas():Observable<Personas[]>{
		return this.http.get<Personas[]>(Globals.BASE_URL_API_REST+'personas.json');
	}

	savePersonas(data:Personas){
		return this.http.post(Globals.BASE_URL_API_REST+'personas.json',data);
	}

	saveAllPersonas(data:Personas[]){
		return this.http.post(Globals.BASE_URL_API_REST+'op_add_all.json',data);
	}
	
	deletePersonas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'personas/'+id+'.json');
	}

	updatePersonas(data:Personas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'personas/'+data.id+'.json',data);
	}

	viewPersonas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'personas/'+id+'.json');
	}

	getDataPacienteParroquias(id):Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'op_parroquias_busquedas/'+id+'.json');
    }

    getDataPacienteProvincias():Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'op_provincias_busquedas.json');
    }

    getDataPacienteCantones(id):Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'op_cantones_busquedas/'+id+'.json');
    }

	getDataEtnias():Observable<Etnias[]>{
		return this.http.get<Etnias[]>(Globals.BASE_URL_API_REST+'etnias.json');
	}
	
	getDataFormacionacademicas():Observable<Formacionacademicas[]>{
		return this.http.get<Formacionacademicas[]>(Globals.BASE_URL_API_REST+'formacionacademicas.json');
	}

}