import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-lista-personas-general',
  templateUrl: './lista-personas-general.component.html',
  styleUrls: ['./lista-personas-general.component.scss']
})
export class ListaPersonasGeneralComponent implements OnInit {
  @Output() clickEvent = new EventEmitter();
  rowscliente = [];
  tempcliennte = [];
  busqueEnviar: string = '';

  constructor(private ngxService: NgxUiLoaderService,
    private persona:ElementComponentService) { }

  ngOnInit() {
  }

  buscarEvento(event) {
    const val = event.target.value.toLowerCase();
    this.busqueEnviar = val;
  }

  eviarBusqueda() {
    this.ngxService.startLoader('princialLoder');
    this.persona.viewPersonasEditar(this.busqueEnviar).subscribe((data: any) => {
      this.rowscliente = data.pacientes;
      this.tempcliennte = [...this.rowscliente];
      this.ngxService.stopLoader('princialLoder');
    });
  }

  seleccionarTodo(data:any){
    this.clickEvent.emit(data);
  }

}
