import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Motivoscitas } from '../../motivoscita/motivoscitas.model';
import { MotivoscitasServices } from '../../motivoscita/motivoscitas.services';

@Component({
	selector: 'app-addmotivoscita',
	templateUrl: './addmotivoscita.component.html',
	styleUrls: ['./addmotivoscita.component.scss']
})

export class AddMotivoscitaComponent implements OnInit {
	@Input() form:number=null;
	@Input() seccion:number=null;
	@Input() Orden:number=null;

	public formAddMotivoscita: FormGroup;
	dataTipo = [
		{ id: 1, nombre: 'Selección' },
		{ id: 2, nombre: 'Otro' },
		{ id: 3, nombre: 'Selección con detalle' },
		{ id: 4, nombre: 'Número' },
		{ id: 5, nombre: 'Opciones' },
		{ id: 6, nombre: 'Opciones y texto' },
		{ id: 7, nombre: 'Fecha' },
		{ id: 8, nombre: 'Observaciones' }
	];

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		private servicioMotivoscita: MotivoscitasServices,
		private Noti: NotificationServices,) { }

	ngOnInit() {
		this.formAddMotivoscita = this.fb.group({
			codigo: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(15)])],
			nombre: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])],
			formulario: [this.form, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(15)])],
			seccion: [this.seccion, Validators.compose([Validators.required, CustomValidators.number])],
			orden:[this.Orden,null],
			inicio:[null,null],
			fin:[null,null],
			tipo: [null, Validators.compose([Validators.required, CustomValidators.number])],
			col:[null,Validators.compose([CustomValidators.number])],
			medida:[null,Validators.compose([Validators.minLength(1), Validators.maxLength(20)])],
			estado: [1, null],
		});
	}


	save() {
		let motivoscitaDataModel: Motivoscitas = this.formAddMotivoscita.value;
		this.servicioMotivoscita.saveMotivoscitas(motivoscitaDataModel).subscribe((data: any) => {
			if (data.estado) {
				this.Noti.setNotification(data).then((datas) => {
					if (datas) {
						this.activeModal.close(1);
					}
				});
			} else {
				this.Noti.setNotification(data);
			}
		});
	}
	cerrarModal() {
		this.activeModal.close(2);
	}
}