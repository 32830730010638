import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-f008-seccion3]',
  templateUrl: './f008-seccion3.component.html',
  styleUrls: ['./f008-seccion3.component.scss']
})
export class F008Seccion3Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  tmpLista = [];
  tmps = [];
  constructor() { }

  ngOnInit() {
    this.tmps = this.infoFormulacionSeccion.motivos;
    this.tmpLista = this.tmps.filter(function (el) {
      return el.seleccion == 1;
    });
  }

}
