import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Especialidad_personales } from '../../especialidad_personale/especialidad_personales.model';
import { Especialidad_personalesServices } from '../../especialidad_personale/especialidad_personales.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';
import { Especialidades } from '../../../especialidades/especialidade/especialidades.model';
import { EspecialidadesServices } from '../../../especialidades/especialidade/especialidades.services';

@Component({
	selector: 'app-editespecialidad_personale',
	templateUrl: './editespecialidad_personale.component.html',
	styleUrls: ['./editespecialidad_personale.component.scss']
})

export class EditEspecialidad_personaleComponent implements OnInit {

	@Input() Especialidad_personale:Especialidad_personales;
	public dataPersonas={'cedula':'vacio'} as Personas;
	public personasData:any;
	public dataEspecialidades={'nombre':'vacio'} as Especialidades;
	public especialidadesData:any;

	public formEspecialidad_personalePersona:FormGroup;
	public editPersona:boolean=false;

	public formEspecialidad_personaleEspecialidade:FormGroup;
	public editEspecialidade:boolean=false;

	public formEspecialidad_personaleEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEspecialidad_personale:Especialidad_personalesServices,
		private servicioPersona:PersonasServices,
		private servicioEspecialidade:EspecialidadesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPersona.getDataPersonas().subscribe((data:any)=>{
			this.personasData=data.personas;
			this.dataPersonas=this.personasData.find(obj => obj.id == this.Especialidad_personale.persona_id);
		});
		this.servicioEspecialidade.getDataEspecialidades().subscribe((data:any)=>{
			this.especialidadesData=data.especialidades;
			this.dataEspecialidades=this.especialidadesData.find(obj => obj.id == this.Especialidad_personale.especialidade_id);
		});

		this.formEspecialidad_personalePersona=this.fb.group({
			persona_id:[this.Especialidad_personale.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formEspecialidad_personaleEspecialidade=this.fb.group({
			especialidade_id:[this.Especialidad_personale.especialidade_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	
	editEspecialidad_personalePersonaEnable(){
		this.editPersona=true;
		this.formEspecialidad_personalePersona=this.fb.group({
			persona_id:[this.Especialidad_personale.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEspecialidad_personalePersonaDisable(){
		this.editPersona=false;
	}

	saveEspecialidad_personalePersona(){
		let Especialidad_personaleDataModel:Especialidad_personales=this.formEspecialidad_personalePersona.value;
		this.Especialidad_personale.persona_id=Especialidad_personaleDataModel.persona_id;
		this.servicioEspecialidad_personale.updateEspecialidad_personales(Especialidad_personaleDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPersona=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editEspecialidad_personaleEspecialidadeEnable(){
		this.editEspecialidade=true;
		this.formEspecialidad_personaleEspecialidade=this.fb.group({
			especialidade_id:[this.Especialidad_personale.especialidade_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEspecialidad_personaleEspecialidadeDisable(){
		this.editEspecialidade=false;
	}

	saveEspecialidad_personaleEspecialidade(){
		let Especialidad_personaleDataModel:Especialidad_personales=this.formEspecialidad_personaleEspecialidade.value;
		this.Especialidad_personale.especialidade_id=Especialidad_personaleDataModel.especialidade_id;
		this.servicioEspecialidad_personale.updateEspecialidad_personales(Especialidad_personaleDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEspecialidade=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

