import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Especialidad_personales} from './especialidad_personales.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class Especialidad_personalesServices {

	constructor(private http: HttpClient) { }

	getDataEspecialidad_personales():Observable<Especialidad_personales[]>{
		return this.http.get<Especialidad_personales[]>(Globals.BASE_URL_API_REST+'especialidad_personales.json');
	}

	saveEspecialidad_personales(data:Especialidad_personales){
		return this.http.post(Globals.BASE_URL_API_REST+'especialidad_personales.json',data);
	}

	deleteEspecialidad_personales(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'especialidad_personales/'+id+'.json');
	}

	updateEspecialidad_personales(data:Especialidad_personales){
		 return this.http.patch(Globals.BASE_URL_API_REST+'especialidad_personales/'+data.persona_id+'.json',data);
	}

	viewEspecialidad_personales(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'especialidad_personales/'+id+'.json');
	}

	viewPersonalMedicoSinAsignar(nombres:string){
		return this.http.get(Globals.BASE_URL_API_REST+'op_filtrado_medicos.json?nombres='+nombres);
	}

	viewPersonalMedicoEspecialidades(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'bu_medicos_especialidad/'+id+'.json');
   	}   

	
}