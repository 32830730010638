import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../auth/auth.services';
import { localStorageServices } from '../auth/localStorageServices';

@Component({
  selector: 'app-cerrar-error',
  templateUrl: './cerrar-error.component.html',
  styleUrls: ['./cerrar-error.component.scss']
})
export class CerrarErrorComponent implements OnInit {

  constructor( private router: Router, 
    private route: ActivatedRoute,public authServices:AuthServices,public localdata:localStorageServices,) { }

  ngOnInit() {
  }

  cerrarSession(){
    this.authServices.logout().subscribe( (data:any) => {
      if(data.message.estado){
        this.localdata.removeValueFromLocalStorage();
        this.router.navigate(['inicio/login']);
      }
    });
  }

}
