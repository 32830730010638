import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { Especialidad_personalesServices} from '../especialidad_personale/especialidad_personales.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Especialidad_personales } from '../especialidad_personale/especialidad_personales.model';
import { AddEspecialidad_personaleComponent } from '../modal/add/addespecialidad_personale.component';
import { EditEspecialidad_personaleComponent } from '../modal/edit/editespecialidad_personale.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-especialidad_personalevista',
	templateUrl:'./especialidad_personalevista.component.html',
	styleUrls: ['./especialidad_personalevista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class Especialidad_personalesVistaComponent implements OnInit {
	rows = [];
	temp = [];

	timeout: any;
	public imgUrlProductoProveedor:string;
	@ViewChild('tableEspecialidad_personale', { static: false }) table: any;

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-account-multiple-plus'
	},{
		text: 'Refrescar',
		icon: 'mdi mdi-refresh'
	}]

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refrescar'){
			this.listaEspecialidad_personales();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosEspecialidad_personales:Especialidad_personalesServices,
		private ngxService: NgxUiLoaderService,
		private notificationServices:NotificationServices,
	){}

	ngOnInit(){
		this.listaEspecialidad_personales();
	}

	listaEspecialidad_personales(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosEspecialidad_personales.getDataEspecialidad_personales().subscribe((data:any)=>{
			this.rows=data.especialidadPersonales;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.cedula.toLowerCase().indexOf(val) !== -1 
				   || ( d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1)
				   || ( d.apellido && d.apellido.toLowerCase().indexOf(val) !== -1)
				   || ( d.razonsocial && d.razonsocial.toLowerCase().indexOf(val) !== -1)
				   || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddEspecialidad_personaleComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result){
					this.listaEspecialidad_personales();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataEspecialidad_personale:any){
		let data={'val':dataEspecialidad_personale.estado,'msg':'Eliminar Personas'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosEspecialidad_personales.deleteEspecialidad_personales(dataEspecialidad_personale.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaEspecialidad_personales();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditEspecialidad_personaleComponent, this.config);
		dialogRef.componentInstance.Especialidad_personale = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaEspecialidad_personales();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}
	
	onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	  }
	
	  toggleExpandRow(row, i) {
		this.table.rowDetail.toggleExpandRow(row);
	  }
	
	  onDetailToggle(event) {
		//console.log('Detail Toggled', event);
	  }
}

