import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-resultado-examen',
  templateUrl: './lista-resultado-examen.component.html',
  styleUrls: ['./lista-resultado-examen.component.scss']
})
export class ListaResultadoExamenComponent implements OnInit {
  @Input() data:any;
  constructor() { }

  ngOnInit() {
    console.log(this.data);
    
  }

}
