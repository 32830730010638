import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CitasmedicainformacionesServices } from '../../citasmedicainformacione/citasmedicainformaciones.services';

@Component({
  selector: '[app-tipo1-personalizado-motivo]',
  templateUrl: './tipo1-personalizado-motivo.component.html',
  styleUrls: ['./tipo1-personalizado-motivo.component.scss']
})
export class Tipo1PersonalizadoMotivoComponent implements OnInit {
  @Input() infoMotivo:any;
  @Output() clickEvent = new EventEmitter();
  constructor(private accionFormulario: CitasmedicainformacionesServices,
    private ngxService: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    
  }

  setMotivoUnoSeleccion(data:any){
    if(this.infoMotivo.guardado && this.infoMotivo.guardado==1 && this.infoMotivo.accion!=2){
      this.eliminar();
    }
    this.clickEvent.emit(data);
  }

  eliminar() {
    let infoUpdate = { id: this.infoMotivo.citasmedicainformacione_id } as any;
    this.ngxService.startLoader('item' + this.infoMotivo.formulario + this.infoMotivo.seccion + this.infoMotivo.codigogrupo + this.infoMotivo.id);
    this.accionFormulario.deleteCitasmedicainformaciones(infoUpdate.id).subscribe((update: any) => {
      if (update.estado) {
        this.infoMotivo.seleccion = 0;
        delete this.infoMotivo.guardado;
        this.ngxService.stopLoader('item' + this.infoMotivo.formulario + this.infoMotivo.seccion + this.infoMotivo.codigogrupo + this.infoMotivo.id);
      }
    });
  }

}
