import { Component, OnInit } from '@angular/core';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';
import * as XLSX from 'xlsx';
import { Personas } from '../persona/personas.model';
type AOA = any[][];
import * as moment from 'moment';
import { Parroquias } from '../../../ubicacion/parroquias/parroquia/parroquias.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { PersonasServices } from '../persona/personas.services';

@Component({
  selector: 'app-subir-informacion',
  templateUrl: './subir-informacion.component.html',
  styleUrls: ['./subir-informacion.component.scss']
})
export class SubirInformacionComponent implements OnInit {
  data: AOA;
  parroquia={parroquia_id:0} as any;
  rows=[];
  rowserr=[];
  constructor(private validaciones:customValidatorMondogo,private ngxService: NgxUiLoaderService,private servicioPersona:PersonasServices,private Noti:NotificationServices,) { }

  ngOnInit() {
  }

  onFileChange(evt: any) {
    this.rowserr=[];
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.ngxService.startLoader('princialLoder');
    if (target.files.length !== 1) {
        this.ngxService.stopLoader('princialLoder');
        throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    
    reader.onload = (e: any) => {
      
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary','cellDates':true });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 0 }));
      this.data=this.filtarCedulaVacias(this.data);
      this.data=this.filtarCedulaValida(this.data);
      this.rows=[];
      this.data.forEach((element:any)=> {
        
        const dataPersona = {nombre:'',apellido:''} as Personas;
        let nombre=element.Nombre.split(" ");
        for (let i = 0; i < nombre.length; i++) {
          if(i<2){
            dataPersona.nombre+=nombre[i]+' ';
          }else{
            dataPersona.apellido+=nombre[i]+' ';
          }
        }
        dataPersona.cedula=element.NumID;
        dataPersona.tipo=1;
        dataPersona.prefijo="Sr.";
        dataPersona.sexo=element.Sexo;
        let sexo:string='M';
        if(element.Sexo=='M')
          sexo='M';
        else if(element.Sexo=='F')
          sexo='F';
        dataPersona.sexo=sexo;

        let num:number=1;
        if(element.EdoCivil=='S')
          num=1;
        else if(element.EdoCivil=='C')
          num=3;
        else if(element.EdoCivil=='V')
          num=5;
        else if(element.EdoCivil=='D')
          num=4;
          
        dataPersona.estado_civil=num;
        if(element.Domicilio){
          dataPersona.direccion=element.Domicilio;
        }else{
          dataPersona.direccion='ninguna';
        }

        if(element.Tel){
          let telefono=element.Tel.split(" ");
          for (let j = 0; j < telefono.length; j++) {
            
            if(!isNaN(telefono[j])){
              if(telefono[j].substring(0,2)==='09')
                dataPersona.celular=telefono[j];
              else
                dataPersona.telefono=telefono[j];
            }else{
              if(!dataPersona.celular)
                dataPersona.celular='0000000000';
              if(!dataPersona.telefono)
                dataPersona.telefono='000000000';
              break;
            }
          }
        }
        if(!dataPersona.celular)
          dataPersona.celular='0000000000';
        if(!dataPersona.telefono)
          dataPersona.telefono='000000000';
        dataPersona.nombre=dataPersona.nombre.toUpperCase();
        dataPersona.apellido=dataPersona.apellido.toUpperCase();
        dataPersona.direccion=dataPersona.direccion.toUpperCase();
        dataPersona.fecha_nacimiento=element.BirthDate;
        dataPersona.parroquia_id=this.parroquia.parroquia_id;
        dataPersona.estado=1;
        this.rows.push(dataPersona);
      });
    };
    reader.readAsBinaryString(target.files[0]);
    this.ngxService.stopLoader('princialLoder');
  }

  filtarCedulaVacias(array){
    return array.filter(e=> String(e.NumID).length==10 );
  }

  filtarCedulaValida(array){
    return array.filter(e=> this.validaciones.Cedula(String(e.NumID))===true );
  }

  save(){
    this.ngxService.startLoader('princialLoder');
    this.servicioPersona.saveAllPersonas(this.rows).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas:any)=>{
					if(datas){
            this.rows=[];
            this.rowserr=data.errores;
						this.ngxService.stopLoader('princialLoder');
					}
				});
			}else{
        this.Noti.setNotification(data);
        this.rows=[];
        this.rowserr=data.errores;
        this.ngxService.stopLoader('princialLoder');
			}
		});
  }

  cambiarUbicacion(data:any){
    if(data.parroquia_id!=null)
      this.parroquia=data;
	}

}
