import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { AuthServices } from '../../appauth/auth/auth.services';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { UserServices } from '../../appauth/user-pages/user/users.services';
import { Globals } from '../../global/global.model';
import { ListaImpresorasServices } from '../../global/glogal.services';
import { DataSharingFacturaService } from '../../global/dataService.service';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  dataGrupo= {'name':undefined} as any;
  facturaciones: any[]=[];
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal,
    private router: Router, 
    public authServices:AuthServices,
    public localdata:localStorageServices,
    private servicesFactura:ListaImpresorasServices,
    private DataSharing: DataSharingFacturaService,
    private servicesUser:UserServices) {
      this.dataUser=localdata.getValueFromLocalStorage().datos;
      if(localdata.getValueFromLocalStorage().grupo!=undefined)
        this.dataGrupo=localdata.getValueFromLocalStorage().grupo.group;
    }

  dataUser:any;
  public imgUrlProfile:string;
  public showSearch = false;

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];



  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  ngAfterViewInit() {
    this.buscar();
  }

  cerrarSession(){
    this.authServices.logout().subscribe( (data:any) => {
      if(data.message.estado){
        this.localdata.removeValueFromLocalStorage();
        this.router.navigate(['inicio/login']);
      }
    });
  }

  profile(){
    this.router.navigate(['usuarios/userprofile']);
  }

  imagePhoto(){
    this.router.navigate(['usuarios/photo']);
  }


  home(){
    this.router.navigate(['home']);
  }

  buscar(){
    this.servicesFactura.listasDeFacturasVentas().subscribe((info:any)=>{
      if(info){
        this.facturaciones=info.facturaciones;
      }
    });
  }
  

  ngOnInit() {
    if(this.dataUser.fotousers[0] !== undefined)
      this.imgUrlProfile=Globals.BASE_URL_API_REST+this.dataUser.fotousers[0].dir+this.dataUser.fotousers[0].filename;
      else
        this.imgUrlProfile="assets/images/users/profile.png";
  }

}
