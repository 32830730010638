import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Facturas } from '../../../appfacturas/busquedas/servicios/consultas.model';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import { ConsultasFacturasService } from '../../../appfacturas/busquedas/servicios/consultas.facturas.services';
import { ElementComponentService } from '../servicios/component.servicios';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddStaticItemsComponent } from '../../../appfacturas/busquedas/modal/add-static-items/add-static-items.component';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataItemsGeneral } from '../../../appasclepio/examen-medicos/citaexemanes/citaexemane/cita.data';
import { ListaImpresorasServices } from '../../../../app/global/glogal.services';
import * as moment from 'moment-timezone';
import { AddPersonaClienteInformacionComponent } from '../../../appasclepio/configuracion/personas/modal/add-cliente-informacion/add-cliente-informacion.component';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'app-editar-factura-general',
  templateUrl: './editar-factura-general.component.html',
  styleUrls: ['./editar-factura-general.component.scss']
})
export class EditarFacturaGeneralComponent implements OnInit {
  dataInforFactura = {} as Facturas;
  items = DataItemsGeneral.items;
  @Input() dataPaciente: any;
  @Output() clickEvent = new EventEmitter();
  dataFacturaCliente: any;
  dataInfoFactura: any;
  editarInfoCliente: boolean = false;
  buscarInfoCliente: boolean = false;
  addInfoCliente: boolean = false;
  dataPacienteCambio: any;
  totalAFacturar: number = 0;
  totalDescuentos: number = 0;
  totalGeneral:number =0;
  cantidadExamenes: number = 0;
  ivaExamenes: number = 0;
  sinImpuesto: boolean = false;
  tempall: any;
  tempallNoSave: any;
  verDetalleFactura: boolean = false;

  dataEmpresa: any;
  valoretencioniva: number = 0;
  valoretencionrenta: number = 0;
  dataComrpobante: any;
  rentaimpuestos = [];
  retencionesImprimir = [];
  abono: number = 0;

  formaDePagos = [];
  formaDePagosTodo = [];
  userdata: any;
  ip: string = '127.0.0.1'

  config: any = {
    size: 'lg',
    centered: true,
    resolve: { datasend: null }
  }

  constructor(private factura: ElementComponentService,
    private servicioDecimal: localStorageServices,
    private servicioConsultas: ElementComponentService,
    public dialog: NgbModal,
    public localdata: localStorageServices,
    private notificationServices: NotificationServices,
    private ngxService: NgxUiLoaderService,
    public imprimir: ListaImpresorasServices,
    private serviciosFacturas: ConsultasFacturasService,) { }

  ngOnInit() {
    //console.log(this.dataPaciente.factura);

    this.userdata = this.localdata.getValueFromLocalStorage();
    if (this.dataPaciente != undefined) {
      this.dataFacturaCliente = this.dataPaciente.factura.persona;

      this.factura.viewFacturasEditar(this.dataPaciente.facturacione.factura_id).subscribe((info: any) => {
        this.dataInfoFactura = info;
        let idsSaveDetallesCitas = [0];
        let idsSaveDetallesExamen = [0];
        let idsSaveDetallesItems = [0];
        this.dataInfoFactura.factura.detallefacturas.forEach(element => {
          if (element.citasmedica_id) {
            idsSaveDetallesCitas.push(element.citasmedica_id);
          }
          if (element.citaexemane_id) {
            idsSaveDetallesExamen.push(element.citaexemane_id);
          }
          if (element.itemfijo_id) {
            idsSaveDetallesItems.push(element.itemfijo_id);
          }
        });

        let dataSaveInfoDetalle = { citas: idsSaveDetallesCitas, examen: idsSaveDetallesExamen, item: idsSaveDetallesItems };

        this.factura.MostrarCitasYExamenesDetalleFacturas(dataSaveInfoDetalle).subscribe((infoDetalle: any) => {
          let tempInicio = infoDetalle.info;
          let idPacienteBuscar=-1;
          if(this.dataInfoFactura.factura.admisione!=null && this.dataInfoFactura.factura.admisione!=undefined)
          {
            idPacienteBuscar=this.dataPaciente.factura.admisione.id;
          }
          this.servicioConsultas.MostrarCitasYExamenesFacturasBusquedasEdiciones(idPacienteBuscar).subscribe((data: any) => {
            this.tempallNoSave = data.info;
            this.tempallNoSave.citaexemanes.forEach(element => {
              let existe = false;
              for (let j = 0; j < tempInicio.citaexemanes.length; j++) {
                const elementexamen = tempInicio.citaexemanes[j];
                if (element.id == elementexamen.id) {
                  existe = true;
                  break;
                }
              }
              if (!existe) {
                tempInicio.citaexemanes.push(element);
              }

            });

            this.tempallNoSave.citasmedicas.forEach(element => {
              let existe = false;
              for (let j = 0; j < tempInicio.citasmedicas.length; j++) {
                const elementexamen = tempInicio.citasmedicas[j];
                if (element.id == elementexamen.id) {
                  existe = true;
                  break;
                }
              }
              if (!existe) {
                tempInicio.citasmedicas.push(element);
              }

            });
            this.tempall = tempInicio;
            this.verDetalleFactura = true;
          });
          infoDetalle.info.citasmedicas.forEach(element => {
            this.ponercitamedica(element);
          });
          infoDetalle.info.citaexemanes.forEach(element => {
            this.ponerexamen(element);
          });
          infoDetalle.info.itemfijos.forEach(element => {
            
            let info:any = this.dataInfoFactura.factura.detallefacturas.find(obj => obj.itemfijo_id == element.id);
            element.descuento=info.descuento;
            element.iva=info.iva;
            this.ponerestatico(element);
          });
        });

        this.serviciosFacturas.deleteAllDetallesFacturas();
        //console.log(this.dataInfoFactura);
        this.formaDePagosTodo = this.dataInfoFactura.factura.facturasformapagos;
        this.dataInfoFactura.factura.detallefacturas.forEach(element => {
          element.info = JSON.parse(element.info);
        });
      });
    }
  }

  cancelarFacturacion() {
    this.clickEvent.emit({ tipo: 0 });
  }

  editarCliente() {
    this.editarInfoCliente = true;
    this.buscarInfoCliente = false;
    this.addInfoCliente = false;
    this.dataPacienteCambio = undefined;
    this.dataFacturaCliente = this.dataPaciente.factura.persona;
  }

  buscarCliente() {
    this.editarInfoCliente = false;
    this.addInfoCliente = false;
    this.buscarInfoCliente = true;
  }

  cancelCliente() {
    this.editarInfoCliente = false;
    this.buscarInfoCliente = false;
    this.addInfoCliente = false;
  }

  editarClienteInfo(data: any) {
    if (data && data == 1) {
      this.dataFacturaCliente = data;
    }
  }

  cambioCliente(data: any) {
    this.dataPacienteCambio = data;
    this.dataFacturaCliente = data;
  }

  limpiar() {
    this.dataPacienteCambio = undefined;
  }

  ponercitamedica(data: any) {
    //console.log(data);

    if (data.sinimpuesto == false) {
      this.sinImpuesto = true;
      return;
    }
    let sumaiva: number = 0;
    let sumaivatotal: number = 0;
    let sumaivadescuento: number = 0;
    for (let index = 0; index < data.tipocitamedica.citaimpuestos.length; index++) {
      const element = data.tipocitamedica.citaimpuestos[index];
      if (element.tipoimpusto && [2, 4, 5,10].includes(element.tipoimpusto.numero) && element.tipoimpusto.valorimpuestos.length > 0) {
        sumaiva += this.servicioDecimal.round((data.costo-data.descuento) - ((data.costo-data.descuento) / (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivatotal +=this.servicioDecimal.round((data.valor) - ((data.valor)/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivadescuento +=this.servicioDecimal.round((data.descuento) - ((data.descuento )/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
      } else {
        sumaiva += 0;
        sumaivatotal+= 0;
        sumaivadescuento+= 0;
      }
    }
    let dataSendExamenParametro = { base: 1, 
                                  citasmedica_id: data.id, 
                                  estado: 1, 
                                  cantidad: 1, 
                                  precio: this.servicioDecimal.round(data.costo - sumaivatotal),
                                  descuento:this.servicioDecimal.round(data.descuento-sumaivadescuento),  
                                  iva: sumaiva } as any;
    this.serviciosFacturas.setDetallesFacturaCitas(dataSendExamenParametro, 1);

  }

  ponerexamen(data: any) {
    //console.log(data);
    if (data.sinimpuesto == false) {
      this.sinImpuesto = true;
      return;
    }
    let sumaiva: number = 0;
    let sumaivatotal: number = 0;
    let sumaivadescuento: number = 0;
    for (let index = 0; index < data.examenesmedico.exameninpuestos.length; index++) {
      const element = data.examenesmedico.exameninpuestos[index];
      if (element.tipoimpusto && [2, 4, 5,10].includes(element.tipoimpusto.numero) && element.tipoimpusto.valorimpuestos.length > 0) {
        sumaiva += this.servicioDecimal.round(data.costo - (data.costo / (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivatotal +=this.servicioDecimal.round((data.valor) - ((data.valor)/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
        sumaivadescuento +=this.servicioDecimal.round((data.descuento) - ((data.descuento )/ (1 + (element.tipoimpusto.valorimpuestos[0].valor / 100))));
      } else {
        sumaiva += 0;
        sumaivatotal+= 0;
        sumaivadescuento+= 0;
      }
    }
    let dataSendExamenParametro = { base: 1, 
                                    citaexemane_id: data.id, 
                                    estado: 1, 
                                    cantidad: 1, 
                                    precio: this.servicioDecimal.round(data.costo - sumaivatotal), 
                                    descuento:this.servicioDecimal.round(data.descuento-sumaivadescuento),  
                                    iva: sumaiva } as any;
    this.serviciosFacturas.setDetallesFacturaExamenes(dataSendExamenParametro, 1);
  }

  ponerestatico(data: any) {
   
    this.dataInfoFactura.factura.detallefacturas.forEach(element => {
      if (element.itemfijo_id == data.id) {
        data.cantidad = element.cantidad
        data.valor = element.precio;
        let sumaiva: number = 0;
        for (let indeximp = 0; indeximp < data.itemimpuestos.length; indeximp++) {
          const element = data.itemimpuestos[indeximp];
          if (element.tipoimpusto && [2, 4, 5,10].includes(element.tipoimpusto.numero) && element.tipoimpusto.valorimpuestos.length > 0) {
            sumaiva += data.iva;
          } else {
            sumaiva += 0;
          }
        }
        let dataSendExamenParametro = { base: 1, 
                                        codigo: data.codigo, 
                                        data: data, 
                                        itemfijo_id: data.id, 
                                        estado: 0, 
                                        cantidad: data.cantidad, 
                                        precio: this.servicioDecimal.round(data.valor), 
                                        descuento:this.servicioDecimal.round(data.descuento),  
                                        iva: sumaiva } as any;

        console.log(dataSendExamenParametro);
    
        this.serviciosFacturas.setDetallesFacturaItems(dataSendExamenParametro, 1);
      }
    });


  }


  sumarTotalCambio() {
    console.log('cambio');
    
    this.serviciosFacturas.getFacturaInformacion().then((dataInfo: any) => {
      if (dataInfo) {
        this.totalAFacturar = 0;
        this.totalDescuentos = 0;
        this.ivaExamenes = 0;
        this.cantidadExamenes = 0;
        for (let index = 0; index < dataInfo.detallefacturas.length; index++) {
          const elementdetalle = dataInfo.detallefacturas[index];
          this.totalAFacturar += this.servicioDecimal.round((elementdetalle.precio-elementdetalle.descuento) * elementdetalle.cantidad);
          this.totalDescuentos += this.servicioDecimal.round(elementdetalle.descuento * elementdetalle.cantidad);
          this.ivaExamenes += this.servicioDecimal.round(elementdetalle.iva);
          this.cantidadExamenes += elementdetalle.cantidad;
        }
        this.totalGeneral=this.servicioDecimal.round2Decimales(this.ivaExamenes+this.totalAFacturar);
        this.onChange(this.dataPaciente.factura.locale);
      }
    });

  }

  onChange(data: any) {
    this.dataEmpresa = undefined;
    this.valoretencioniva = 0;
    this.valoretencionrenta = 0;
    setTimeout(() => {
      if (data) {
        this.dataEmpresa = data;
        this.dataComrpobante = undefined;

        if (this.dataEmpresa.tipofacturar == 2) {
          this.dataInforFactura.tipo = 1;
        } else {
          this.dataInforFactura.tipo = 2;
        }
        this.serviciosFacturas.viewTipoComprobante(this.dataEmpresa.comerciale.id, '01', this.dataEmpresa.id).subscribe((info: any) => {
          if (info) {
            this.dataComrpobante = info;
          }
        });

      } else {
        this.dataEmpresa = undefined;
        this.dataComrpobante = undefined;
      }
    }, 500);
  }

  restaImpuesto(data: any) {

    this.rentaimpuestos = [];
    let renta = 0;
    let iva = 0;
    let isd = 0;
    data.forEach(element => {
      if (1 == element.codigo)
        renta += element.valor;
      if (2 == element.codigo)
        iva += element.valor;
      if (3 == element.codigo)
        isd += element.valor;
    });
    this.valoretencionrenta = renta;
    this.valoretencioniva = iva;
    let resntasInser = [];
    this.retencionesImprimir = [];
    data.forEach(element => {
      if (1 == element.codigo) {
        let itemPrinter = { "detalle": element.nombre, "porcentaje": element.porcentaje, "codigop": element.codigop, "codigo": element.codigo, "valor": element.valor } as any;
        let item = { retencioneslocale_id: element.retencioneslocale_id, valor: element.valor, estado: 1 } as any;
        resntasInser.push(item);
        this.retencionesImprimir.push(itemPrinter);
      }
      if (2 == element.codigo) {
        let itemPrinter = { "detalle": element.nombre, "porcentaje": element.porcentaje, "codigop": element.codigop, "codigo": element.codigo, "valor": element.valor } as any;
        let item = { retencioneslocale_id: element.retencioneslocale_id, valor: element.valor, estado: 1 } as any;
        resntasInser.push(item);
        this.retencionesImprimir.push(itemPrinter);
      }
      if (3 == element.codigo) {
        let itemPrinter = { "detalle": element.nombre, "porcentaje": element.porcentaje, "codigop": element.codigop, "codigo": element.codigo, "valor": element.valor } as any;
        let item = { retencioneslocale_id: element.retencioneslocale_id, valor: element.valor, estado: 1 } as any;
        resntasInser.push(item);
        this.retencionesImprimir.push(itemPrinter);
      }
    });
    this.rentaimpuestos = resntasInser;
  }

  actualizarFormaDePagos(data: any) {
    this.formaDePagos = data.formaPagos;
    this.abono = 0;
    for (let j = 0; j < this.formaDePagos.length; j++) {
      const element = this.formaDePagos[j];
      this.abono += element.monto;
    }
  }

  addStaticItems() {
    this.verDetalleFactura = false;
    let dialogRef = this.dialog.open(AddStaticItemsComponent, this.config);
    dialogRef.result.then(
      result => {
        if (result) {
          this.verDetalleFactura = true;
        }
      },
      reason => {
        this.verDetalleFactura = true;
      }
    );
  }


  saveFactura() {

    let paciente: string = '';
    if (this.dataPaciente.factura.admisione.tipo = 1) {
      paciente = this.dataPaciente.factura.admisione.nombre + " " + this.dataPaciente.factura.admisione.apellido;
    } else {
      paciente = this.dataPaciente.factura.admisione.razonsocial
    }

    let correo: string = '';
    if (this.dataFacturaCliente.email != null && this.dataFacturaCliente.facturaremail == null) {
      correo = this.dataFacturaCliente.email;
    } if (this.dataFacturaCliente.email == null && this.dataFacturaCliente.facturaremail != null) {
      correo = this.dataFacturaCliente.facturaremail;
    } if (this.dataFacturaCliente.email != null && this.dataFacturaCliente.facturaremail != null) {
      correo = this.dataFacturaCliente.facturaremail;
    }

    if (correo.length == 0) {
      let datasms = { 'val': '', 'msg': { tipo: 'warning', tipomsg: 'Ingrese una corrreo para el envio de la factura al cliente.' } };
      this.notificationServices.setNotification(datasms);
      return;
    }


    let data = { 'val': 'de <span style="font-size: xxx-large;font-weight: bold;"> $' + ((this.totalAFacturar + this.ivaExamenes) - (this.valoretencionrenta + this.valoretencioniva)) + "</span> que realizo al paciente, " + this.dataPaciente.factura.admisione.cedula + " " + paciente + ' <b style="color: dodgerblue;">CON RUC DE: ' + this.dataEmpresa.comerciale.numeroruc + '</b> <b style="color: steelblue;">' + this.dataEmpresa.comerciale.razonsocial + '</b> <b style="color: dodgerblue;">' + this.dataEmpresa.numeroestablecimiento + '</b> ' + this.dataEmpresa.tipoestablecimiento, 'msg': 'Facturar los detalles' };
    this.notificationServices.msgConfir(data).then((datas) => {
      if (datas) {
        this.ngxService.startLoader('princialLoder');
        this.dataInforFactura.persona_id = this.dataFacturaCliente.id;
        this.dataInforFactura.recaudador = this.userdata.datos.persona.id;
        this.dataInforFactura.paciente = this.dataPaciente.factura.admisione.id;
        this.dataInforFactura.facturasformapagos = this.formaDePagos;
        this.dataInforFactura.estado = 1;
        this.dataInforFactura.locale_id = this.dataEmpresa.id;

        this.serviciosFacturas.getFacturaInformacion().then((dataInfo: any) => {
          if (dataInfo) {
            this.totalAFacturar = 0;
            this.totalDescuentos = 0;
            this.ivaExamenes = 0;
            this.cantidadExamenes = dataInfo.detallefacturas.length;
            let impuestoGeneral = [];
            let formaPagoGeneral = [];
            for (let y = 0; y < this.formaDePagos.length; y++) {
              const element = this.formaDePagos[y];
              let tmpFormaPago = { formaPago: element.formaPago, total: element.monto, plazo: 0, unidadTiempo: 'DIAS' } as any
              if (formaPagoGeneral.length == 0) {
                formaPagoGeneral.push(tmpFormaPago);
              } else {
                let indexfp: number = 0;
                let existfp: boolean = false;
                for (let z = 0; z < formaPagoGeneral.length; z++) {
                  const elementfptmp = formaPagoGeneral[z];

                  if (elementfptmp.formaPago == tmpFormaPago.formaPago) {
                    indexfp = z;
                    existfp = true;
                    break;
                  }
                }
                if (!existfp) {
                  formaPagoGeneral.push(tmpFormaPago);

                } else {

                  formaPagoGeneral[indexfp].total += element.monto;
                  formaPagoGeneral[indexfp].total = this.servicioDecimal.round(formaPagoGeneral[indexfp].total);
                }
              }

            }
            let facturaImprimir = [];
            for (let index = 0; index < dataInfo.detallefacturas.length; index++) {
              let itemFactura = { "detalle": "", "cantidad": 0, "precio": 0, "total": 0 } as any;
              const elementdetalle = dataInfo.detallefacturas[index];
              this.totalAFacturar += this.servicioDecimal.round((elementdetalle.precio-elementdetalle.descuento) * elementdetalle.cantidad);
              this.totalDescuentos += this.servicioDecimal.round(elementdetalle.descuento * elementdetalle.cantidad);
              
              this.ivaExamenes += this.servicioDecimal.round(elementdetalle.iva);

              itemFactura.cantidad = elementdetalle.cantidad;
              itemFactura.precio = elementdetalle.precio;

              if (elementdetalle.citasmedica_id != undefined) {
                elementdetalle.codigo = 'ESP-' + elementdetalle.data.horario.especialidade.id;
                let info = {} as any;
                info.detalle = 'CITA MÉDICA/' + elementdetalle.data.horario.especialidade.nombre;
                info.nombre = elementdetalle.data.horario.especialidade.nombre;

                let impuestoDetalle = [];
                for (let j = 0; j < elementdetalle.data.tipocitamedica.citaimpuestos.length; j++) {
                  let impTemp = {} as any;
                  const element = elementdetalle.data.tipocitamedica.citaimpuestos[j];

                  impTemp.codigo = Number(element.tipoimpusto.listaimpuesto.codigo);
                  impTemp.codigoPorcentaje = element.tipoimpusto.numero;
                  impTemp.tarifa = element.tipoimpusto.valor;
                  impTemp.baseImponible = this.servicioDecimal.round(elementdetalle.precio);
                  impTemp.valor = this.servicioDecimal.round(elementdetalle.iva);
                  impuestoDetalle.push(impTemp);
                  if (impuestoGeneral.length == 0) {
                    let impTempGeneral = {} as any;
                    impTempGeneral.codigo = impTemp.codigo;
                    impTempGeneral.codigoPorcentaje = impTemp.codigoPorcentaje;
                    impTempGeneral.baseImponible = this.servicioDecimal.round(impTemp.baseImponible);
                    impTempGeneral.valor = this.servicioDecimal.round(impTemp.valor);
                    impTempGeneral.valor = this.servicioDecimal.round(impTempGeneral.valor);
                    impuestoGeneral.push(impTempGeneral);


                  } else {
                    let indice = 0;
                    let encontrado: boolean = false;
                    for (let z = 0; z < impuestoGeneral.length; z++) {
                      const element = impuestoGeneral[z];
                      if (element.codigo == impTemp.codigo && element.codigoPorcentaje == impTemp.codigoPorcentaje) {
                        encontrado = true;
                        indice = z;
                        break;
                      }
                    }
                    if (!encontrado) {
                      let impTempGeneral = {} as any;
                      impTempGeneral.codigo = impTemp.codigo;
                      impTempGeneral.codigoPorcentaje = impTemp.codigoPorcentaje;
                      impTempGeneral.baseImponible = this.servicioDecimal.round(impTemp.baseImponible);
                      impTempGeneral.valor = this.servicioDecimal.round(impTemp.valor);
                      impTempGeneral.valor = this.servicioDecimal.round(impTempGeneral.valor);
                      impuestoGeneral.push(impTempGeneral);

                    } else {
                      impuestoGeneral[indice].baseImponible += this.servicioDecimal.round(impTemp.baseImponible);
                      impuestoGeneral[indice].valor += this.servicioDecimal.round(impTemp.valor);
                      impuestoGeneral[indice].valor = this.servicioDecimal.round(impuestoGeneral[indice].valor);
                    }
                  }
                }
                itemFactura.detalle = info.nombre;


                info.impuestos = impuestoDetalle;
                elementdetalle.info = info;
              }
              else if (elementdetalle.citaexemane_id != undefined) {
                let info = {} as any;
                if (elementdetalle.data.examenesmedico.especialidade != undefined) {
                  info.detalle = 'EXÁMEN/' + elementdetalle.data.examenesmedico.especialidade.nombre + '/' + elementdetalle.data.examenesmedico.nombre;
                } else if (elementdetalle.data.examenesmedico.especialidade == undefined) {
                  info.detalle = 'EXÁMEN/' + this.items.nombre + '/' + elementdetalle.data.examenesmedico.nombre;
                }
                info.nombre = elementdetalle.data.examenesmedico.nombre;

                let impuestoDetalle = [];
                for (let j = 0; j < elementdetalle.data.examenesmedico.exameninpuestos.length; j++) {
                  let impTemp = {} as any;
                  const element = elementdetalle.data.examenesmedico.exameninpuestos[j];
                  impTemp.codigo = Number(element.tipoimpusto.listaimpuesto.codigo);
                  impTemp.codigoPorcentaje = element.tipoimpusto.numero;
                  impTemp.tarifa = element.tipoimpusto.valor;
                  impTemp.baseImponible = this.servicioDecimal.round(elementdetalle.precio);
                  impTemp.valor = this.servicioDecimal.round(elementdetalle.iva);
                  impuestoDetalle.push(impTemp);
                  if (impuestoGeneral.length == 0) {
                    let impTempGeneral = {} as any;
                    impTempGeneral.codigo = impTemp.codigo;
                    impTempGeneral.codigoPorcentaje = impTemp.codigoPorcentaje;
                    impTempGeneral.baseImponible = this.servicioDecimal.round(impTemp.baseImponible);
                    impTempGeneral.valor = this.servicioDecimal.round(impTemp.valor);
                    impTempGeneral.valor = this.servicioDecimal.round(impTempGeneral.valor);
                    impuestoGeneral.push(impTempGeneral);

                  } else {
                    let indice = 0;
                    let encontrado: boolean = false;
                    for (let z = 0; z < impuestoGeneral.length; z++) {
                      const element = impuestoGeneral[z];
                      if (element.codigo == impTemp.codigo && element.codigoPorcentaje == impTemp.codigoPorcentaje) {
                        encontrado = true;
                        indice = z;
                        break;
                      }
                    }
                    if (!encontrado) {
                      let impTempGeneral = {} as any;
                      impTempGeneral.codigo = impTemp.codigo;
                      impTempGeneral.codigoPorcentaje = impTemp.codigoPorcentaje;
                      impTempGeneral.baseImponible = this.servicioDecimal.round(impTemp.baseImponible);
                      impTempGeneral.valor = this.servicioDecimal.round(impTemp.valor);
                      impTempGeneral.valor = this.servicioDecimal.round(impTempGeneral.valor);
                      impuestoGeneral.push(impTempGeneral);

                    } else {
                      impuestoGeneral[indice].baseImponible += this.servicioDecimal.round(impTemp.baseImponible);
                      impuestoGeneral[indice].valor += this.servicioDecimal.round(impTemp.valor);
                      impuestoGeneral[indice].valor = this.servicioDecimal.round(impuestoGeneral[indice].valor);

                    }
                  }
                }
                itemFactura.detalle = info.nombre;

                info.impuestos = impuestoDetalle;
                elementdetalle.info = info;
                elementdetalle.codigo = 'EXA-' + elementdetalle.data.examenesmedico.id;

              } else if (elementdetalle.itemfijo_id != undefined) {

                let info = {} as any;
                info.detalle = elementdetalle.data.detalle;
                info.nombre = elementdetalle.data.detalle;
                let impuestoDetalle = [];
                for (let j = 0; j < elementdetalle.data.itemimpuestos.length; j++) {
                  let impTemp = {} as any;
                  const element = elementdetalle.data.itemimpuestos[j];
                  impTemp.codigo = Number(element.tipoimpusto.listaimpuesto.codigo);
                  impTemp.codigoPorcentaje = element.tipoimpusto.numero;
                  impTemp.tarifa = element.tipoimpusto.valor;
                  impTemp.baseImponible = this.servicioDecimal.round(elementdetalle.precio * elementdetalle.cantidad);
                  impTemp.valor = this.servicioDecimal.round(elementdetalle.iva);
                  impuestoDetalle.push(impTemp);
                  if (impuestoGeneral.length == 0) {
                    let impTempGeneral = {} as any;
                    impTempGeneral.codigo = impTemp.codigo;
                    impTempGeneral.codigoPorcentaje = impTemp.codigoPorcentaje;
                    impTempGeneral.baseImponible = this.servicioDecimal.round(impTemp.baseImponible);
                    impTempGeneral.valor = this.servicioDecimal.round(impTemp.valor);
                    impTempGeneral.valor = this.servicioDecimal.round(impTempGeneral.valor);
                    impuestoGeneral.push(impTempGeneral);

                  } else {
                    let indice = 0;
                    let encontrado: boolean = false;
                    for (let z = 0; z < impuestoGeneral.length; z++) {
                      const element = impuestoGeneral[z];
                      if (element.codigo == impTemp.codigo && element.codigoPorcentaje == impTemp.codigoPorcentaje) {
                        encontrado = true;
                        indice = z;
                        break;
                      }
                    }
                    if (!encontrado) {
                      let impTempGeneral = {} as any;
                      impTempGeneral.codigo = impTemp.codigo;
                      impTempGeneral.codigoPorcentaje = impTemp.codigoPorcentaje;
                      impTempGeneral.baseImponible = this.servicioDecimal.round(impTemp.baseImponible);
                      impTempGeneral.valor = this.servicioDecimal.round(impTemp.valor);
                      impTempGeneral.valor = this.servicioDecimal.round(impTempGeneral.valor);
                      impuestoGeneral.push(impTempGeneral);

                    } else {
                      impuestoGeneral[indice].baseImponible += this.servicioDecimal.round(impTemp.baseImponible);
                      impuestoGeneral[indice].valor += this.servicioDecimal.round(impTemp.valor);
                      impuestoGeneral[indice].valor = this.servicioDecimal.round(impuestoGeneral[indice].valor);

                    }
                  }
                }
                itemFactura.detalle = info.nombre;


                info.impuestos = impuestoDetalle;
                elementdetalle.info = info;

              }
              itemFactura.total = this.servicioDecimal.round(itemFactura.cantidad * itemFactura.precio);

              facturaImprimir.push(itemFactura);

            }
            let dataFactura = { impuesto: impuestoGeneral, formaPago: formaPagoGeneral } as any;
            this.dataInforFactura.data = dataFactura;
            this.dataInforFactura.iva = this.servicioDecimal.round(this.ivaExamenes,2);
            this.dataInforFactura.subtotal = this.servicioDecimal.round(this.totalAFacturar,2);
            this.dataInforFactura.descuento = this.servicioDecimal.round(this.totalDescuentos,2);
            this.dataInforFactura.total = this.servicioDecimal.round(this.ivaExamenes + this.totalAFacturar,2);
            this.dataInforFactura.detallefacturas = dataInfo.detallefacturas;
            this.dataInforFactura.valoretencionrenta = this.valoretencionrenta;
            this.dataInforFactura.valoretencioniva = this.valoretencioniva;
            this.dataInforFactura.retencionfacturas = this.rentaimpuestos;
            this.dataInforFactura.id = this.dataPaciente.factura.id;

            let sumIva: number = 0;
            let sumIva14: number = 0;
						let sumIva15: number = 0;
						let sumIva5: number = 0;
						let sumIva13: number = 0;

            let sumIvaSubtotal: number = 0;
            let sumIvaSubtotal14: number = 0;
						let sumIvaSubtotal15: number = 0;
						let sumIvaSubtotal5: number = 0;
						let sumIvaSubtotal13: number = 0;

            let sumIvaCero: number = 0;
            let sumIvaCeroSubtotal: number = 0;
            let sumIvaNoObjeto: number = 0;
            let sumIvaNoObjetoSubtotal: number = 0;
            let sumIvaExento: number = 0;
            let sumIvaExentoSubtotal: number = 0;
            let ice: number = 0;
            let iceSubtotal: number = 0;
            let inbpnr: number = 0;
            let inbpnrSubtotal: number = 0;

            for (let x = 0; x < dataInfo.detallefacturas.length; x++) {
              const element = dataInfo.detallefacturas[x].info.impuestos;
              for (let y = 0; y < element.length; y++) {
                const elementva = element[y];
                if (elementva.codigo == 2) {
                  if (elementva.codigo == 2 && elementva.codigoPorcentaje == 2) {
                    sumIva += elementva.valor;
                    sumIvaSubtotal += elementva.baseImponible;
                  } else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 3) {
										sumIva14 += elementva.valor;
										sumIvaSubtotal14 += elementva.baseImponible;
									} else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 4) {
										sumIva15 += elementva.valor;
										sumIvaSubtotal15 += elementva.baseImponible;
									} else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 5) {
										sumIva5 += elementva.valor;
										sumIvaSubtotal5 += elementva.baseImponible;
									} else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 10) {
										sumIva13 += elementva.valor;
										sumIvaSubtotal13 += elementva.baseImponible;
									} else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 0) {
                    sumIvaCero += elementva.valor;
                    sumIvaCeroSubtotal += elementva.baseImponible;
                  } else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 6) {
                    sumIvaNoObjeto += elementva.valor;
                    sumIvaNoObjetoSubtotal += elementva.baseImponible;
                  } else if (elementva.codigo == 2 && elementva.codigoPorcentaje == 7) {
                    sumIvaExento += elementva.valor;
                    sumIvaExentoSubtotal += elementva.baseImponible;
                  }
                } else if (elementva.codigo == 3) {
                  ice += elementva.valor;
                  iceSubtotal += elementva.baseImponible;
                } else if (elementva.codigo == 5) {
                  inbpnr += elementva.valor;
                  inbpnrSubtotal += elementva.baseImponible;
                }
              }
            }
            let sumaImpuesto = [
              { "nombre": "Subtotal 12", "valor": sumIvaSubtotal },
							{ "nombre": "Subtotal 14", "valor": sumIvaSubtotal14 },
							{ "nombre": "Subtotal 15", "valor": sumIvaSubtotal15 },
							{ "nombre": "Subtotal 5", "valor": sumIvaSubtotal5 },
							{ "nombre": "Subtotal 13", "valor": sumIvaSubtotal13 }, 
              { "nombre": "Subtotal 0", "valor": sumIvaCeroSubtotal }, 
              { "nombre": "Subtotal no obj iva", "valor": sumIvaNoObjetoSubtotal }, 
              { "nombre": "Subtotal sin impu.", "valor": sumIvaExentoSubtotal }, 
              { "nombre": "Subtotal", "valor": this.dataInforFactura.subtotal }, 
              { "nombre": "IVA 12", "valor": sumIva },
							{ "nombre": "IVA 14", "valor": sumIva14 },
							{ "nombre": "IVA 15", "valor": sumIva15 },
							{ "nombre": "IVA 5", "valor": sumIva5 },
							{ "nombre": "IVA 13", "valor": sumIva13 }, 
              { "nombre": "Total", "valor": this.dataInforFactura.total }]
            
            //console.log(this.dataInforFactura);

            this.serviciosFacturas.upadteFacturasEdicion(this.dataInforFactura).subscribe((datas: any) => {
              if (datas.estado) {

                let dataFactura = { 'id': this.dataInfoFactura.facturacione.id, 'estado': 1, 'eviomail': 0 } as any;
                this.serviciosFacturas.upadteFacturaciones(dataFactura).subscribe((dataFac: any) => {

                });

                let clienteFactura: string = '';
                if (this.dataFacturaCliente.tipo == 1) {
                  clienteFactura = this.dataFacturaCliente.nombre + " " + this.dataFacturaCliente.apellido;
                } else {
                  clienteFactura = this.dataFacturaCliente.razonsocial
                }
                this.imprimir.viewAdministradoresImpresoras(this.userdata.grupo).subscribe((datadmin: any) => {
                  if (datadmin.impresora != undefined) {
                    this.ip = datadmin.impresora.ip;
                    let dataPrinterFactura = {
                      data: {
                        "comercial": { "razonsocial": this.dataEmpresa.comerciale.razonsocial, "direccion": this.dataEmpresa.direccioncompleta, "ruc": this.dataEmpresa.comerciale.numeroruc, "obligado": this.dataEmpresa.comerciale.obligado },
                        "comprobante": { "claveacceso": this.dataInfoFactura.facturacione.claveacceso, "fecha_emicion": moment(this.dataInfoFactura.facturacione.created).locale('es').format('LL') },
                        "cliente": { "cedula": this.dataFacturaCliente.cedula, "tipo": this.dataFacturaCliente.tipo, "razonsocial": clienteFactura },
                        "detalles": facturaImprimir,
                        "valores": sumaImpuesto,
                        "pagos": this.formaDePagosTodo,
                        "retenciones": this.retencionesImprimir,
                        "tesorero": {
                          "identificador": this.userdata.datos.persona.cedula,
                          "nombre": this.userdata.datos.persona.nombre,
                          "apellido": this.userdata.datos.persona.apellido
                        }
                      },
                      ip: this.ip
                    };
                    this.imprimir.printerFactura(dataPrinterFactura).subscribe((resultImpimir: any) => {
                      if (resultImpimir) {
                        this.notificationServices.setNotification(resultImpimir);
                      }
                    });

                    this.verDetalleFactura = false;
                    this.formaDePagos = [];
                    this.formaDePagosTodo = [];
                    this.ivaExamenes = 0;
                    this.totalAFacturar = 0;
                    this.abono = 0;
                    this.dataComrpobante = undefined;
                    this.valoretencioniva = 0;
                    this.valoretencionrenta = 0;
                    this.ngxService.stopLoader('princialLoder');
                    this.cancelarFacturacion();
                  } else {
                    this.verDetalleFactura = false;
                    this.formaDePagos = [];
                    this.formaDePagosTodo = [];
                    this.ivaExamenes = 0;
                    this.totalAFacturar = 0;
                    this.abono = 0;
                    this.dataComrpobante = undefined;
                    this.valoretencioniva = 0;
                    this.valoretencionrenta = 0;
                    this.ngxService.stopLoader('princialLoder');
                    this.cancelarFacturacion();
                  }
                });


              } else {
                this.notificationServices.setNotification(datas);
              }
            });

          }
        });
      }
    })
  }


  addCliente() {
    this.editarInfoCliente = false;
    this.buscarInfoCliente = false;
    this.addInfoCliente = true;
    this.dataPacienteCambio = undefined;
    let dialogRef = this.dialog.open(AddPersonaClienteInformacionComponent, this.config);
    dialogRef.result.then(
      result => {
        if (result.datos != undefined) {
          this.dataFacturaCliente = result.datos;
          this.dataPacienteCambio = result.datos;
        } else {
          this.dataFacturaCliente = this.dataPaciente.factura.persona;
          this.dataPacienteCambio = undefined;
        }
      },
      reason => {
        this.dataFacturaCliente = this.dataPaciente.factura.persona;
        this.dataPacienteCambio = undefined;
      }
    );
  }

}
