import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Opcionesmotivos } from '../../opcionesmotivo/opcionesmotivos.model';
import { OpcionesmotivosServices } from '../../opcionesmotivo/opcionesmotivos.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Motivoscitas } from '../../../motivoscitas/motivoscita/motivoscitas.model';
import { MotivoscitasServices } from '../../../motivoscitas/motivoscita/motivoscitas.services';

@Component({
	selector: 'app-editopcionesmotivo',
	templateUrl: './editopcionesmotivo.component.html',
	styleUrls: ['./editopcionesmotivo.component.scss']
})

export class EditOpcionesmotivoComponent implements OnInit {

	@Input() Opcionesmotivo:Opcionesmotivos;
	public dataMotivoscitas={'codigo':'vacio'} as Motivoscitas;
	public motivoscitasData:any;

	public formOpcionesmotivoOpciones:FormGroup;
	public editOpciones:boolean=false;

	public formOpcionesmotivoMotivoscita:FormGroup;
	public editMotivoscita:boolean=false;

	public formOpcionesmotivoEstado:FormGroup;
	public editEstado:boolean=false;

	public formOpcionesmotivoValor:FormGroup;
	public editValor:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioOpcionesmotivo:OpcionesmotivosServices,
		private servicioMotivoscita:MotivoscitasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMotivoscita.getDataMotivoscitas().subscribe((data:any)=>{
			this.motivoscitasData=data.motivoscitas;
			this.dataMotivoscitas=this.motivoscitasData.find(obj => obj.id == this.Opcionesmotivo.motivoscita_id);
		});

		this.formOpcionesmotivoOpciones=this.fb.group({
			opciones:[this.Opcionesmotivo.opciones,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});

		this.formOpcionesmotivoMotivoscita=this.fb.group({
			motivoscita_id:[this.Opcionesmotivo.motivoscita_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formOpcionesmotivoValor=this.fb.group({
			valor:[this.Opcionesmotivo.valor,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});

	}
	

	editOpcionesmotivoOpcionesEnable(){
		this.editOpciones=true;
		this.formOpcionesmotivoOpciones=this.fb.group({
			opciones:[this.Opcionesmotivo.opciones,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});
	}

	editOpcionesmotivoOpcionesDisable(){
		this.editOpciones=false;
	}

	saveOpcionesmotivoOpciones(){
		let OpcionesmotivoDataModel:Opcionesmotivos=this.formOpcionesmotivoOpciones.value;
		OpcionesmotivoDataModel.id=this.Opcionesmotivo.id;
		this.Opcionesmotivo.opciones=OpcionesmotivoDataModel.opciones;
		this.servicioOpcionesmotivo.updateOpcionesmotivos(OpcionesmotivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editOpciones=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editOpcionesmotivoMotivoscitaEnable(){
		this.editMotivoscita=true;
		this.formOpcionesmotivoMotivoscita=this.fb.group({
			motivoscita_id:[this.Opcionesmotivo.motivoscita_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editOpcionesmotivoMotivoscitaDisable(){
		this.editMotivoscita=false;
	}

	saveOpcionesmotivoMotivoscita(){
		let OpcionesmotivoDataModel:Opcionesmotivos=this.formOpcionesmotivoMotivoscita.value;
		OpcionesmotivoDataModel.id=this.Opcionesmotivo.id;
		this.Opcionesmotivo.motivoscita_id=OpcionesmotivoDataModel.motivoscita_id;
		this.servicioOpcionesmotivo.updateOpcionesmotivos(OpcionesmotivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMotivoscita=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editOpcionesmotivoValorEnable(){
		this.editValor=true;
		this.formOpcionesmotivoValor=this.fb.group({
			valor:[this.Opcionesmotivo.valor,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});
	}

	editOpcionesmotivoValorDisable(){
		this.editValor=false;
	}

	saveOpcionesmotivoValor(){
		let OpcionesmotivoDataModel:Opcionesmotivos=this.formOpcionesmotivoValor.value;
		OpcionesmotivoDataModel.id=this.Opcionesmotivo.id;
		this.Opcionesmotivo.valor=OpcionesmotivoDataModel.valor;
		this.servicioOpcionesmotivo.updateOpcionesmotivos(OpcionesmotivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editValor=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

