import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Cantones } from '../../cantone/cantones.model';
import { CantonesServices } from '../../cantone/cantones.services';
import { Provincias } from '../../../provincias/provincia/provincias.model';
import { ProvinciasServices } from '../../../provincias/provincia/provincias.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'app-addcantone',
	templateUrl: './addcantone.component.html',
	styleUrls: ['./addcantone.component.scss']
})

export class AddCantoneComponent implements OnInit {
	@Input() provincia_id:number;
	public dataProvincias:any;
	public formAddCantone: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioCantone:CantonesServices,
		private servicioProvincia:ProvinciasServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioProvincia.getDataProvincias().subscribe((data:any)=>{
			this.dataProvincias=data.provincias;
		});
		this.formAddCantone = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
			provincia_id:[this.provincia_id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let cantoneDataModel:Cantones=this.formAddCantone.value;
		this.ngxService.startLoader('princialLoder');
		this.servicioCantone.saveCantones(cantoneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('princialLoder');
						this.activeModal.close(data);
					}
				});
			}else{
				this.ngxService.stopLoader('princialLoder');
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(0);
	}
}