import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPagoVentaElementComponent } from '../modal/add-pago-venta/add-pago-venta.component';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-component-select-formapago',
  templateUrl: './component-select-formapago.component.html',
  styleUrls: ['./component-select-formapago.component.scss']
})
export class SelectFormapagoComponentComponent implements OnInit {
  @Input() total:number;
  @Input() abonoData:number;
  dataPago:any;
  @Output() newItemEvent = new EventEmitter<string>();
  rows=[];
  config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
  }
  constructor(private servicioventas:ElementComponentService, 
              public dialog: NgbModal,
              private router: Router,) { }

  ngOnInit() {
      this.servicioventas.getDataConfirmarVentaFormaPago().subscribe((data:any)=>{
        this.rows=(data.formapagos);
      });
  }

  seleccionar(data:any,info:any){
    let dialogRef = this.dialog.open(AddPagoVentaElementComponent, this.config);
    dialogRef.componentInstance.formaPago = data;
    dialogRef.componentInstance.institucion = info;
    dialogRef.componentInstance.monoTotal = this.total;
    dialogRef.componentInstance.abonoMonto = this.abonoData;
    dialogRef.result.then(
      result=>{
        if(result){
          this.newItemEvent.emit(result);
        }
      },
      reason=>{
        console.log(reason);
      }
    );
  }

  onChange(data:any){
    if(data){
      this.dataPago=data;
    }else{
      this.dataPago=undefined;
    }
    
  }

}
