import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEnfermeriaComponent } from './edit/editenfermeria.component';
import { AddEnfermeriaComponent } from './add/addenfermeria.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalPersonaModule } from '../../personas/modal/modalpersona.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		ModalPersonaModule,
		NgSelectModule
	],
	declarations:[EditEnfermeriaComponent,AddEnfermeriaComponent],
	entryComponents:[EditEnfermeriaComponent,AddEnfermeriaComponent],
})
export class ModalEnfermeriaModule { }
