import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-grafica-resumen',
  templateUrl: './grafica-resumen.component.html',
  styleUrls: ['./grafica-resumen.component.scss']
})
export class GraficaResumenComponent implements OnInit {
  @Input() info:any;
  
  totalefectivo: number = 0;
  rowsgrafica=[];

  public doughnutChartLabels: string[] = [];
  public doughnutChartOptions: any = {
    borderWidth: 2,
    maintainAspectRatio: false
  };
  public doughnutChartData: number[] = [];
  public doughnutChartColor = [];
  public doughnutChartType = 'doughnut';
  public doughnutChartLegend = false;

  constructor() { }

  ngOnInit() {
    console.log(this.info);
    this.listaGrafico();
  }

  listaGrafico() {
    this.doughnutChartColor = [];
    this.doughnutChartLabels = [];
    this.doughnutChartData = [];
    this.totalefectivo = 0;
    
      let id: string[] = [];
      let values: number[] = [];
      let institucion: any[] = [];
      for (let i = 0; i < this.info.length; i++) {
        const element = this.info[i];
      
        if (element.formaPago.codigo == '01') {
          this.totalefectivo += element.monto;
        }

        if (element.formaPago.codigo == '-11') {
          this.totalefectivo -= element.monto;
        }
        
        if (element.monto > 0) {
          id.push(element.formaPago.nombre);
          values.push(element.monto);
          if(element.institucion!=undefined)
            institucion.push(element.institucion);
          else{
            institucion.push({});
          }
          this.doughnutChartColor.push("#" + ((1 << 24) * Math.random() | 0).toString(16));
        }

      };
      this.doughnutChartLabels = id;
      this.doughnutChartData = values;
      this.rowsgrafica = [];
      for (let index = 0; index < this.doughnutChartColor.length; index++) {
        const elementuno = this.doughnutChartLabels[index];
        const elementdos = this.doughnutChartData[index];
        const elementtres = this.doughnutChartColor[index];
        let dataInfo = { 'label': elementuno, 'data': elementdos, 'color': elementtres,institucion:institucion[index]};
        this.rowsgrafica.push(dataInfo);
      }

      console.log(this.rowsgrafica);
      


  }


}
