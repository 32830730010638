import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProvinciasRoutes } from './provincias.routing';
import { ProvinciasVistaComponent } from './vistas/provinciavista.component';
import { ModalProvinciaModule } from './modal/modalprovincia.module';
import { ProvinciasServices } from './provincia/provincias.services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(ProvinciasRoutes),
		NgxDatatableModule,
		ModalProvinciaModule,
		NgbModule
	],
	providers:[
		ProvinciasServices
	],
	declarations:[
		ProvinciasVistaComponent,
	],
})

export class ProvinciasModule {}
