import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Globals } from '../../../app/global/global.model';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { ConfiguracionesAdministradorService } from '../servicios/configuraciones.services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-configurar-administrador',
  templateUrl: './configurar.component.html',
  styleUrls: ['./configurar.component.scss']
})
export class ConfigurarAdministradorComponent implements OnInit {
  page = 1;
	pageSize =38;
  imgEmergencia:string=Globals.imagenEmergencia;
  public formAddPersona: FormGroup;
  public editarLogo:boolean=false;
  public editarLogoCliente:boolean=false;
  public editarLogoClienteLoading:boolean=false;
  public editarImageClienteHome:boolean=false;
  public editarLogooMarcaAguaExameneEnable:boolean=false;
  public editarLogoFacturaEnable:boolean=false;
  public dataInfo:any;
  public userdata:any;
  public pdfpath:any="";
  public pdfpathreceta: any;
  public pdfpathexamen: any;
  constructor(private servicoConfig:ConfiguracionesAdministradorService,
              private ngxService: NgxUiLoaderService,
              public localdata: localStorageServices,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private fb: FormBuilder,
              public sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    this.refrescartodo();
  }

  editLogoEnable(val:boolean){
    this.editarLogo=val;
  }

  editLogoClienteEnable(val:boolean){
    this.editarLogoCliente=val;
  }

  editLogoClienteLoadingEnable(val:boolean){
    this.editarLogoClienteLoading=val;
  }

  editLogoMarcaAguaExameneEnable(val:boolean){
    this.editarLogooMarcaAguaExameneEnable=val;
  }

  editLogoFacturacionEnable(val:boolean){
    this.editarLogoFacturaEnable=val;
  }

  editImageClienteHomeEnable(val:boolean){
    this.editarImageClienteHome=val;
  }

  refrescartodo(){
    this.ngxService.startLoader('princialLoder'); 
    this.servicoConfig.getDataConfiguraciones().subscribe((data:any)=>{
      this.dataInfo=data.info;
      this.formAddPersona = this.fb.group({
        empresa:[data.info.empresa,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(250)])],
        direccion:[data.info.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
        logo:[data.info.logo,Validators.compose([Validators.required,Validators.minLength(1)])],
        tituloc:[data.info.aplicacion.empresa,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
        text:[data.info.aplicacion.text,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
        logoc:[data.info.aplicacion.logo,Validators.compose([Validators.required,Validators.minLength(1)])],
        logocl:[data.info.aplicacion.loading,Validators.compose([Validators.required,Validators.minLength(1)])],
        imghome:[data.info.aplicacion.home,Validators.compose([Validators.required,Validators.minLength(1)])],
        especialidades:[data.info.receta.especialidades,Validators.compose([Validators.required,Validators.minLength(1)])],
        horario:[data.info.receta.horario,Validators.compose([Validators.required,Validators.minLength(1)])],
        direccionreceta:[data.info.receta.direccion,Validators.compose([Validators.required,Validators.minLength(1)])],
        rp:[data.info.receta.rp,Validators.compose([Validators.required,Validators.minLength(1)])],
        indicaciones:[data.info.receta.indicaciones,Validators.compose([Validators.required,Validators.minLength(1)])],
        especilidades_uno:[data.info.examenmedico.especilidades_uno,Validators.compose([Validators.required,Validators.minLength(1)])],
        especilidades_dos:[data.info.examenmedico.especilidades_dos,Validators.compose([Validators.required,Validators.minLength(1)])],
        direccionexamen:[data.info.examenmedico.direccion,Validators.compose([Validators.required,Validators.minLength(1)])],
        logofull:[data.info.examenmedico.logofull,Validators.compose([Validators.required,Validators.minLength(1)])],
        logofactura:[data.info.factura.logofactura,Validators.compose([Validators.minLength(1)])],
        header:[data.info.factura.header,Validators.compose([Validators.minLength(1)])],
        headerapp:[data.info.aplicacion.headerapp,Validators.compose([Validators.minLength(1)])],
        headereceta:[data.info.receta.headereceta,Validators.compose([Validators.minLength(1)])],
        headerexamen:[data.info.examenmedico.headerexamen,Validators.compose([Validators.minLength(1)])],
        headercm:[data.info.factura.headercm,Validators.compose([Validators.minLength(2)])],
        headerappcm:[data.info.aplicacion.headerappcm,Validators.compose([Validators.minLength(2)])],
        headerecetacm:[data.info.receta.headerecetacm,Validators.compose([Validators.minLength(2)])],
        headerexamencm:[data.info.examenmedico.headerexamencm,Validators.compose([Validators.minLength(2)])],
        reportelogowpx:[data.info.factura.reportelogowpx,Validators.compose([Validators.minLength(2)])],
        reportelogohpx:[data.info.factura.reportelogohpx,Validators.compose([Validators.minLength(2)])],
        correo:[data.info.correo,Validators.compose([Validators.minLength(125)])],
        sitio:[data.info.sitio,Validators.compose([Validators.minLength(125)])],
        facturalogowpx:[data.info.factura.facturalogowpx,Validators.compose([Validators.minLength(2)])],
        facturalogohpx:[data.info.factura.facturalogohpx,Validators.compose([Validators.minLength(2)])],
        agregarmedicamento:[data.info.receta.agregarmedicamento,Validators.compose([Validators.minLength(2)])],
        metodopago:[data.info.aplicacion.metodopago,Validators.compose([Validators.minLength(2)])],
      });
      this.ngxService.stopLoader('princialLoder');
    });
  }

  save(){
    this.pdfpath=undefined;
    this.pdfpathreceta=undefined;
    this.pdfpathexamen=undefined;
    this.dataInfo.empresa=this.formAddPersona.value.empresa;
    this.dataInfo.direccion=this.formAddPersona.value.direccion;
    this.dataInfo.logo=this.formAddPersona.value.logo;
    this.dataInfo.correo=this.formAddPersona.value.correo;
    this.dataInfo.sitio=this.formAddPersona.value.sitio;

    this.dataInfo.aplicacion.empresa=this.formAddPersona.value.tituloc;
    this.dataInfo.aplicacion.text=this.formAddPersona.value.text;
    this.dataInfo.aplicacion.logo=this.formAddPersona.value.logoc;
    this.dataInfo.aplicacion.loading=this.formAddPersona.value.logocl;
    this.dataInfo.aplicacion.home=this.formAddPersona.value.imghome;
    this.dataInfo.aplicacion.headerapp=this.formAddPersona.value.headerapp;
    this.dataInfo.aplicacion.headerappcm=this.formAddPersona.value.headerappcm;
    this.dataInfo.aplicacion.metodopago=this.formAddPersona.value.metodopago;

    this.dataInfo.examenmedico.direccion=this.formAddPersona.value.direccionexamen;
    this.dataInfo.examenmedico.especilidades_dos=this.formAddPersona.value.especilidades_dos;
    this.dataInfo.examenmedico.especilidades_uno=this.formAddPersona.value.especilidades_uno;
    this.dataInfo.examenmedico.logofull=this.formAddPersona.value.logofull;
    this.dataInfo.examenmedico.headerexamen=this.formAddPersona.value.headerexamen;
    this.dataInfo.examenmedico.headerexamencm=this.formAddPersona.value.headerexamencm;

    this.dataInfo.receta.direccion=this.formAddPersona.value.direccionreceta;
    this.dataInfo.receta.especialidades=this.formAddPersona.value.especialidades;
    this.dataInfo.receta.horario=this.formAddPersona.value.horario;
    this.dataInfo.receta.indicaciones=this.formAddPersona.value.indicaciones;
    this.dataInfo.receta.rp=this.formAddPersona.value.rp;
    this.dataInfo.receta.headereceta=this.formAddPersona.value.headereceta;
    this.dataInfo.receta.headerecetacm=this.formAddPersona.value.headerecetacm;
    this.dataInfo.receta.agregarmedicamento=this.formAddPersona.value.agregarmedicamento;

   
    this.dataInfo.factura.logofactura=this.formAddPersona.value.logofactura;
    this.dataInfo.factura.header=this.formAddPersona.value.header;
    this.dataInfo.factura.headercm=this.formAddPersona.value.headercm;
    this.dataInfo.factura.reportelogohpx=this.formAddPersona.value.reportelogohpx;
    this.dataInfo.factura.reportelogowpx=this.formAddPersona.value.reportelogowpx;
    this.dataInfo.factura.facturalogohpx=this.formAddPersona.value.facturalogohpx;
    this.dataInfo.factura.facturalogowpx=this.formAddPersona.value.facturalogowpx;

    this.servicoConfig.saveConfiguraciones(this.dataInfo).subscribe((data:any)=>{
      this.userdata.datos.config=this.dataInfo.aplicacion;
      this.localdata.setAuthorizationData(this.userdata);
      this.refrescartodo();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  pdfReporteGeneral(){
    this.pdfpath=undefined;
    this.ngxService.startLoader('princialLoder');
		this.servicoConfig.genernerPruebaReporte().subscribe((data:any)=>{
      this.pdfpath = this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST + data.datos.PruebaPedidoInforme.ruta);
      this.ngxService.stopLoader('princialLoder');
    });
	}

  pdfReporteGeneralReceta(){
    this.pdfpathreceta=undefined;
    this.ngxService.startLoader('princialLoder');
		this.servicoConfig.genernerPruebaReporteReceta().subscribe((data:any)=>{
      this.pdfpathreceta = this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST + data.datos.PruebaPdfReporteReceta.ruta);
      this.ngxService.stopLoader('princialLoder');
    });
	}

  pdfReporteGeneralExamen(){
    this.pdfpathexamen=undefined;
    this.ngxService.startLoader('princialLoder');
		this.servicoConfig.genernerPruebaReporteExamen().subscribe((data:any)=>{
      this.pdfpathexamen = this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST + data.datos.PruebaPdfReporteExamen.ruta);
      this.ngxService.stopLoader('princialLoder');
    });
	}

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0];
    this.readFile(selectedFile); 
  }

  readFile(file: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      this.formAddPersona.get('logo').setValue(base64String);
			this.formAddPersona.updateValueAndValidity();
      this.save();
    };

    reader.readAsDataURL(file);
  }

  onFileSelectedExamenMedico(event: any) {
    const selectedFile = event.target.files[0];
    this.readFileExamenMedico(selectedFile); 
  }

  onFileSelectedFactura(event: any) {
    const selectedFile = event.target.files[0];
    this.readFileFactura(selectedFile); 
  }

  readFileExamenMedico(file: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      this.formAddPersona.get('logofull').setValue(base64String);
			this.formAddPersona.updateValueAndValidity();
      this.save();
    };

    reader.readAsDataURL(file);
  }

  readFileFactura(file: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      this.formAddPersona.get('logofactura').setValue(base64String);
			this.formAddPersona.updateValueAndValidity();
      this.save();
    };

    reader.readAsDataURL(file);
  }

}
