import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditCantoneComponent } from './edit/editcantone.component';
import { AddCantoneComponent } from './add/addcantone.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivaModule } from '../../../../directivas/directiva.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		DirectivaModule
	],
	declarations:[EditCantoneComponent,AddCantoneComponent],
	entryComponents:[EditCantoneComponent,AddCantoneComponent],
})
export class ModalCantoneModule { }
