import { Component, OnInit, Input } from '@angular/core';
import { customValidatorMondogo } from '../../../../custom-validator/customValidator.services';
import { PersonasServices } from '../../persona/personas.services';
import * as moment from 'moment';

@Component({
  selector: 'app-obtener-edad',
  templateUrl: './obtener-edad.component.html',
  styleUrls: ['./obtener-edad.component.scss']
})
export class ObtenerEdadComponent implements OnInit {
  @Input() fecha:any;
  edad:number;
  edadserver:number;
  constructor(private validatciones:customValidatorMondogo,private persona:PersonasServices) { }

  ngOnInit() {
  
  this.fecha =  this.fecha.split('T')[0];
 
  /* this.persona.getedadserver({fecha:this.fecha}).subscribe((data:any)=>{
     this.edad=data.edad; 
  }); */
  
  }

}
