import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-f008-seccion8]',
  templateUrl: './f008-seccion8.component.html',
  styleUrls: ['./f008-seccion8.component.scss']
})
export class F008Seccion8Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  tmpLista = [];
  tmps = [];
  constructor() { }

  ngOnInit() {
    this.tmps = this.infoFormulacionSeccion.motivos;
    this.tmpLista = this.tmps.filter(function (el) {
      return el.seleccion == 1;
    });
  }

}
