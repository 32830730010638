import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ParroquiasRoutes } from './parroquias.routing';
import { ParroquiasVistaComponent } from './vistas/parroquiavista.component';
import { ModalParroquiaModule } from './modal/modalparroquia.module';
import { ParroquiasServices } from './parroquia/parroquias.services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(ParroquiasRoutes),
		NgxDatatableModule,
		ModalParroquiaModule,
		NgbModule
	],
	providers:[
		ParroquiasServices
	],
	declarations:[
		ParroquiasVistaComponent,
	],
})

export class ParroquiasModule {}
