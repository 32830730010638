import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Solicitudexternas } from '../../solicitudexterna/solicitudexternas.model';
import { SolicitudexternasServices } from '../../solicitudexterna/solicitudexternas.services';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';

@Component({
	selector: 'app-addsolicitudexterna',
	templateUrl: './addsolicitudexterna.component.html',
	styleUrls: ['./addsolicitudexterna.component.scss']
})

export class AddSolicitudexternaComponent implements OnInit {
	public dataPersonas:any;
	public formAddSolicitudexterna: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioSolicitudexterna:SolicitudexternasServices,
		private servicioPersona:PersonasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPersona.getDataPersonas().subscribe((data:any)=>{
			this.dataPersonas=data.personas;
		});
		this.formAddSolicitudexterna = this.fb.group({
			persona_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let solicitudexternaDataModel:Solicitudexternas=this.formAddSolicitudexterna.value;
		this.servicioSolicitudexterna.saveSolicitudexternas(solicitudexternaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}