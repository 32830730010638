import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../../servicios/component.servicios';

@Component({
  selector: '[app-f008-seccion1]',
  templateUrl: './f008-seccion1.component.html',
  styleUrls: ['./f008-seccion1.component.scss']
})
export class F008Seccion1Component implements OnInit {
  @Input() infoFormulacionSeccion:any;
  @Input() idCitaMedica?:any;
  
  dataInfoCitaMedicaResumen: any;
  constructor(private servicioApps: ElementComponentService) { }

  ngOnInit() {
    this.servicioApps.getDataViewCitaMedica(this.idCitaMedica).subscribe((data: any) => {
      this.dataInfoCitaMedicaResumen = data.citasmedica;
      this.dataInfoCitaMedicaResumen.formastodo.forEach((element: any) => {
        if (this.dataInfoCitaMedicaResumen.formacitamedicas.length > 0 && this.dataInfoCitaMedicaResumen.formacitamedicas[0].formallegada_id == element.id) {
          element.seleccion = 1;
        } else {
          element.seleccion = 0;
        }
      });
    });
  }

}
