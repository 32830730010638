import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Parroquias, Zonas} from './parroquias.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class ParroquiasServices {

	constructor(private http: HttpClient) { }

	getDataParroquias():Observable<Parroquias[]>{
		return this.http.get<Parroquias[]>(Globals.BASE_URL_API_REST+'parroquias.json');
	}

	saveParroquias(data:Parroquias){
		return this.http.post(Globals.BASE_URL_API_REST+'parroquias.json',data);
	}

	deleteParroquias(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'parroquias/'+id+'.json');
	}

	updateParroquias(data:Parroquias){
		 return this.http.patch(Globals.BASE_URL_API_REST+'parroquias/'+data.id+'.json',data);
	}

	viewParroquias(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'parroquias/'+id+'.json');
	}

	getDataZonas():Observable<Zonas[]>{
		return this.http.get<Zonas[]>(Globals.BASE_URL_API_REST+'zonas.json');
	}


}