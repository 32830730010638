import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditOpcionesmotivoComponent } from './edit/editopcionesmotivo.component';
import { AddOpcionesmotivoComponent } from './add/addopcionesmotivo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DirectivaModule } from '../../../../directivas/directiva.module';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivaModule
	],
	declarations:[EditOpcionesmotivoComponent,AddOpcionesmotivoComponent],
	entryComponents:[EditOpcionesmotivoComponent,AddOpcionesmotivoComponent],
})
export class ModalOpcionesmotivoModule { }
