import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Globals, Impresora } from './global.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ListaImpresorasServices {

        constructor(private http: HttpClient) { }

        viewAdministradoresImpresoras(data: any) {
                return this.http.get(Globals.BASE_URL_API_REST + 'ap_impresoras_lista/' + data.group_id + '/' + data.user_id + '.json');
        }

        saveImpresoras(data: Impresora) {
                return this.http.post(Globals.BASE_URL_API_REST + 'impresoras.json', data);
        }

        updateImpresoras(data: Impresora) {
                return this.http.patch(Globals.BASE_URL_API_REST + 'impresoras/' + data.id + '.json', data);
        }

        printerPagoCita(data: any) {
                let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
                return this.http.post('https://' + data.ip + ':3333/pagos', data.data, { headers: headers });
        }

        printerPagoCitaCuota(data: any) {
                let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
                return this.http.post('https://' + data.ip + ':3333/cuotas', data.data, { headers: headers });
        }

        printerPagoExamen(data: any) {
                let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
                return this.http.post('https://' + data.ip + ':3333/pagoexamenes', data.data, { headers: headers });
        }

        printerPagoExamenCuota(data: any) {
                let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
                return this.http.post('https://' + data.ip + ':3333/cuotaexamenes', data.data, { headers: headers });
        }

        printerPueba(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/prueba', data.data, { headers: headers });
        }
        
        printerDetalleExamenes(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/examenesdetalles', data.data, { headers: headers });
	}

        printerFactura(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/facturas', data.data, { headers: headers });
	}

        listasDeFacturasVentas() {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_notificacion_factura_venta.json');
	}
}