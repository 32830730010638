import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../../global/global.model';
import { Motivoscitas } from './motivoscitas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class MotivoscitasServices {

	constructor(private http: HttpClient) { }

	getDataMotivoscitas(): Observable<Motivoscitas[]> {
		return this.http.get<Motivoscitas[]>(Globals.BASE_URL_API_REST + 'motivoscitas.json');
	}

	getDataCitasFormularioMotivo(form: string, seccion: number): Observable<Motivoscitas[]> {
		return this.http.get<Motivoscitas[]>(Globals.BASE_URL_API_REST + 'buscar_motivo_formulario.json?form=' + form + '&seccion=' + seccion);
	}

	saveMotivoscitas(data: Motivoscitas) {
		return this.http.post(Globals.BASE_URL_API_REST + 'motivoscitas.json', data);
	}

	deleteMotivoscitas(id: number) {
		return this.http.delete(Globals.BASE_URL_API_REST + 'motivoscitas/' + id + '.json');
	}

	updateMotivoscitas(data: Motivoscitas) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'motivoscitas/' + data.id + '.json', data);
	}

	updateMotivoscitasSiguiente(data: Motivoscitas) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'op_motivos_actualizar_next/' + data.id + '.json', data);
	}

	viewMotivoscitas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'motivoscitas/' + id + '.json');
	}

	getAppsPacienteEdicionCie10Citas(nombres: string, historiasclinica_id: number): Observable<any[]> {
		return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'op_apps_historial_edicion_cie10.json?nombres=' + nombres + '&historiasclinica_id=' + historiasclinica_id);
	}
}