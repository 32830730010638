import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Opcionesmotivos} from './opcionesmotivos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Motivoscitas } from '../../motivoscitas/motivoscita/motivoscitas.model';


@Injectable()
export class OpcionesmotivosServices {

	constructor(private http: HttpClient) { }

	getDataOpcionesmotivos(id:number):Observable<Opcionesmotivos[]>{
		return this.http.get<Opcionesmotivos[]>(Globals.BASE_URL_API_REST+'opcionesmotivos.json?id='+id);
	}

	saveOpcionesmotivos(data:Opcionesmotivos){
		return this.http.post(Globals.BASE_URL_API_REST+'opcionesmotivos.json',data);
	}

	deleteOpcionesmotivos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'opcionesmotivos/'+id+'.json');
	}

	updateOpcionesmotivos(data:Opcionesmotivos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'opcionesmotivos/'+data.id+'.json',data);
	}

	viewOpcionesmotivos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'opcionesmotivos/'+id+'.json');
	}

	updateMotivoscitasOpcioneSiguiente(data: Motivoscitas) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'op_motivos_actualizar_opcion_next/' + data.id + '.json', data);
	}
}