import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { EmpleadosServices } from '../empleado/empleados.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Empleados } from '../empleado/empleados.model';
import { AddEmpleadoComponent } from '../modal/add/addempleado.component';
import { EditEmpleadoComponent } from '../modal/edit/editempleado.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-empleadovista',
	templateUrl: './empleadovista.component.html',
	styleUrls: ['./empleadovista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class EmpleadosVistaComponent implements OnInit {
	rows = [];
	temp = [];
	timeout: any;

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	}, {
		text: 'Refrescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if (text == 'Refrescar') {
			this.listaEmpleados();
		} else if (text == 'Nuevo') {
			this.open();
		}
	}

	@ViewChild('tableEmpleados', { static: false }) table: any;

	constructor(public dialog: NgbModal,
		private serviciosEmpleados: EmpleadosServices,
		private notificationServices: NotificationServices,
		private ngxService: NgxUiLoaderService,
	) { }

	ngOnInit() {
		this.listaEmpleados();
	}

	listaEmpleados() {
		this.ngxService.startLoader('princialLoder');
		this.serviciosEmpleados.getDataEmpleados().subscribe((data: any) => {
			this.rows = data.empleados;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return d.cedula.toLowerCase().indexOf(val) !== -1
				|| (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1)
				|| (d.apellido && d.apellido.toLowerCase().indexOf(val) !== -1)
				|| (d.razonsocial && d.razonsocial.toLowerCase().indexOf(val) !== -1)
				|| !val;
		});
		this.rows = temp;
	}

	open() {
		let dialogRef = this.dialog.open(AddEmpleadoComponent, this.config);
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaEmpleados();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	delete(dataEmpleado: any) {
		let data = { 'val': dataEmpleado.estado, 'msg': 'Eliminar Empleado' };
		this.notificationServices.msgEliminarConfir(data).then((datas) => {
			if (datas) {
				this.serviciosEmpleados.deleteEmpleados(dataEmpleado.id).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaEmpleados();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data: any) {
		let dialogRef = this.dialog.open(EditEmpleadoComponent, this.config);
		dialogRef.componentInstance.Empleado = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaEmpleados();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataEmpleado: Empleados) {
		let dataEstadoEmpleado = {} as Empleados;
		dataEstadoEmpleado.estado = Number(!dataEmpleado.estado);
		dataEstadoEmpleado.persona_id = dataEmpleado.persona_id;
		dataEstadoEmpleado.tipoempleado_id = dataEmpleado.tipoempleado_id;
		this.serviciosEmpleados.updateEmpleados(dataEstadoEmpleado).subscribe((data: any) => {
			this.notificationServices.setNotification(data);
		});
	}

	onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			//console.log('paged!', event);
		}, 100);
	}

	toggleExpandRow(row, i) {
		this.table.rowDetail.toggleExpandRow(row);
	}

	onDetailToggle(event) {
		//console.log('Detail Toggled', event);
	}

}

