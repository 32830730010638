import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditMotivoscitaComponent } from './edit/editmotivoscita.component';
import { AddMotivoscitaComponent } from './add/addmotivoscita.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DirectivaModule } from '../../../../directivas/directiva.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivaModule,
		NgSelectModule
	],
	declarations:[EditMotivoscitaComponent,AddMotivoscitaComponent],
	entryComponents:[EditMotivoscitaComponent,AddMotivoscitaComponent],
})
export class ModalMotivoscitaModule { }
