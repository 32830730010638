export class Motivoscitas{
	id?:number;
	codigo?:string;
	nombre?:string;
	formulario?:string;
	seccion?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
	tipo?:number;
	orden?:number;
	nextorden?:number;
	inicio?:number;
	fin?:number;
	col?:number;
	medida?:string;
}