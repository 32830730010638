import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CantonesRoutes } from './cantones.routing';
import { CantonesVistaComponent } from './vistas/cantonevista.component';
import { ModalCantoneModule } from './modal/modalcantone.module';
import { CantonesServices } from './cantone/cantones.services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(CantonesRoutes),
		NgxDatatableModule,
		ModalCantoneModule,
		NgbModule
	],
	providers:[
		CantonesServices
	],
	declarations:[
		CantonesVistaComponent,
	],
})

export class CantonesModule {}
