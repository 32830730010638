import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Empleados } from '../../empleado/empleados.model';
import { EmpleadosServices } from '../../empleado/empleados.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';
import { Tipoempleados } from '../../../tipoempleados/tipoempleado/tipoempleados.model';
import { TipoempleadosServices } from '../../../tipoempleados/tipoempleado/tipoempleados.services';

@Component({
	selector: 'app-editempleado',
	templateUrl: './editempleado.component.html',
	styleUrls: ['./editempleado.component.scss']
})

export class EditEmpleadoComponent implements OnInit {

	@Input() Empleado:Empleados;
	public dataPersonas={'prefijo':'vacio'} as Personas;
	public personasData:any;
	public dataTipoempleados={'nombre':'vacio'} as Tipoempleados;
	public tipoempleadosData:any;

	public formEmpleadoPersona:FormGroup;
	public editPersona:boolean=false;

	public formEmpleadoTipoempleado:FormGroup;
	public editTipoempleado:boolean=false;

	public formEmpleadoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEmpleado:EmpleadosServices,
		private servicioPersona:PersonasServices,
		private servicioTipoempleado:TipoempleadosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPersona.getDataPersonas().subscribe((data:any)=>{
			this.personasData=data.personas;
			this.dataPersonas=this.personasData.find(obj => obj.id == this.Empleado.persona_id);
		});
		this.servicioTipoempleado.getDataTipoempleados().subscribe((data:any)=>{
			this.tipoempleadosData=data.tipoempleados;
			this.dataTipoempleados=this.tipoempleadosData.find(obj => obj.id == this.Empleado.tipoempleado_id);
		});

		this.formEmpleadoPersona=this.fb.group({
			persona_id:[this.Empleado.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formEmpleadoTipoempleado=this.fb.group({
			tipoempleado_id:[this.Empleado.tipoempleado_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	
	editEmpleadoPersonaEnable(){
		this.editPersona=true;
		this.formEmpleadoPersona=this.fb.group({
			persona_id:[this.Empleado.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEmpleadoPersonaDisable(){
		this.editPersona=false;
	}

	saveEmpleadoPersona(){
		let EmpleadoDataModel:Empleados=this.formEmpleadoPersona.value;
		//EmpleadoDataModel.id=this.Empleado.id;
		this.Empleado.persona_id=EmpleadoDataModel.persona_id;
		this.servicioEmpleado.updateEmpleados(EmpleadoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPersona=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editEmpleadoTipoempleadoEnable(){
		this.editTipoempleado=true;
		this.formEmpleadoTipoempleado=this.fb.group({
			tipoempleado_id:[this.Empleado.tipoempleado_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEmpleadoTipoempleadoDisable(){
		this.editTipoempleado=false;
	}

	saveEmpleadoTipoempleado(){
		let EmpleadoDataModel:Empleados=this.formEmpleadoTipoempleado.value;
		//EmpleadoDataModel.id=this.Empleado.id;
		this.Empleado.tipoempleado_id=EmpleadoDataModel.tipoempleado_id;
		this.servicioEmpleado.updateEmpleados(EmpleadoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTipoempleado=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

