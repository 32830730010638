import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { AddMotivoscitaComponent } from '../modal/add/addmotivoscita.component';
import { EditMotivoscitaComponent } from '../modal/edit/editmotivoscita.component';
import { Motivoscitas } from '../motivoscita/motivoscitas.model';
import { MotivoscitasServices } from '../motivoscita/motivoscitas.services';

@Component({
  selector: 'app-motivos-agupado-seccion-detalles',
  templateUrl: './motivos-agupado-seccion-detalles.component.html',
  styleUrls: ['./motivos-agupado-seccion-detalles.component.scss']
})
export class MotivosAgupadoSeccionDetallesComponent implements OnInit {
  @Input() items=[];
  timeout: any;
  position:number=-1;

  iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	}, {
		text: 'Refescar',
		icon: 'mdi mdi-backup-restore'
	}];

  config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}

  @ViewChild('tableMotivoscita', { static: false }) table: any;


  constructor(private serviciosMotivoscitas: MotivoscitasServices,
    public dialog: NgbModal,
		private notificationServices: NotificationServices,) { }

  ngOnInit() {
  }

  select(text: string) {
		if (text == 'Refescar') {
			this.listaMotivoscitas();
		} else if (text == 'Nuevo') {
			this.open();
		}
	}

  listaMotivoscitas() {
    
		this.serviciosMotivoscitas.getDataCitasFormularioMotivo(this.items[0].formulario,this.items[0].seccion).subscribe((data: any) => {
      this.items=[];
		  this.items = data.motivoscitas;
		});
	}

  open() {
		let dialogRef = this.dialog.open(AddMotivoscitaComponent, this.config);
    dialogRef.componentInstance.form = this.items[0].formulario;
    dialogRef.componentInstance.seccion = this.items[0].seccion;
    dialogRef.componentInstance.Orden = this.items.length+1;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaMotivoscitas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	delete(dataMotivoscita: any) {
		let data = { 'val': dataMotivoscita.codigo, 'msg': 'Eliminar Motivoscitas' };
		this.notificationServices.msgEliminarConfir(data).then((datas) => {
			if (datas) {
				this.serviciosMotivoscitas.deleteMotivoscitas(dataMotivoscita.id).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaMotivoscitas();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					} 
				});
			}
		});
	}

	edit(data: any) {
		let dialogRef = this.dialog.open(EditMotivoscitaComponent, this.config);
		dialogRef.componentInstance.Motivoscita = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaMotivoscitas();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataMotivoscita: Motivoscitas) {
		let dataEstadoMotivoscita = {} as Motivoscitas;
		dataEstadoMotivoscita.id = dataMotivoscita.id;
		dataEstadoMotivoscita.estado = Number(!dataMotivoscita.estado);
		this.serviciosMotivoscitas.updateMotivoscitas(dataEstadoMotivoscita).subscribe((data: any) => { });
	}


  onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			//console.log('paged!', event);
		}, 100);
	}

	toggleExpandRow(row, i) {
		this.table.rowDetail.toggleExpandRow(row);
	}

	onDetailToggle(event) {
		//console.log('Detail Toggled', event);
	}

  listaMotivosCambio(data:any){
		this.position=data.orden;
    this.serviciosMotivoscitas.getDataCitasFormularioMotivo(this.items[0].formulario,this.items[0].seccion).subscribe((data: any) => {
      this.items=[];
		  this.items = data.motivoscitas;
		});
	}

}
