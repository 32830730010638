import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Cantones} from './cantones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class CantonesServices {

	constructor(private http: HttpClient) { }

	getDataCantones():Observable<Cantones[]>{
		return this.http.get<Cantones[]>(Globals.BASE_URL_API_REST+'cantones.json');
	}

	saveCantones(data:Cantones){
		return this.http.post(Globals.BASE_URL_API_REST+'cantones.json',data);
	}

	deleteCantones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'cantones/'+id+'.json');
	}

	updateCantones(data:Cantones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'cantones/'+data.id+'.json',data);
	}

	viewCantones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'cantones/'+id+'.json');
	}
}