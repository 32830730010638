import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PersonasServices } from '../../persona/personas.services';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddProvinciaComponent } from '../../../../ubicacion/provincias/modal/add/addprovincia.component';
import { AddCantoneComponent } from '../../../../ubicacion/cantones/modal/add/addcantone.component';
import { AddParroquiaComponent } from '../../../../ubicacion/parroquias/modal/add/addparroquia.component';
import { ParroquiasServices } from '../../../../ubicacion/parroquias/parroquia/parroquias.services';

@Component({
  selector: 'app-ubicaciones',
  templateUrl: './ubicaciones.component.html',
  styleUrls: ['./ubicaciones.component.scss']
})
export class UbicacionesComponent implements OnInit {

  dataProvincias=[];
  dataCantones=[];
  dataParroquias=[];
  @Input() dataUbicacionPersona={} as any;
  @Input() op:number;
  @Output() clickEvent = new EventEmitter();

  config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}

  @ViewChild('cantone_id', { static: false }) cantone: any;
  @ViewChild('parroquia_id', { static: false }) parroquia: any;

  constructor(private servicioPacientes:PersonasServices,
    private servicioParroquia:ParroquiasServices,
    private route: ActivatedRoute,
    public dialog: NgbModal,) { }

  ngOnInit() {
    
    this.servicioPacientes.getDataPacienteProvincias().subscribe((dataPro:any)=>{
      this.dataProvincias=dataPro.provincias;
      if(this.dataUbicacionPersona.parroquia_id==undefined){
        this.dataUbicacionPersona={parroquia_id:0,cantone_id:0,provincia_id:0};
      }else{
        if(this.dataUbicacionPersona.parroquia_id!=null){
          this.servicioParroquia.viewParroquias(this.dataUbicacionPersona.parroquia_id).subscribe((dataParr:any)=>{
            this.dataUbicacionPersona.cantone_id=dataParr.parroquia.cantone.id;
            this.dataUbicacionPersona.provincia_id=dataParr.parroquia.cantone.provincia.id;
            this.servicioPacientes.getDataPacienteCantones(dataParr.parroquia.cantone.provincia.id).subscribe((dataCan:any)=>{
              this.dataCantones=dataCan.cantones;
              this.servicioPacientes.getDataPacienteParroquias(dataParr.parroquia.cantone.id).subscribe((dataParro:any)=>{
                this.dataParroquias=dataParro.parroquias;
              });
            }); 
          });
          
        }
      }
    });

    
   
  }

  selectCantones(){
    this.dataCantones=[];
    this.dataParroquias=[];
    if(this.dataUbicacionPersona.provincia_id!=null){
      this.servicioPacientes.getDataPacienteCantones(this.dataUbicacionPersona.provincia_id).subscribe((dataCan:any)=>{
        this.dataCantones=dataCan.cantones;
        this.cantone.handleClearClick();
        this.parroquia.handleClearClick();
      });
    }else{
      this.cantone.handleClearClick();
      this.parroquia.handleClearClick();
    }
  }

  selectParroquias(){
    this.dataParroquias=[];
    if(this.dataUbicacionPersona.cantone_id!=null){
      this.servicioPacientes.getDataPacienteParroquias(this.dataUbicacionPersona.cantone_id).subscribe((dataParro:any)=>{
        this.dataParroquias=dataParro.parroquias;
        this.parroquia.handleClearClick();
      });
    }else{
      this.parroquia.handleClearClick();
    }
  }

  openProvincia(){
		let dialogRef = this.dialog.open(AddProvinciaComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result){
          
					this.servicioPacientes.getDataPacienteProvincias().subscribe((dataPro:any)=>{
            this.dataProvincias=dataPro.provincias;
            this.dataUbicacionPersona.provincia_id=result.datos.id;
            this.dataUbicacionPersona.cantone_id=null;
            this.dataUbicacionPersona.parroquia_id=null;
            this.cantone.handleClearClick();
            this.parroquia.handleClearClick();

            if(this.dataUbicacionPersona.provincia_id!=null){
              this.servicioPacientes.getDataPacienteCantones(this.dataUbicacionPersona.provincia_id).subscribe((dataCan:any)=>{
                this.dataCantones=dataCan.cantones;
              }); 
            }
          });
				}
			},
			reason=>{
				console.log(reason);
			}
		);
  }
  
  openCantones(){
    let dialogRef = this.dialog.open(AddCantoneComponent, this.config);
    dialogRef.componentInstance.provincia_id = this.dataUbicacionPersona.provincia_id;
		dialogRef.result.then(
			result=>{
				if(result){
          this.dataUbicacionPersona.cantone_id=result.datos.id;
          this.parroquia.handleClearClick();
          this.dataUbicacionPersona.parroquia_id=null;
          this.servicioPacientes.getDataPacienteCantones(this.dataUbicacionPersona.provincia_id).subscribe((dataCan:any)=>{
            this.dataCantones=dataCan.cantones;
            this.servicioPacientes.getDataPacienteParroquias(this.dataUbicacionPersona.cantone_id).subscribe((dataParro:any)=>{
              this.dataParroquias=dataParro.parroquias;
            });
            
          });
				}
			},
			reason=>{
				console.log(reason);
			}
		);
  }
  
  openParroquias(){
    let dialogRef = this.dialog.open(AddParroquiaComponent, this.config);
    dialogRef.componentInstance.cantone_id = this.dataUbicacionPersona.cantone_id;
		dialogRef.result.then(
			result=>{
				if(result){
          this.dataUbicacionPersona.parroquia_id=result.datos.id;
          this.clickEvent.emit(this.dataUbicacionPersona);
					this.servicioPacientes.getDataPacienteParroquias(this.dataUbicacionPersona.cantone_id).subscribe((dataParro:any)=>{
            this.dataParroquias=dataParro.parroquias;
          });
				}
			},
			reason=>{
				console.log(reason);
			}
		);
  }
  
  seleccionarParroquia(){
    this.clickEvent.emit(this.dataUbicacionPersona);
  }

}
