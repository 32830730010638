import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesAdministradorService {

    constructor(private http: HttpClient) { }

    getDataConfiguraciones():Observable<any[]>{
            return this.http.get<any[]>(Globals.BASE_URL_API_REST+'amw_config.json');
    }

    saveConfiguraciones(data:any){
      return this.http.post(Globals.BASE_URL_API_REST+'amw_config.json',data);
    }

    genernerPruebaReporte(){
      return this.http.post(Globals.BASE_URL_API_REST+'prueba_pdf_docuemntos.json',{});
    }

    genernerPruebaReporteReceta(){
      return this.http.put(Globals.BASE_URL_API_REST+'prueba_pdf_docuemntos.json',{});
    }

    genernerPruebaReporteExamen(){
      return this.http.patch(Globals.BASE_URL_API_REST+'prueba_pdf_docuemntos.json',{});
    }

}