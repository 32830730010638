import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdministracionesFacturasService } from '../../../appfacturas/administraciones/servicios/administraciones.facturas.services';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';

@Component({
  selector: '[app-editar-boton-factura]',
  templateUrl: './editar-boton-factura.component.html',
  styleUrls: ['./editar-boton-factura.component.scss']
})
export class EditarBotonFacturaComponent implements OnInit {
  @Input() infoFactuea:any;
  @Output() clickEvent = new EventEmitter();
  texto:string="";
  loading:string="";
  userdata: any;
  facturaInfo: any;
  notaCreditoInfo: any;
  dataEstado: any;

  constructor(public localdata: localStorageServices,
    private ngxService: NgxUiLoaderService,
    private comprobantesServicios: AdministracionesFacturasService,) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    if(this.userdata && Object.entries(this.userdata).length > 0){
      this.texto="";
      this.loading=this.userdata.datos.config.logo;
    }
  }

  buscar(){
    let dataSend = { group_id: this.userdata.grupo.group_id, user_id: this.userdata.grupo.user_id } as any;
    this.ngxService.startLoader('editar' + this.infoFactuea.id);

    this.comprobantesServicios.buscarComprobantesPermisos(dataSend).subscribe((data: any) => {
      for (let index = 0; index < data.permisocomprobante.length; index++) {
        const element = data.permisocomprobante[index];
        if (element.comprobantelectronico.tipocomprobante.codigo == '01' && this.infoFactuea.locale_id == element.comprobantelectronico.locale.id) {
          this.facturaInfo = element;
          if (this.infoFactuea.tipo == 2) {
            this.verEstadoFacturacion();
          }
        }

        /* if (element.comprobantelectronico.tipocomprobante.codigo == '04' && this.infoFactuea.locale_id == element.comprobantelectronico.locale.id) {
          this.notaCreditoInfo = element;
          if (this.infoFactuea.tipo == 2) {
            this.verEstadoNotaDeCredito();
          }
        } */


      }
      this.ngxService.stopLoader('editar' + this.infoFactuea.id);
    });
    
  }
  verEstadoFacturacion() {
    let dataFactura = { 'comprobantelectronico_id': this.facturaInfo.comprobantelectronico_id, 'factura_id': this.infoFactuea.id } as any;
    this.comprobantesServicios.buscarComprobantesEstado(dataFactura).subscribe((data: any) => {
      this.dataEstado = data.facturaciones;
      let inicio = moment(this.dataEstado.created).utc();
      let fin = moment().utc();
      
      this.infoFactuea.numero=this.dataEstado.numero;
      this.infoFactuea.tipo = 2;
      this.clickEvent.emit({tiempo:moment.duration(fin.diff(moment(inicio))),facturacione:this.dataEstado,factura:this.infoFactuea})      
    });
  }


  editar(){
    this.buscar();
  }

}
