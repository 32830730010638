import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Insumounidades} from './insumounidades.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class InsumounidadesServices {

	constructor(private http: HttpClient) { }

	getDataInsumounidades():Observable<Insumounidades[]>{
		return this.http.get<Insumounidades[]>(Globals.BASE_URL_API_REST+'insumounidades.json');
	}

	saveInsumounidades(data:Insumounidades){
		return this.http.post(Globals.BASE_URL_API_REST+'insumounidades.json',data);
	}

	deleteInsumounidades(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'insumounidades/'+id+'.json');
	}

	updateInsumounidades(data:Insumounidades){
		 return this.http.patch(Globals.BASE_URL_API_REST+'insumounidades/'+data.id+'.json',data);
	}

	viewInsumounidades(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'insumounidades/'+id+'.json');
	}

	getDataListaInsumoUnidades():Observable<Insumounidades[]>{
		return this.http.get<Insumounidades[]>(Globals.BASE_URL_API_REST+'ap_insumo_unidades_lista.json');
	}
	
}