import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class ElementComponentService {

    constructor(private http: HttpClient) { }

    getDataConfirmarVentaFormaPago(): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'op_forma_pago_venta.json');
    }

    getDataViewApps(id: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'apps/' + id + '.json');
    }

    getDataViewApfs(id: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'apfs/' + id + '.json');
    }

    getDataViewOcupaciones(id: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'historiaocupaciones/' + id + '.json');
    }

    getDataViewHistoriaclinicas(id: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'historiasclinicas/' + id + '.json');
    }

    getDataViewCitaMedica(id: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'citasmedicas/' + id + '.json');
    }

    updateCitasmedicasElement(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'citasmedicas/' + data.id + '.json', data);
    }

    getDataViewCitaMedicaCertificadoMedico(id: number,data:any): Observable<any[]> {
        return this.http.post<any[]>(Globals.BASE_URL_API_REST + 'informe_filtrado_cetificado_medico/' + id + '.json',data);
    }

    updateAtencionesmedicasElement(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'atencionesmedicas/' + data.id + '.json', data);
    }

    getDataCitasMotivosFormResumen(form: string, seccion: number, idcita: number, idatencionmedica: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'lista_motivos_citas_asignados_resumen/' + idcita + '/' + seccion + '/' + idatencionmedica + '.json?form=' + form);
    }

    getDataCitasFormularioMotivo(form: string, seccion: number): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'buscar_motivo_formulario.json?form=' + form + '&seccion=' + seccion);
    }

    viewAtencionCitaMedicaResumen(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_atencion_motivo_resumen/' + id + '.json');
    }

    getDataFrecuenciamedicamentosResumen(): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'frecuenciamedicamentos.json');
    }

    getDataMedidaMedicinasResumen(): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'op_medidas_medicina.json');
    }
    
    getDataMedidaMedicinasResumenControl(): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'op_medidas_medicina_control.json');
    }

    viewAtencionCitaMedicaConsultaExamenResumen(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_examen_cita_resumen/' + id + '.json');
    }

    viewAtencionCitaMedicaMedicamentosIndicaciones(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_hospitalizar_indicaciones_medicamentos/' + id + '.json');
    }

    viewAtencionCitaMedicaEmergenciaMedicamentosIndicaciones(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_hospitalizar_indicaciones_medicamentos_emergencia/' + id + '.json');
    }

    viewEpicrisis(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_hospitalizar_epicrisis/' + id + '.json');
    }

    updateIndicacionesHospitalizar(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'indicaciones/' + data.id + '.json', data);
    }

    viewFacturasEditar(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'facturas_vita_info/' + id + '.json');
    }

    viewPersonasEditar(nombres: string) {
        return this.http.get(Globals.BASE_URL_API_REST + 'op_pacientes_busquedas.json?nombres=' + nombres);
    }

    MostrarCitasYExamenesFacturasBusquedasEdiciones(data: any) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_mostrar_parafacturar/' + data + '.json');
    }

    MostrarCitasYExamenesDetalleFacturas(data: any) {
        return this.http.post(Globals.BASE_URL_API_REST + 'lista_mostrar_parafacturar_detalles_save.json', data);
    }

    CitasFacturasLista(data: any) {
        return this.http.post(Globals.BASE_URL_API_REST + 'buscar_hospitaliacion_facturas_pagos.json', data);
    }

    updateHospitalizacionesFin(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'hospitalizaciones/' + data.id + '.json', data);
    }

    viewIndicacionrecetasResumen(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'indicacionrecetas/' + id + '.json');
    }

    viewIndicacionesgeneralesHospitalizacionResumen(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_indicaciones_generales_hospitalizacion/' + id + '.json');
    }

    viewConsultaExternaRecetasResumnes(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_rectea_consulta_externa/' + id + '.json');
    }

    viewIndicacionesgeneralesConsultaExternasResumnes(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_indicaciones_consulta_externa/' + id + '.json');
    }

    getDataComplementomedicamentos(id: number): Observable<any[]> {
		return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'lista_indicaciones_adicionales/' + id + '.json');
	}

    viewControlGlicemicoHospitalizaciones(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_control_glicemicas_resumen_hospitalizacion/' + id + '.json');
    }

    viewControlGlicemicoCitaMedica(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'lista_control_glicemicas_resumen_citamedica/' + id + '.json');
    }
    
    getDataComerciales(): Observable<any[]> {
		return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'lista_certificados_locales.json');
	}

    getDataDiagnostico(id:number): Observable<any[]> {
		return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'lista_certificados_diagnosticos/'+id+'.json');
	}

    saveCertificados(data: any) {
        return this.http.post(Globals.BASE_URL_API_REST + 'certificados.json', data);
    }

    generateCertificados(data:any){
        return this.http.post(Globals.BASE_URL_API_REST + 'generatecertificados.json', data);
    }

    updateCertificados(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'certificados/'+data.id+'.json', data);
    }

    viewCertificadoEncontrado(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'certificado_guardado/' + id + '.json');
    }

    viewRegistoMedico(id: number,especialidad:number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_registro_medico/' + id + '/'+especialidad+'.json');
    }

    viewHistoriaocupaciones(id:number){
        return this.http.get(Globals.BASE_URL_API_REST+'historiaocupaciones/'+id+'.json');
    }

    viewEspecialidad_personales(id:number){
        return this.http.get(Globals.BASE_URL_API_REST+'especialidad_personales/'+id+'.json');
    }
    
}