import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Formacionacademicas} from './formacionacademicas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class FormacionacademicasServices {

	constructor(private http: HttpClient) { }

	getDataFormacionacademicas():Observable<Formacionacademicas[]>{
		return this.http.get<Formacionacademicas[]>(Globals.BASE_URL_API_REST+'formacionacademicas.json');
	}

	saveFormacionacademicas(data:Formacionacademicas){
		return this.http.post(Globals.BASE_URL_API_REST+'formacionacademicas.json',data);
	}

	deleteFormacionacademicas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'formacionacademicas/'+id+'.json');
	}

	updateFormacionacademicas(data:Formacionacademicas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'formacionacademicas/'+data.id+'.json',data);
	}

	viewFormacionacademicas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'formacionacademicas/'+id+'.json');
	}
}