import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Solicitudexternas } from '../../solicitudexterna/solicitudexternas.model';
import { SolicitudexternasServices } from '../../solicitudexterna/solicitudexternas.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Personas } from '../../../personas/persona/personas.model';
import { PersonasServices } from '../../../personas/persona/personas.services';

@Component({
	selector: 'app-editsolicitudexterna',
	templateUrl: './editsolicitudexterna.component.html',
	styleUrls: ['./editsolicitudexterna.component.scss']
})

export class EditSolicitudexternaComponent implements OnInit {

	@Input() Solicitudexterna:Solicitudexternas;
	public dataPersonas={'cedula':'vacio'} as Personas;
	public personasData:any;

	public formSolicitudexternaPersona:FormGroup;
	public editPersona:boolean=false;

	public formSolicitudexternaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioSolicitudexterna:SolicitudexternasServices,
		private servicioPersona:PersonasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPersona.getDataPersonas().subscribe((data:any)=>{
			this.personasData=data.personas;
			this.dataPersonas=this.personasData.find(obj => obj.id == this.Solicitudexterna.persona_id);
		});

		this.formSolicitudexternaPersona=this.fb.group({
			persona_id:[this.Solicitudexterna.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	
	editSolicitudexternaPersonaEnable(){
		this.editPersona=true;
		this.formSolicitudexternaPersona=this.fb.group({
			persona_id:[this.Solicitudexterna.persona_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editSolicitudexternaPersonaDisable(){
		this.editPersona=false;
	}

	saveSolicitudexternaPersona(){
		let SolicitudexternaDataModel:Solicitudexternas=this.formSolicitudexternaPersona.value;
		SolicitudexternaDataModel.id=this.Solicitudexterna.id;
		this.Solicitudexterna.persona_id=SolicitudexternaDataModel.persona_id;
		this.servicioSolicitudexterna.updateSolicitudexternas(SolicitudexternaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPersona=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

