import { Component, OnInit, Input } from '@angular/core';
import { Especialidades } from '../../especialidades/especialidade/especialidades.model';
import { EspecialidadesServices } from '../../especialidades/especialidade/especialidades.services';
import { Especialidad_personalesServices } from '../especialidad_personale/especialidad_personales.services';
import { Especialidad_personales } from '../especialidad_personale/especialidad_personales.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-especialidades-medicos',
  templateUrl: './especialidades-medicos.component.html',
  styleUrls: ['./especialidades-medicos.component.scss']
})
export class EspecialidadesMedicosComponent implements OnInit {
  @Input() Especilidad:Especialidades;
  rows=[];
  temp = [];
  catidad:number=0;


  constructor(private serviciosEspecialidades:EspecialidadesServices,
    private ngxService: NgxUiLoaderService,
    private serviciosEspecialidad_personales:Especialidad_personalesServices,) { }

  ngOnInit() {
    if(this.Especilidad==undefined){
      this.serviciosEspecialidades.getDataEspecialidad().then((data:any)=>{
        if(data.id!=undefined){
          this.ngxService.startLoader('princialLoder');
          this.Especilidad=data;
          this.serviciosEspecialidad_personales.viewPersonalMedicoEspecialidades(this.Especilidad.id).subscribe((dataResult:any)=>{
            this.rows=dataResult.especialidadPersonales;
            this.temp = [...this.rows];
            this.catidad=this.rows.length;
            this.ngxService.stopLoader('princialLoder');
          });
        }
          
      });
    }
  }

  editEstado(dataEspecialidad_personale:Especialidad_personales){
    let dataEstadoEspecialidad_personale= {} as Especialidad_personales;
    dataEstadoEspecialidad_personale.persona_id=dataEspecialidad_personale.persona_id;
    dataEstadoEspecialidad_personale.especialidade_id=dataEspecialidad_personale.especialidade_id;
		dataEstadoEspecialidad_personale.estado=Number(!dataEspecialidad_personale.estado);
		this.serviciosEspecialidad_personales.updateEspecialidad_personales(dataEstadoEspecialidad_personale).subscribe((data:any)=>{});
  }

  updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.persona.cedula.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}
  


}
