import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { ParroquiasVistaComponent } from './vistas/parroquiavista.component';

export const ParroquiasRoutes: Routes = [{
	path: '',
	redirectTo: 'parroquias-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'parroquias-index',
		component: ParroquiasVistaComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Lista de parroquias',
			roles: ['Gerente'],
			urls: [
			  { title: 'Home', url: '/home' },
			  { title: 'Página de inicio' }
			]
		},
	}]
}];
