import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OpcionesmotivosRoutes } from './opcionesmotivos.routing';
import { OpcionesmotivosVistaComponent } from './vistas/opcionesmotivovista.component';
import { ModalOpcionesmotivoModule } from './modal/modalopcionesmotivo.module';
import { OpcionesmotivosServices } from './opcionesmotivo/opcionesmotivos.services';
import { MoverItemMotivoOpcionComponent } from './mover-item-motivo-opcion/mover-item-motivo-opcion.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(OpcionesmotivosRoutes),
		NgxDatatableModule,
		ModalOpcionesmotivoModule
	],
	declarations:[
		OpcionesmotivosVistaComponent,
		MoverItemMotivoOpcionComponent
	],
	providers:[
		OpcionesmotivosServices
	],
	exports:[
		OpcionesmotivosVistaComponent
	]
})

export class OpcionesmotivosModule {}
