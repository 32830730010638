import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Citasmedicainformaciones } from '../citasmedicainformacione/citasmedicainformaciones.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CitasmedicainformacionesServices } from '../citasmedicainformacione/citasmedicainformaciones.services';

@Component({
  selector: 'app-grupo-motivos-detalle',
  templateUrl: './grupo-motivos-detalle.component.html',
  styleUrls: ['./grupo-motivos-detalle.component.scss']
})
export class GrupoMotivosDetalleComponent implements OnInit {
  @Input() citaGrupo: any;
  @Input() citaAtencionMedicaGrupo: any;
  @Input() opgrupo: number;
  @Input() itemlistas = [];
  @Input() texto: string;
  @Input() op_guardado: boolean;
  @Input() op_evaluar: string;
  @Input() seccionGrupo: number;
  @Input() formularioGrupo: number;
  @Output() clickEvent = new EventEmitter();

  userdata: any;
  temp = [];
  infosend = [];
  constructor(public localdata: localStorageServices,
    private ngxService: NgxUiLoaderService,
    private accionFormulario: CitasmedicainformacionesServices,
  ) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    this.temp = [...this.itemlistas];
    if (this.citaGrupo == undefined && this.opgrupo == 2) {
      this.citaGrupo = { id: null } as any;
    } else if (this.citaAtencionMedicaGrupo == undefined && this.opgrupo == 1) {
      this.citaAtencionMedicaGrupo = { id: null } as any;
    }

    if (this.itemlistas.length) {
      if (this.itemlistas[0].codigogrupo != undefined && this.itemlistas[0].codigogrupo != null) {
        this.guardado(false);
      }
    }

  }

  guardado(data: Boolean) {
    this.op_guardado = (Boolean)(!data);
    this.itemlistas.forEach(element => {
      if (this.op_guardado == false && element.guardado == 1) {
        element.visualizar = true;
      } else if (this.op_guardado == true && element.guardado == 1) {
        element.visualizar = true;
      } else {
        element.visualizar = false;
      }
    });
    if (this.op_guardado) {
      const temp = this.temp.filter(function (d) {
        return d.visualizar == true;
      });
      this.itemlistas = temp;
    } else {
      this.itemlistas = [...this.temp];
    }
  }

  seleccionar(data: any) {
    let indice = 0;
    for (let index = 0; index < this.itemlistas.length; index++) {
      const element = this.itemlistas[index];
      if (element.id == data.id) {
        indice = index;
        break;
      }
    }
    let estadoanterior = this.itemlistas[indice].seleccion;

    if (this.itemlistas[indice].seleccion == 0) {
      this.itemlistas[indice].seleccion = 1;
    } else if (this.itemlistas[indice].seleccion == 1) {
      this.itemlistas[indice].seleccion = 0;
    }

    let encontrado: boolean = false;
    let indiceItem: number = 0;

    for (let i = 0; i < this.infosend.length; i++) {
      const element = this.infosend[i];
      if (element.citasmedica_id == this.citaGrupo.id && element.motivoscita_id == data.id && this.opgrupo == 1) {
        encontrado = true;
        indiceItem = i;
        break;
      } else if (element.atencionesmedica_id == this.citaAtencionMedicaGrupo.id && element.motivoscita_id == data.id && this.opgrupo == 2) {
        encontrado = true;
        indiceItem = i;
        break;
      }
    }

    console.log(this.itemlistas[indice].guardado);

    if (!encontrado) {
      let dataSendMotivo = {} as any;
      if (this.citaGrupo.id == null && this.opgrupo == 2 && this.itemlistas[indice].guardado==undefined) {
        dataSendMotivo = { motivoscita_id: this.itemlistas[indice].id, atencionesmedica_id: this.citaAtencionMedicaGrupo.id, estado: 1, detalle: data.detalle, persona_id: this.userdata.datos.persona.id , valor: data.valor} as Citasmedicainformaciones;
      } else if (this.citaAtencionMedicaGrupo.id == null && this.opgrupo == 1 && this.itemlistas[indice].guardado==undefined) {
        dataSendMotivo = { motivoscita_id: this.itemlistas[indice].id, citasmedica_id: this.citaGrupo.id, estado: 1, detalle: data.detalle, persona_id: this.userdata.datos.persona.id , valor: data.valor} as Citasmedicainformaciones;
      }
      if(this.itemlistas[indice].guardado==undefined)
        this.infosend.push(dataSendMotivo);
    } else {
      if (estadoanterior == 1 && this.itemlistas[indice].seleccion == 0) {
        this.infosend.splice(indiceItem, 1);
      } else if (estadoanterior == 1 && this.itemlistas[indice].seleccion == 1) {
        this.infosend[indiceItem].detalle = data.detalle;
      } else if (estadoanterior == 0 && this.itemlistas[indice].seleccion == 1) {
        this.infosend[indiceItem].detalle = data.detalle;
      }
    }

  }

  guardarmotivo() {
    this.op_guardado = true;
    let codigo = this.generateRandomString(12);

    if (this.itemlistas.length) {
      if (this.itemlistas[0].codigogrupo != undefined && this.itemlistas[0].codigogrupo != null) {
        codigo = this.itemlistas[0].codigogrupo;
      }
    } else {
      codigo = this.generateRandomString(12);
    }
    this.infosend.forEach(element => {
      element.codigo = codigo;
    });

    
    let infoTotal = {} as any;
    if (this.citaGrupo.id==null && this.opgrupo == 2) {
      infoTotal = { info: { seccion: this.seccionGrupo, form: this.formularioGrupo, atencionesmedica_id: this.citaAtencionMedicaGrupo.id }, inserts: this.infosend } as any;
    } else if (this.citaAtencionMedicaGrupo.id==null && this.opgrupo == 1) {
      infoTotal = { info: { seccion: this.seccionGrupo, form: this.formularioGrupo, citasmedica_id: this.citaGrupo.id }, inserts: this.infosend } as any;
    }

    this.ngxService.startLoader('item' + this.formularioGrupo + this.seccionGrupo);
    this.accionFormulario.saveCitasmedicainformaciones(infoTotal).subscribe((data: any) => {
      if (data.estado) {
        this.infosend = [];
        this.clickEvent.emit(data.datos);
        this.ngxService.stopLoader('item' + this.formularioGrupo + this.seccionGrupo);
      }
    });
  }

  generateRandomString(num: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = Math.random().toString(36).substring(0, num);
    return result1;
  }

}
