import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Enfermerias} from './enfermerias.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class EnfermeriasServices {

	constructor(private http: HttpClient) { }

	getDataEnfermerias():Observable<Enfermerias[]>{
		return this.http.get<Enfermerias[]>(Globals.BASE_URL_API_REST+'enfermerias.json');
	}

	saveEnfermerias(data:Enfermerias){
		return this.http.post(Globals.BASE_URL_API_REST+'enfermerias.json',data);
	}

	deleteEnfermerias(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'enfermerias/'+id+'.json');
	}

	updateEnfermerias(data:Enfermerias){
		 return this.http.patch(Globals.BASE_URL_API_REST+'enfermerias/'+data.persona_id+'.json',data);
	}

	viewEnfermerias(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'enfermerias/'+id+'.json');
	}

	viewPersonalEnfermeriaSinAsignar(nombres:string){
		return this.http.get(Globals.BASE_URL_API_REST+'op_filtrado_enfermeria_persona.json?nombres='+nombres);
	}

}