import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LowercaseInputDirective } from "./lowercase-input.directive";
import { UppercaseInputDirective } from "./uppercase-input.directive";

@NgModule({
    imports: [
      CommonModule
    ],
    declarations: [
    LowercaseInputDirective,
    UppercaseInputDirective
    ],
    exports: [
    LowercaseInputDirective,
    UppercaseInputDirective
    ]
  })

  export class DirectivaModule { }