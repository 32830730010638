import { Routes } from '@angular/router';
import { AuthGuard } from '../../../appauth/auth/auth.guard';
import { EnfermeriasVistaComponent } from './vistas/enfermeriavista.component';

export const EnfermeriasRoutes: Routes = [{
	path: '',
	redirectTo: 'enfermeria-index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'enfermeria-index',
		component: EnfermeriasVistaComponent,
		canActivate: [AuthGuard]
	}]
}];
