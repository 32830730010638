import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-grafica-signos-vitales',
  templateUrl: './grafica-signos-vitales.component.html',
  styleUrls: ['./grafica-signos-vitales.component.scss']
})
export class GraficaSignosVitalesComponent implements OnInit {
  @Input() dataSignos: any;
  public lineChartData: Array<any> = [];
  public lineChartDataTmp: Array<any> = [];
  selecion: number = 0;
  verGrafica: boolean = true;
  public lineChartLabels: Array<any> = [];
  anchoGrafico: number = 0;
  seleccionados = [];
  todoChech = 0;
  public lineChartOptions: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0.1)'
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0.1)'
          }
        }
      ]
    },
    lineTension: 10,
    responsive: true,
    maintainAspectRatio: false
  };
  public lineChartColors: Array<any> = [];
  public lineChartColorsTmp: Array<any> = [];
  public lineChartLegend = false;
  public lineChartType = 'line';
  constructor() { }

  ngOnInit() {
    this.lineChartColors = [
      {
        borderColor: 'rgba(0,158,251,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgba(246,45,81,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgba(117,91,241,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgba(6,215,156,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgb(231, 112, 221)',
        pointBorderColor: '#fff',
      }
    ];

    this.lineChartColorsTmp = [
      {
        borderColor: 'rgba(0,158,251,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgba(246,45,81,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgba(117,91,241,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgba(6,215,156,1)',
        pointBorderColor: '#fff',
      },
      {
        borderColor: 'rgb(231, 112, 221)',
        pointBorderColor: '#fff',
      }
    ];
    if (this.dataSignos.length < 5) {
      this.anchoGrafico = this.dataSignos.length * 100;
    }else if (this.dataSignos.length >=5 && this.dataSignos.length < 12) {
      this.anchoGrafico = this.dataSignos.length * 75;
    } else {
      this.anchoGrafico = 800;
    }

    for (let i = 0; i < this.dataSignos.length; i++) {
      const element = this.dataSignos[i];
      moment.locale('es');
      this.lineChartLabels.push(moment(element.created).format('llll'));
    }
    let dataPulso = [];
    let dataTemp = [];
    let dataSistolica = [];
    let dataFrecResXMin = [];
    let dataDiastolica = [];
    for (let index = 0; index < this.dataSignos.length; index++) {
      const element2 = this.dataSignos[index];
      dataPulso.push(element2.frequenciacardiaca);
      dataTemp.push(element2.temperaturaaxilar);
      dataFrecResXMin.push(element2.frecuenciarespiratoria);
      dataSistolica.push(element2.presionarterialmaxima);
      dataDiastolica.push(element2.presionarterialminima);
    }

    this.lineChartData.push({ data: dataPulso, label: 'PULSO' });
    this.lineChartData.push({ data: dataTemp, label: 'TEMP' });
    this.lineChartData.push({ data: dataFrecResXMin, label: 'FRECUENCIA. RESPIRATORIA. X MIN.' });
    this.lineChartData.push({ data: dataSistolica, label: 'PRESIÓN SISTÓLICA' });
    this.lineChartData.push({ data: dataDiastolica, label: 'PRESIÓN DIASTÓLICA' });
    this.lineChartDataTmp = [...this.lineChartData];

  }

  select(num: number) {

    this.selecion = num;
    this.verGrafica = false;
    while (this.lineChartData.length > 0) {
      this.lineChartData.pop();
    }
    while (this.lineChartColors.length > 0) {
      this.lineChartColors.pop();
    }

    if (num > 0) {
      let existe: boolean = false;
      let indicef: number = 0;
      for (let index = 0; index < this.seleccionados.length; index++) {
        const element = this.seleccionados[index];
        if (element == num - 1) {
          existe = true;
          indicef = index;
          break;
        }
      }
      if (!existe) {
        this.seleccionados.push(num - 1)
      } else {
        this.seleccionados.splice(indicef, 1);
      }
    }

    if (num > 0) {
      this.todoChech=0;
      const temp: Array<any> = [];
      const tempcolor: Array<any> = [];
      for (let u = 0; u < this.lineChartDataTmp.length; u++) {
        const element = this.lineChartDataTmp[u];
        let existeenbuscar: boolean = false;
        for (let k = 0; k < this.seleccionados.length; k++) {
          const elementgrafica = this.seleccionados[k];
          if (elementgrafica == u) {
            existeenbuscar = true;
            break;
          }
        }
        if (existeenbuscar) {
          temp.push(element);
          tempcolor.push(u);
        }
      }

      for (let i = 0; i < temp.length; i++) {
        const element = temp[i];
        this.lineChartData.push(element);
      }

      for (let i = 0; i < tempcolor.length; i++) {
        const element = tempcolor[i];
        this.lineChartColors.push(this.lineChartColorsTmp[element]);
      }


    } else {

      if (this.todoChech == 0) {
        this.todoChech = 1;
        const temp: Array<any> = this.lineChartDataTmp;
        this.lineChartData = [...temp];
        this.lineChartColors = [
          {
            borderColor: 'rgba(0,158,251,1)',
            pointBorderColor: '#fff',
          },
          {
            borderColor: 'rgba(246,45,81,1)',
            pointBorderColor: '#fff',
          },
          {
            borderColor: 'rgba(117,91,241,1)',
            pointBorderColor: '#fff',
          },
          {
            borderColor: 'rgba(6,215,156,1)',
            pointBorderColor: '#fff',
          },
          {
            borderColor: 'rgb(231, 112, 221)',
            pointBorderColor: '#fff',
          }
        ];
        this.seleccionados=[];
      }
      else {
        this.todoChech = 0;
        const temp: Array<any> = [];
        this.lineChartData = [...temp];
        this.seleccionados=[];
      }


    }
    setTimeout(() => {
      this.verGrafica = true;
    }, 100);
  }

}
