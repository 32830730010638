import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../app/global/global.model';
import { MspReportesService } from '../servicios/msprepotrtes.servicios';

@Component({
  selector: '[app-resumen-formulario-f008]',
  templateUrl: './resumen-formulario-f008.component.html',
  styleUrls: ['./resumen-formulario-f008.component.scss']
})
export class ResumenFormularioF008Component implements OnInit {
  @Input() idHospital: number;
  @Input() idAtencion: number;
  @Input() idCitaMedica: number;
  @Input() idHistoriaClinica: number;
  pdfpath:any;
  pdfpathcvs: string;

  constructor(private smpForm:MspReportesService,public sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.imprimir();
    this.smpForm.viewInfoPaciente(this.idHistoriaClinica).subscribe((data:any)=>{
      if(data.historiasclinica.persona.sexo=='F'){
        this.pdfpathcvs='assets/images/mujer.svg';
      }else{
        this.pdfpathcvs='assets/images/hombre.svg';
      }
    });
  }

  imprimir(){
    this.smpForm.getMSPForm008({historia:this.idHistoriaClinica,cita:this.idCitaMedica}).subscribe((datapdf:any)=>{
      this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+ datapdf.datos.MSP.ruta); 
    })
  }

}
