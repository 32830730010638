import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../../global/global.model';
import {Tipoempleados} from './tipoempleados.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class TipoempleadosServices {

	private formData: Tipoempleados = new Tipoempleados();

	constructor(private http: HttpClient) { }

	getTipoEmpleado() {
       	var proveedor: Tipoempleados = this.formData;
		let promise = new Promise((resolve, reject) => {
            resolve(proveedor);
        });
        return promise;
    }
	

    setTipoEmpleado(data: Tipoempleados) {
        this.formData = data;
    }

	getDataTipoempleados():Observable<Tipoempleados[]>{
		return this.http.get<Tipoempleados[]>(Globals.BASE_URL_API_REST+'tipoempleados.json');
	}

	saveTipoempleados(data:Tipoempleados){
		return this.http.post(Globals.BASE_URL_API_REST+'tipoempleados.json',data);
	}

	getDataTipoempleadosAdmision(id:number):Observable<Tipoempleados[]>{
		return this.http.get<Tipoempleados[]>(Globals.BASE_URL_API_REST+'informe_admision_horarios/'+id+'.json');
	}

	deleteTipoempleados(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'tipoempleados/'+id+'.json');
	}

	updateTipoempleados(data:Tipoempleados){
		 return this.http.patch(Globals.BASE_URL_API_REST+'tipoempleados/'+data.id+'.json',data);
	}

	viewTipoempleados(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'tipoempleados/'+id+'.json');
	}

	saveHorarioempleados(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'horarioempleados.json',data);
	}

	viewHorarioExamenesAdmisiones(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'informe_admision_horarios.json',data);
	}

	updateHorarioempleados(data:any){
		return this.http.patch(Globals.BASE_URL_API_REST+'horarioempleados/'+data.id+'.json',data);
   	}

	deleteHorarioempleados(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'horarioempleados/'+id+'.json');
	}
}