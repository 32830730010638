import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { ConfirmarMedicamentoEnfermeraHospitalizarComponent } from '../modal/confirmar-medicamento-enfermera-hospitalizar/confirmar-medicamento-enfermera-hospitalizar.component';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-medicamentos-recetados-hospitalizacion',
  templateUrl: './medicamentos-recetados-hospitalizacion.component.html',
  styleUrls: ['./medicamentos-recetados-hospitalizacion.component.scss']
})
export class MedicamentosRecetadosHospitalizacionComponent implements OnInit {
  @Input() idHospitalizacion: number;
  @Output() clickEvent = new EventEmitter();
  grupoDosisCompleta: any;
  rows = [];
  config: any = {
    size: 'md',
    centered: true,
    resolve: { datasend: null }
  }
  @ViewChild('ctdTabset', { static: false }) ctdTabset;
  constructor(private servicioMedicina: ElementComponentService,
    private notificationServices: NotificationServices,
    public dialog: NgbModal,) { }

  ngOnInit() {
    console.log(this.idHospitalizacion);
    this.buscar();
  }

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  cancelar(data: any) {

    let dialogRef = this.dialog.open(ConfirmarMedicamentoEnfermeraHospitalizarComponent, this.config);
    dialogRef.componentInstance.Indicacione = data;
    dialogRef.result.then(
      result => {
        if (result && result !== 2) {
          data = result;
          this.clickEvent.emit(data);
        }
      },
      reason => {
        console.log(reason);
      }
    );
  }

  buscar() {
    this.servicioMedicina.viewAtencionCitaMedicaMedicamentosIndicaciones(this.idHospitalizacion).subscribe((data: any) => {
      if (data) {
        this.rows = data.indicaciones;
        for (let kk = 0; kk < this.rows.length; kk++) {
          const element = this.rows[kk];
          if (element.tipo == null || element.tipo == '') {
            this.rows[kk].tipo = 0;
          }
          let grupoDosis: any = this.groupBy(element.kardexmedicamentos, row => row.fecha);
          this.rows[kk].kardexmedicamentos = grupoDosis;

        }
        this.grupoDosisCompleta = this.groupBy(this.rows, row => row.tipo);
      }
    });
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }


}
