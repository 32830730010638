export class Personas{
	id?:number;
	tipo?:number;
	cedula?:string;
	nombre?:string;
	razonsocial?:string;
	apellido?:string;
	direccion?:string;
	barrio?:string;
	prefijo?:string;
	telefono?:string;
	telefono_trabajo?:string;
	celular?:string;
	celular_trabajo?:string;
	email?:string;
	estado?:number;
	created?:Date;
	modified?:Date;
	telefono_trabajo_dos?:string;
	celular_trabajo_dos?:string;
	fecha_nacimiento?:Date;
	estado_civil?:number;
	sexo?:any;
	parroquia_id?:any;
	facturaremail?:string;
	etnia_id?:number;
	formacionacademica_id?:number;
	lugarnacimiento?:string;
	nacionalidad?:string;
}