import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Empleados } from '../empleado/empleados.model';
import { EmpleadosServices } from '../empleado/empleados.services';

@Component({
  selector: 'app-laboratorios',
  templateUrl: './laboratorios.component.html',
  styleUrls: ['./laboratorios.component.scss']
})
export class LaboratoriosComponent implements OnInit {
  @Input() info:any;
  tipo_examen: boolean = true;

  constructor(private servicioEmpleado:EmpleadosServices,private Noti:NotificationServices) { }

  ngOnInit() {
    if(this.info.labclinico==null){
      this.tipo_examen=false;
    }else if(this.info.labclinico==1){
      this.tipo_examen=false;
    }else if(this.info.labclinico==2){
      this.tipo_examen=true;
    }
  }

  tipoExamen(data: boolean) {
		this.tipo_examen = (!data);
		if (this.tipo_examen) {
			this.saveEmpleadoLabEnf(2);
		}else{
      this.saveEmpleadoLabEnf(1);
    }
	}

  saveEmpleadoLabEnf(tipo:number){
		let EmpleadoDataModel = {'labclinico':tipo,persona_id:this.info.persona_id,tipoempleado_id:this.info.tipoempleado_id} as Empleados;
		this.servicioEmpleado.updateEmpleados(EmpleadoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
            this.info.labclinico=data.datos.labclinico;
					}
				});;
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

  editLab(){
    let EmpleadoDataModel = {'labclinico':null,persona_id:this.info.persona_id,tipoempleado_id:this.info.tipoempleado_id} as Empleados;
		this.servicioEmpleado.updateEmpleados(EmpleadoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.info.labclinico=data.datos.labclinico;
					}
				});;
			}else{
				this.Noti.setNotification(data);
			}
		});
  }

}
