import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataItemsGeneral } from '../../../appasclepio/examen-medicos/citaexemanes/citaexemane/cita.data';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-form-acciones-atenciones-medicas',
  templateUrl: './form-acciones-atenciones-medicas.component.html',
  styleUrls: ['./form-acciones-atenciones-medicas.component.scss']
})
export class FormAccionesAtencionesMedicasComponent implements OnInit {
  @Input() idAtencionMedica: number;
  dataInfoAtencion: any;
  listaIngreso = [];
  listaAlta = [];
  verInfoCitaExamen: boolean = true;
  verInfoCitaIngreso: boolean = true;
  verInfoCitaAlta: boolean = true;
  verInfoCitaTratamiento: boolean = true;
  items=DataItemsGeneral.items;
  rows=[];
  @Output() clickEvent = new EventEmitter();
  
  constructor(private servicioApps: ElementComponentService,) { }

  ngOnInit() {
    this.servicioApps.viewAtencionCitaMedicaResumen(this.idAtencionMedica).subscribe((data: any) => {
      this.dataInfoAtencion = data.atencionesmedica;
      this.listaIngreso = this.dataInfoAtencion.diagnosticos.filter(function (el) {
        return el.tipo == 1;
      });
      this.listaAlta = this.dataInfoAtencion.diagnosticos.filter(function (el) {
        return el.tipo == 2;
      });
      this.servicioApps.viewAtencionCitaMedicaConsultaExamenResumen(this.dataInfoAtencion.id).subscribe((info:any)=>{
        this.rows=info.citaexemane;
      });
    });
  }

  verInfoCitaDetalle() {
    if (this.verInfoCitaExamen == true) {
      this.verInfoCitaExamen = false;
    } else {
      this.verInfoCitaExamen = true;
    }
  }

  verInfoCitaDetalleIngreso() {
    if (this.verInfoCitaIngreso == true) {
      this.verInfoCitaIngreso = false;
    } else {
      this.verInfoCitaIngreso = true;
    }
  }

  verInfoCitaDetalleAlta() {
    if (this.verInfoCitaAlta == true) {
      this.verInfoCitaAlta = false;
    } else {
      this.verInfoCitaAlta = true;
    }
  }

  verInfoCitaDetallePlan(){
    if (this.verInfoCitaTratamiento == true) {
      this.verInfoCitaTratamiento = false;
    } else {
      this.verInfoCitaTratamiento = true;
    }
  }

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }


}
