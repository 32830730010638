import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Formacionacademicas } from '../../formacionacademica/formacionacademicas.model';
import { FormacionacademicasServices } from '../../formacionacademica/formacionacademicas.services';

@Component({
	selector: 'app-addformacionacademica',
	templateUrl: './addformacionacademica.component.html',
	styleUrls: ['./addformacionacademica.component.scss']
})

export class AddFormacionacademicaComponent implements OnInit {
	public formAddFormacionacademica: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioFormacionacademica:FormacionacademicasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddFormacionacademica = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
			estado:[1,null],
		});
	}


	save(){
		let formacionacademicaDataModel:Formacionacademicas=this.formAddFormacionacademica.value;
		this.servicioFormacionacademica.saveFormacionacademicas(formacionacademicaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}
}