export class Opcionesmotivos{
	id?:number;
	opciones?:string;
	valor?:string;
	motivoscita_id?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
	orden?:number;
	nextorden?:number;
}