import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Opcionesmotivos } from '../opcionesmotivo/opcionesmotivos.model';
import { OpcionesmotivosServices } from '../opcionesmotivo/opcionesmotivos.services';

@Component({
  selector: 'app-mover-item-motivo-opcion',
  templateUrl: './mover-item-motivo-opcion.component.html',
  styleUrls: ['./mover-item-motivo-opcion.component.scss']
})
export class MoverItemMotivoOpcionComponent implements OnInit {
  @Input() indice: number;
  @Input() cantidad: number;
  @Input() data: any;
  @Input() eliminacion: boolean;
  @Output() clickEvent = new EventEmitter();

  constructor(private servicioMotivo: OpcionesmotivosServices,) { }

  ngOnInit() {
    if (!this.data.orden || this.eliminacion) {
      let ParametrosexameneDataModel = {} as Opcionesmotivos;
      ParametrosexameneDataModel.id = this.data.id;
      ParametrosexameneDataModel.orden = this.indice;
      this.servicioMotivo.updateOpcionesmotivos(ParametrosexameneDataModel).subscribe((data: any) => {
        if (data.estado) {
          this.data.orden = data.datos.orden;
        }
      });
    }

  }

  up() {

    let ParametrosexameneDataModelPreview = {} as Opcionesmotivos;
    ParametrosexameneDataModelPreview.motivoscita_id = this.data.motivoscita_id;
    ParametrosexameneDataModelPreview.id = this.data.id;
    ParametrosexameneDataModelPreview.orden = this.indice;
    ParametrosexameneDataModelPreview.nextorden = this.indice - 1;
    this.servicioMotivo.updateMotivoscitasOpcioneSiguiente(ParametrosexameneDataModelPreview).subscribe((data: any) => {
      if (data.estado) {
        let ParametrosexameneDataModel = {} as Opcionesmotivos;
        ParametrosexameneDataModel.id = this.data.id;
        ParametrosexameneDataModel.orden = this.indice - 1;
        this.servicioMotivo.updateOpcionesmotivos(ParametrosexameneDataModel).subscribe((data: any) => {
          if (data.estado) {
            this.data.orden = data.datos.orden;
            this.clickEvent.emit(this.data);
          }
        });
      }
    });
  }

  down() {

    let ParametrosexameneDataModelNext = {} as Opcionesmotivos;
    ParametrosexameneDataModelNext.motivoscita_id = this.data.motivoscita_id;
    ParametrosexameneDataModelNext.id = this.data.id;
    ParametrosexameneDataModelNext.orden = this.indice;
    ParametrosexameneDataModelNext.nextorden = this.indice + 1;
    this.servicioMotivo.updateMotivoscitasOpcioneSiguiente(ParametrosexameneDataModelNext).subscribe((data: any) => {
      if (data.estado) {
        let ParametrosexameneDataModel = {} as Opcionesmotivos;
        ParametrosexameneDataModel.id = this.data.id;
        ParametrosexameneDataModel.orden = this.indice + 1;
        this.servicioMotivo.updateOpcionesmotivos(ParametrosexameneDataModel).subscribe((data: any) => {
          if (data.estado) {
            this.data.orden = data.datos.orden;
            this.clickEvent.emit(this.data);
          }
        });
      }
    });
  }

}
