import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Provincias } from '../../provincia/provincias.model';
import { ProvinciasServices } from '../../provincia/provincias.services';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editprovincia',
	templateUrl: './editprovincia.component.html',
	styleUrls: ['./editprovincia.component.scss']
})

export class EditProvinciaComponent implements OnInit {

	@Input() Provincia:Provincias;

	public formProvinciaNombre:FormGroup;
	public editNombre:boolean=false;

	public formProvinciaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioProvincia:ProvinciasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formProvinciaNombre=this.fb.group({
			nombre:[this.Provincia.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}
	

	editProvinciaNombreEnable(){
		this.editNombre=true;
		this.formProvinciaNombre=this.fb.group({
			nombre:[this.Provincia.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editProvinciaNombreDisable(){
		this.editNombre=false;
	}

	saveProvinciaNombre(){
		let ProvinciaDataModel:Provincias=this.formProvinciaNombre.value;
		ProvinciaDataModel.id=this.Provincia.id;
		this.Provincia.nombre=ProvinciaDataModel.nombre;
		this.servicioProvincia.updateProvincias(ProvinciaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}

