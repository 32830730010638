import { Component, Input, OnInit } from '@angular/core';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-app-info-cita',
  templateUrl: './app-info-cita.component.html',
  styleUrls: ['./app-info-cita.component.scss']
})
export class AppInfoCitaComponent implements OnInit {
  @Input() idHistoriaMedica: number;
  rows=[];
  verInfoCita:boolean=true;

  constructor(private servicioApps: ElementComponentService) { }

  ngOnInit() {
    this.servicioApps.getDataViewApps(this.idHistoriaMedica).subscribe((data: any) => {
      this.rows = (data.apps);
    });
  }

  verInfoCitaDetalle(){
    if(this.verInfoCita==true){
      this.verInfoCita=false;
    }else{
      this.verInfoCita=true;
    }
  }
}
