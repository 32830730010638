import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImprimirCertificadoGeneralComponent } from '../modal/imprimir-informacion-certificado/imprimir-informacion-certificado.component';
import { ImprimirCertificadoMedicoCustomComponent } from '../modal/imprimir-informacion/imprimir-informacion.component';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-certificado-medicos',
  templateUrl: './certificado-medicos.component.html',
  styleUrls: ['./certificado-medicos.component.scss']
})
export class CertificadoMedicosComponent implements OnInit {
  @Input() cita: any;
  configPdf: any = {
		size: 'xl',
		centered: true,
		resolve: { datasend: null }
	}
  constructor(private certificadoInfo: ElementComponentService, public dialog: NgbModal,) { }

  ngOnInit() {
  }

  certificado() {
      let dialogRef = this.dialog.open(ImprimirCertificadoMedicoCustomComponent, this.configPdf);
      dialogRef.componentInstance.medico =  this.cita.horario.persona;
      dialogRef.componentInstance.especialidad =  this.cita.horario.especialidade;
      dialogRef.componentInstance.paciente =  this.cita.historiasclinica.persona;
      dialogRef.componentInstance.cita =  this.cita.id;
      dialogRef.componentInstance.titulo = "CERTIFICADO MÉDICO";
      dialogRef.result.then(
        result => {
          if (result) {

          }
        },
        reason => {
          console.log(reason);
        }
      );
  }

  general(){
    let dialogRef = this.dialog.open(ImprimirCertificadoGeneralComponent, this.configPdf);
      dialogRef.componentInstance.medico =  this.cita.horario.persona;
      dialogRef.componentInstance.especialidad =  this.cita.horario.especialidade;
      dialogRef.componentInstance.paciente =  this.cita.historiasclinica.persona;
      dialogRef.componentInstance.cita =  this.cita.id;
      dialogRef.componentInstance.historia =  this.cita.historiasclinica.id;
      dialogRef.componentInstance.tipocita=this.cita.cita;
      dialogRef.componentInstance.titulo = "CERTIFICADO MÉDICO GENERAL";
      dialogRef.result.then(
        result => {
          if (result) {

          }
        },
        reason => {
          console.log(reason);
        }
      );
  }
}
