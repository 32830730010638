import { Component, OnInit,ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { Users } from '../../user/users.model';
import { CustomValidators } from 'ng2-validation';
import {UserServices} from '../../user/users.services';
import {NotificationServices} from '../../../notification/notificatio.services';
import {GroupsServices} from '../../../group-pages/groups/groups.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-admin-user',
    templateUrl:'./useradminmodal-component.html',
    encapsulation: ViewEncapsulation.None
  })
  export class UserAdminModalComponent implements OnInit {
    @Input() public dataUsuario;
    public formAddGroupUser: FormGroup;
    public dataGroups:any;
    breakpoint: number;
    ratio = '4:1';
    constructor(
                private fb: FormBuilder,
                private servicesUser:UserServices,
                private servicesGroup:GroupsServices,
                private activeModal:NgbActiveModal,
                private ngxService: NgxUiLoaderService,
                private Noti:NotificationServices,) {}
    
    ngOnInit(){

      this.breakpoint = (window.innerWidth <= 800) ? 1 : 1;
      this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
      this.servicesUser.getDataUsuario().then((data: any) => {
        if (data.id != undefined) {
          this.dataUsuario = data;
          this.formAddGroupUser = this.fb.group({
            estado:[1,null],
            user_id:[this.dataUsuario.id,Validators.compose([Validators.required,CustomValidators.number])],
            group_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
          });
          let ids=[];
          this.dataUsuario.group_users.forEach(element => {
            ids.push(element.group_id)
          });
          this.servicesGroup.getDataGroups().subscribe((data:any)=>{
            this.dataGroups=data.groups.filter(function(item){
              return ids.indexOf(item.id) === -1;
            });
          });
        }
      });
    }

    saveUser(){
      let userDataModel:Users=this.formAddGroupUser.value;
      this.ngxService.startLoader('princialLoder');
      this.servicesUser.saveGroupUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.ngxService.stopLoader('princialLoder');
                this.activeModal.close(1);
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }

    onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 1;
      this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
    }

    cerrarModal(){
      this.activeModal.close(2);
    }
    

  }

  