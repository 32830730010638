import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Opcionesmotivos } from '../../opcionesmotivo/opcionesmotivos.model';
import { OpcionesmotivosServices } from '../../opcionesmotivo/opcionesmotivos.services';
import { Motivoscitas } from '../../../motivoscitas/motivoscita/motivoscitas.model';
import { MotivoscitasServices } from '../../../motivoscitas/motivoscita/motivoscitas.services';

@Component({
	selector: 'app-addopcionesmotivo',
	templateUrl: './addopcionesmotivo.component.html',
	styleUrls: ['./addopcionesmotivo.component.scss']
})

export class AddOpcionesmotivoComponent implements OnInit {
	@Input() motivo:any;
	@Input() Orden:number=null;
	public dataMotivoscitas:any;
	public formAddOpcionesmotivo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioOpcionesmotivo:OpcionesmotivosServices,
		private servicioMotivoscita:MotivoscitasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		
		this.formAddOpcionesmotivo = this.fb.group({
			opciones:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
			motivoscita_id:[this.motivo.id,Validators.compose([Validators.required,CustomValidators.number])],
			orden:[this.Orden,null],
			valor:[null,Validators.compose([Validators.minLength(1), Validators.maxLength(15)])],
			estado:[1,null],
		});
	}


	save(){
		let opcionesmotivoDataModel:Opcionesmotivos=this.formAddOpcionesmotivo.value;
		this.servicioOpcionesmotivo.saveOpcionesmotivos(opcionesmotivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}