import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../../global/global.model';
import { Empleados } from './empleados.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class EmpleadosServices {

	constructor(private http: HttpClient) { }

	getDataEmpleados(): Observable<Empleados[]> {
		return this.http.get<Empleados[]>(Globals.BASE_URL_API_REST + 'empleados.json');
	}

	saveEmpleados(data: Empleados) {
		return this.http.post(Globals.BASE_URL_API_REST + 'empleados.json', data);
	}

	deleteEmpleados(id: number) {
		return this.http.delete(Globals.BASE_URL_API_REST + 'empleados/' + id + '.json');
	}

	updateEmpleados(data: Empleados) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'empleados/'+data.persona_id+'.json', data);
	}

	viewEmpleados(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'empleados/' + id + '.json');
	}

	viewEmpleadosSinAsignar(nombres: string) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_filtrado_empleados.json?nombres=' + nombres);
	}
}