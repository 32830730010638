import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ElementResumenHospitalizacionComponentService {

  constructor(private http: HttpClient) { }

  getDataResumenInfoAtencionesHopitalizacion(id: number): Observable<any[]> {
    return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'informe_lista_hospitalizaciones/' + id + '.json');
  }

  getDataResumenInfoAtencionesHopitalizacionEmergencia(id: number): Observable<any[]> {
    return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'informe_lista_cita_emergencia/' + id + '.json');
  }

  viewHospitalizacionesResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'hospitalizaciones/' + id + '.json');
  }

  viewHospitalizacionResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_vwe_hospitalizacion/' + id + '.json');
  }

  viewSignosVilatesHospitalizacionResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_signos_vitales_hospitalizacion/' + id + '.json');
  }

  viewSignosVilatesCitaEmergenciaResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_signos_vitales_cita_emergencia/' + id + '.json');
  }

  viewBalanceHidricoHospitalizacionResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_balance_hidrico_hospitalizacion/' + id + '.json');
  }

  viewMedicionesActividadesHospitalizacionResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_mediciones_actividades_hospitalizacion/' + id + '.json');
  }

  // Cita emergencia


  viewBalanceHidricoCitaEmergenciaResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_balance_hidrico_cita_emergencia/' + id + '.json');
  }

  viewMedicionesActividadesCitaEmergenciaResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_mediciones_actividades_cita_emergencia/' + id + '.json');
  }

  viewMedicamentosKardexHospitalizacionResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_medicamemto_kardex_hospitalizacion/' + id + '.json');
  }

  // cita emergencia

  viewMedicamentosKardexCitaEmergenciaResumen(id: number) {
    return this.http.get(Globals.BASE_URL_API_REST + 'informe_medicamemto_kardex_cita_emergencia/' + id + '.json');
  }

  viewReporteenfermeriasPorHospitalizacion(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_repporte_de_enfermerias/' + id + '.json');
	}

  viewReporteenfermeriasPorCitaMedica(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_repporte_de_enfermerias_por_cita_medica/' + id + '.json');
	}



}