import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataItemsGeneral } from '../../../appasclepio/examen-medicos/citaexemanes/citaexemane/cita.data';
import { Globals } from '../../../global/global.model';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { ElementComponentService } from '../servicios/component.servicios';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-epicrisis-historia-clinica-hispitalizacion',
  templateUrl: './epicrisis-historia-clinica-hispitalizacion.component.html',
  styleUrls: ['./epicrisis-historia-clinica-hispitalizacion.component.scss']
})
export class EpicrisisHistoriaClinicaHispitalizacionComponent implements OnInit {
  @Input() idHospitalizacion: number;
  @Input() infoCitasMedicaEpicrisis:any;
  dataInfoEpicris: any;
  imgEmergencia: string = Globals.imagenEmergencia;
  items = DataItemsGeneral.items;
  listaIngreso = [];
  listaAlta = [];

  constructor(private servicioMedicina: ElementComponentService,
    private notificationServices: NotificationServices,
    private ngxService: NgxUiLoaderService,
    public dialog: NgbModal,) { }

  ngOnInit() {
    this.ngxService.startLoader('princialLoder');
    this.servicioMedicina.viewEpicrisis(this.idHospitalizacion).subscribe((data: any) => {
      this.dataInfoEpicris = data;
      
      this.listaAlta = this.dataInfoEpicris.diagnosticos.filter(function (el) {
        return el.tipo == 2;
      });
      this.listaIngreso = this.dataInfoEpicris.diagnosticos.filter(function (el) {
        return el.tipo == 1;
      });
      this.ngxService.stopLoader('princialLoder');
    });
  }

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }

}
