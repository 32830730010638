import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../app/global/global.model';
import { MspReportesService } from '../servicios/msprepotrtes.servicios';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: '[app-resumen-medicamentos-kardex-cita-emergencia]',
  templateUrl: './resumen-medicamentos-kardex-cita-emergencia.component.html',
  styleUrls: ['./resumen-medicamentos-kardex-cita-emergencia.component.scss']
})
export class ResumenMedicamentosKardexCitaEmergenciaComponent implements OnInit {
  @Input() idCitaMedica: number;
  @Input() idHistoriaClinica: number;
  grupoDosisCompleta: any;
  rows = [];
  @ViewChild('ctdTabset', { static: false }) ctdTabset;
  pdfpath: any;
  
  constructor(private resumen: ElementResumenHospitalizacionComponentService,
    public sanitizer: DomSanitizer,
    private smpForm:MspReportesService,) { }

  ngOnInit() {
    this.resumen.viewMedicamentosKardexCitaEmergenciaResumen(this.idCitaMedica).subscribe((data: any) => {
      if (data) {
        this.rows = data.indicaciones;
        for (let kk = 0; kk < this.rows.length; kk++) {
          const element = this.rows[kk];
          if (element.tipo == null || element.tipo == '') {
            this.rows[kk].tipo = 0;
          }

          let grupoDosis: any = this.groupBy(element.kardexmedicamentos, row => row.fecha);
          this.rows[kk].kardexmedicamentos = grupoDosis;

        }

        this.grupoDosisCompleta = this.groupBy(this.rows, row => row.tipo);
      }
      this.imprimir();
    });
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  fraction(value, donly = true) {
    let tolerance = 1.0E-6; // a partir de cuantas decimales se hace el redondeo
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let negative = false;
    let i: any;

    if (parseInt(value) == value) { // si el valor es un número entero, detener el código
      return value;
    } else if (value < 0) {
      negative = true;
      value = -value;
    }

    if (donly) {
      i = parseInt(value);
      value -= i;
    }

    let b = value;

    do {
      let a = Math.floor(b);
      let aux = h1;
      h1 = a * h1 + h2;
      h2 = aux;
      aux = k1;
      k1 = a * k1 + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(value - h1 / k1) > value * tolerance);

    return (negative ? "-" : '') + ((donly && (i != 0)) ? i + ' ' : '') + (h1 == 0 ? '' : h1 + "/" + k1);
  }

  switchNgBTab(id: string) {
    this.ctdTabset.select(id);
  }

  imprimir(){
    this.smpForm.getMSPForm0022Emergencia({citasmedica_id:this.idCitaMedica,historia:this.idHistoriaClinica}).subscribe((datapdf:any)=>{
      this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+ datapdf.datos.MSP.ruta); 
    })
  }

}
