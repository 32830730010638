import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { InsumounidadesServices} from '../insumounidade/insumounidades.services';
import { NotificationServices} from '../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Insumounidades } from '../insumounidade/insumounidades.model';
import { AddInsumounidadeComponent } from '../modal/add/addinsumounidade.component';
import { EditInsumounidadeComponent } from '../modal/edit/editinsumounidade.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-insumounidadevista',
	templateUrl:'./insumounidadevista.component.html',
	styleUrls: ['./insumounidadevista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class InsumounidadesVistaComponent implements OnInit {
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refescar'){
			this.listaInsumounidades();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosInsumounidades:InsumounidadesServices,
		private notificationServices:NotificationServices,
		private ngxService: NgxUiLoaderService,
	){}

	ngOnInit(){
		this.listaInsumounidades();
	}

	listaInsumounidades(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosInsumounidades.getDataInsumounidades().subscribe((data:any)=>{
			this.rows=data.insumounidades;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddInsumounidadeComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaInsumounidades();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataInsumounidade:any){
		let data={'val':dataInsumounidade.nombre,'msg':'Eliminar Insumounidades'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosInsumounidades.deleteInsumounidades(dataInsumounidade.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaInsumounidades();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditInsumounidadeComponent, this.config);
		dialogRef.componentInstance.Insumounidade = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaInsumounidades();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataInsumounidade:Insumounidades){
		let dataEstadoInsumounidade= {} as Insumounidades;
		dataEstadoInsumounidade.id=dataInsumounidade.id;
		dataEstadoInsumounidade.estado=Number(!dataInsumounidade.estado);
		this.serviciosInsumounidades.updateInsumounidades(dataEstadoInsumounidade).subscribe((data:any)=>{});
	}
}

