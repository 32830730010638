import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../app/global/global.model';
import { MspReportesService } from '../servicios/msprepotrtes.servicios';
import { ElementResumenHospitalizacionComponentService } from '../servicios/resumen.hospitalizacion.services';

@Component({
  selector: '[app-resumen-signos-vitales-emergencia]',
  templateUrl: './resumen-signos-vitales-emergencia.component.html',
  styleUrls: ['./resumen-signos-vitales-emergencia.component.scss']
})
export class ResumenSignosVitalesEmergenciaComponent implements OnInit {
  @Input() idCitaMedica: number;
  @Input() idHistoriaClinica: number;
  signos:any[]=[];
  balanceHidrico:any[]=[];
  balanceMedicio:any[]=[];
  pdfpath:any;
  constructor(private resumen: ElementResumenHospitalizacionComponentService,
    private smpForm:MspReportesService,
    public sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.imprimir();
    this.resumen.viewSignosVilatesCitaEmergenciaResumen(this.idCitaMedica).subscribe((info: any) => {
      this.signos = info.signosvitales;
      this.listAtensionBalanceHidrico();
      this.listAtensionMedionesActividades()
    });
    
  }


  listAtensionBalanceHidrico() {
    this.resumen.viewBalanceHidricoCitaEmergenciaResumen(this.idCitaMedica).subscribe((dataInfo: any) => {
      this.balanceHidrico = [];
      for (let k = 0; k < dataInfo.signosbalancehidricos.length; k++) {
        const element = dataInfo.signosbalancehidricos[k];
        this.balanceHidrico.push(element);
      }
    });
  }

  listAtensionMedionesActividades() {
    this.resumen.viewMedicionesActividadesCitaEmergenciaResumen(this.idCitaMedica).subscribe((dataInfo: any) => {
      this.balanceMedicio = [];
      for (let k = 0; k < dataInfo.signosmedicionactividades.length; k++) {
        const element = dataInfo.signosmedicionactividades[k];
        this.balanceMedicio.push(element);
      }
    });
  }

  imprimir(){
    this.smpForm.getMSPForm004Emergencia({historia:this.idHistoriaClinica,citasmedica_id:this.idCitaMedica}).subscribe((datapdf:any)=>{
      this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+ datapdf.datos.MSP.ruta); 
    })
  }

}
