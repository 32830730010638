import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { DataItemsGeneral } from '../../../appasclepio/examen-medicos/citaexemanes/citaexemane/cita.data';
import { ConsultasFacturasService } from '../../../appfacturas/busquedas/servicios/consultas.facturas.services';

@Component({
  selector: 'app-lista-item-detalle-factura',
  templateUrl: './lista-item-detalle-factura.component.html',
  styleUrls: ['./lista-item-detalle-factura.component.scss']
})
export class ItemListaDetalleFacturaComponent implements OnInit {
  @Input() info:any;
  totalAFacturar: number = 0;
  todoDetalles=[];
  items=DataItemsGeneral.items;
  @Output() clickEvent = new EventEmitter();
  constructor(private serviciosFacturas:ConsultasFacturasService,
    private notificationServices:NotificationServices,
    ) { }

  ngOnInit() {
    this.lista();
  }

  lista(){
    this.todoDetalles=[];
    this.totalAFacturar=0;
    this.serviciosFacturas.getFacturaInformacion().then((dataInfo:any)=>{
      if(dataInfo){
        this.todoDetalles=(dataInfo.detallefacturas);
        for (let index = 0; index < this.todoDetalles.length; index++) {
          const element = this.todoDetalles[index];
          this.totalAFacturar += element.costo-element.descuento;
          
          if(element.citasmedica_id!=undefined){
            let dataTempCita=this.info.citasmedicas.filter((x)=>x.id==element.citasmedica_id);
            
            this.todoDetalles[index].data=dataTempCita[0];
          }else if(element.citaexemane_id!=undefined){
            let dataTempExamenes=this.info.citaexemanes.filter((x)=>x.id==element.citaexemane_id);
           
            this.todoDetalles[index].data=dataTempExamenes[0];
          }
          
        }
        this.clickEvent.emit(this.totalAFacturar);
      }
    });
  }
  eliminarItem(dataItem:any,i:number){
    
    let data={'val':'','msg':'Eliminar detalle'};
    if(dataItem.citasmedica_id!=undefined){
      data.val=dataItem.data.horario.especialidade.nombre;
    }else if(dataItem.citaexemane_id!=undefined){
      data.val=dataItem.data.examenesmedico.nombre;
    }

    
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				if(dataItem.citasmedica_id!=undefined){
          this.todoDetalles.splice(i, 1);
          this.serviciosFacturas.setDetallesFacturaCitas(dataItem, 2);
          this.lista();
        }else if(dataItem.citaexemane_id!=undefined){
          this.todoDetalles.splice( i, 1 );
          this.serviciosFacturas.setDetallesFacturaExamenes(dataItem,2);
          this.lista();
        }else if(dataItem.itemfijo_id!=undefined){
          this.todoDetalles.splice( i, 1 );
          this.serviciosFacturas.setDetallesFacturaItems(dataItem,2);
          this.lista();
        }
			}
		});
  }
}
