import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../global/global.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementComponentService } from '../../servicios/component.servicios';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { HistoriaocupacionesServices } from '../../../../appasclepio/historia/historiaocupaciones/historiaocupacione/historiaocupaciones.services';
import * as trasformar from 'conversor-numero-a-letras-es-ar';
import * as moment from 'moment';

@Component({
    selector: 'app-imprimir-certificado-general',
    templateUrl: './imprimir-informacion-certificado.component.html',
    styleUrls: ['./imprimir-informacion-certificado.component.scss']
})

export class ImprimirCertificadoGeneralComponent implements OnInit {
    @Input() dataPdf: any;
    @Input() titulo: string = '';
    @Input() medico: any;
    @Input() paciente: any;
    @Input() especialidad: any;
    @Input() cita: number;
    @Input() historia: number;
    @Input() tipocita: number;


    tipo: number = 1;
    pdfpath: any = '';
    ubicacion: any;
    empresa = {} as any;
    observaciones: string = '';
    empresaCliente = { nombre: '', puesto: '', fechaEmision: moment().utc().format('YYYY-MM-DD') } as any;
    diagnosticoDetalles = { lista: '', codigo: '', contigencia: '', enfermedad: '' } as any;
    hospitalizacion = { estado: false, desde: moment().utc().format('YYYY-MM-DD'), hasta: moment().utc().format('YYYY-MM-DD'), aislamiento: false, reposo: false, estado_enfermedad: false } as any;
    infoDataMotivo = { dias: { numero: 0, letras: "" }, desde: moment().utc().format('YYYY-MM-DD'), hasta: moment().utc().format('YYYY-MM-DD') } as any;
    empresaClienteSelect: any;
    locales = [];
    diagnosticos = [];
    contexto: string = '';
    fecha = new Date();
    fechas = [];
    rows: any[] = [];
    fechaSelect: any;
    aislamien: boolean = false;
    repos: boolean = false;
    estado_enfermedad: boolean = false;
    estado_hospitalizacion: boolean = false;
    especialidades = [];
    constructor(public sanitizer: DomSanitizer,
        private activeModal: NgbActiveModal,
        private Noti: NotificationServices,
        private comerciales: ElementComponentService) { }

    ngOnInit() {
        if (this.tipocita == 0 || this.tipocita == 1) {
            this.hospitalizacion.estado = false;
            this.estado_hospitalizacion = false;
        }


        let b = trasformar.conversorNumerosALetras;
        let miConversor = new b();
        this.infoDataMotivo.dias.letras = miConversor.convertToText(this.infoDataMotivo.dias.numero);

        this.listaHistoriaocupaciones();
        this.comerciales.getDataComerciales().subscribe((info: any) => {
            this.locales = info.locales;
            this.medico.seleccion = 0;
            for (let index = 0; index < this.locales.length; index++) {
                const element = this.locales[index];
                element.seleccion = 0;
            }
        });
        this.comerciales.getDataDiagnostico(this.cita).subscribe((info: any) => {
            this.diagnosticos = info.atencionesmedicas;
            for (let o = 0; o < this.diagnosticos.length; o++) {
                const element = this.diagnosticos[o];
                this.fechas.push(element.created);
                for (let i = 0; i < element.diagnosticos.length; i++) {
                    const elementdia = element.diagnosticos[i];
                    this.diagnosticoDetalles.lista += elementdia.detalles + " " + elementdia.ciedy.nombre + ' ';
                    this.diagnosticoDetalles.codigo += elementdia.ciedy.codigo + ' ';
                }
            }

            if (this.fechas.length > 0) {
                this.fechaSelect = this.fechas[0];
                this.empresaCliente.fecha = moment(this.fechas[0]).utc().format('YYYY-MM-DD');
            }
        })

        this.comerciales.viewEspecialidad_personales(this.medico.id).subscribe((info: any) => {
            for (let u = 0; u < info.especialidadPersonale.length; u++) {
                const element = info.especialidadPersonale[u];
                this.especialidades.push({ nombre: element.especialidade.nombre, registroprofecional: element.registroprofecional });
            }
            this.especialidad = this.especialidades.find(x => x.nombre == this.especialidad.nombre);
        })

        this.comerciales.viewRegistoMedico(this.medico.id, this.especialidad.id).subscribe((info: any) => {
            this.especialidad.registroprofecional = info.especialidadPersonale.registroprofecional;
        })
    }

    cerrarModal() {
        this.activeModal.close(2);
    }

    medicoSelect(item: any) {
        this.empresa.nombre = item.comerciale.razonsocial;
        this.empresa.direccion = item.direccioncompleta;
        this.empresa.ubicacion = item.parroquia.cantone.provincia.nombre + " " + item.parroquia.cantone.nombre;
        this.empresa.ciudad = item.parroquia.cantone.nombre;
        this.empresa.telefono = item.comerciale.telefono;
        this.empresa.celular = item.comerciale.celular;
        this.empresa.correo = item.comerciale.correo;

        this.tipo = 1;
        this.ubicacion = item.parroquia;
        this.medico.seleccion = 0;

        for (let index = 0; index < this.locales.length; index++) {
            const element = this.locales[index];
            element.seleccion = 0;
        }
        for (let index = 0; index < this.locales.length; index++) {
            const element = this.locales[index];
            element.seleccion = 0;
            if (element.id == item.id) {
                element.seleccion = 1;
            }
        }
    }

    listaHistoriaocupaciones() {
        this.comerciales.viewHistoriaocupaciones(this.historia).subscribe((data: any) => {
            this.rows = data.historiaocupaciones;
            if (this.rows.length > 0) {
                this.empresaClienteSelect = this.rows[0];
                this.empresaCliente.puesto = this.rows[0].ocupacione.nombre;
                this.empresaCliente.nombre = this.rows[0].empresaocupacione.nombre;
            }
        });
    }

    generar() {
        let infoSendData = {
            medico: this.medico,
            contexto: this.contexto,
            empresa: this.empresa,
            observaciones: this.observaciones,
            emprecliente: this.empresaCliente,
            diagnosticos: this.diagnosticoDetalles,
            motivos: this.infoDataMotivo,
            epecialidad: this.especialidad,
            hispitalizacion: this.hospitalizacion,
            paciente: this.paciente,
            cita:this.cita,
            historia:this.historia
        } as any;
        this.comerciales.generateCertificados(infoSendData).subscribe((datapdf: any) => {
            if (datapdf.estado) {
                this.Noti.setNotification(datapdf).then((datas) => {
                    if (datas) {
                        this.pdfpath = this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST + datapdf.datos.CertificadoMedico.ruta);
                    }
                });
            } else {
                this.Noti.setNotification(datapdf);
            }
        })

    }

    ponerEmpresaCliente(data: any) {
        this.empresaCliente.puesto = data.ocupacione.nombre;
        this.empresaCliente.nombre = data.empresaocupacione.nombre;
    }

    ponerfecha(data: any) {
        this.empresaCliente.fecha =  moment(data).utc().format('YYYY-MM-DD');
    }

    dataChanged(data: any) {
        let b = trasformar.conversorNumerosALetras;
        let miConversor = new b();
        this.infoDataMotivo.dias.letras = miConversor.convertToText(data);
    }

    ponerEspecialidad(data: any) {
        this.especialidad = data;
    }

    aislamiento(val: boolean) {
        this.aislamien = val;
        this.hospitalizacion.aislamiento = val;
    }

    reposo(val: boolean) {
        this.repos = val;
        this.hospitalizacion.reposo = val;
    }

    estadoenfermedad(val: boolean) {
        this.estado_enfermedad = val;
        this.hospitalizacion.estado_enfermedad = val;
    }

    estadhospitalizacion(val: boolean) {
        this.estado_hospitalizacion = val;
        this.hospitalizacion.estado = val;
    }

}