import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { ProvinciasServices} from '../provincia/provincias.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Provincias } from '../provincia/provincias.model';
import { AddProvinciaComponent } from '../modal/add/addprovincia.component';
import { EditProvinciaComponent } from '../modal/edit/editprovincia.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-provinciavista',
	templateUrl:'./provinciavista.component.html',
	styleUrls: ['./provinciavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ProvinciasVistaComponent implements OnInit {
	page = 1;
	pageSize =20;
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'mdi mdi-note-plus'
	},{
		text: 'Refrescar',
		icon: 'mdi mdi-backup-restore'
	}]

	config: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if(text=='Refrescar'){
			this.listaProvincias();
		} else if(text=='Nuevo') {
			this.open();
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosProvincias:ProvinciasServices,
		private notificationServices:NotificationServices,
		private ngxService: NgxUiLoaderService,
	){}

	ngOnInit(){
		this.listaProvincias();
	}

	listaProvincias(){
		this.ngxService.startLoader('princialLoder');
		this.serviciosProvincias.getDataProvincias().subscribe((data:any)=>{
			this.rows=data.provincias;
			let info=Math.ceil(this.rows.length/this.pageSize);
			if(info<this.page)
				this.page--;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('princialLoder');
		});
	}

	updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	open(){
		let dialogRef = this.dialog.open(AddProvinciaComponent, this.config);
		dialogRef.result.then(
			result=>{
				if(result){
					this.listaProvincias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	delete(dataProvincia:any){
		let data={'val':dataProvincia.nombre,'msg':'Eliminar Provincias'};
		this.notificationServices.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosProvincias.deleteProvincias(dataProvincia.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.notificationServices.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaProvincias();
							}
						});
					}else{
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditProvinciaComponent, this.config);
		dialogRef.componentInstance.Provincia = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaProvincias();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	editEstado(dataProvincia:Provincias){
		let dataEstadoProvincia= {} as Provincias;
		dataEstadoProvincia.id=dataProvincia.id;
		dataEstadoProvincia.estado=Number(!dataProvincia.estado);
		this.serviciosProvincias.updateProvincias(dataEstadoProvincia).subscribe((data:any)=>{});
	}
}

